import { custom, object, serializable } from "serializr";
import { Country } from "../Country/country.model";
import { Province } from "../Province/province.model";

export class Location {
  @serializable
  id?: string;

  @serializable(object(Country))
  country?: Country;

  @serializable(object(Province))
  province?: Province;
}

export class LocationParams {
  @serializable(
    custom(
      (country: Country) => country?.id,
      () => undefined,
    ),
  )
  country?: Country;

  @serializable(
    custom(
      (province: Province) => province?.id,
      () => undefined,
    ),
  )
  province?: Province;
}
