import React, { FC } from "react";
import "./activityLogCard.scss";
import Breadcrumb from "shared/components/Breadcrumb";
import { Hierarchy } from "models/PrototypeActivity/prototypeActivity.model";
import { User } from "models/user.model";
import moment from "moment";
import { ModuleInPrototype, Action } from "enums/prototypeActivity";
import useToggle from "shared/hooks/useToggle";
import { Eye, EyeSlash } from "shared/icons";
import { generatePath } from "react-router-dom";
import { ApiRoutes } from "routes/routeConstants/apiRoutes";
import { useSelectedPlan } from "context/SelectedPlanContext";
import { useSelectedLayerId } from "context/SelectedLayerContext";
import useRedirect from "shared/hooks/useRedirect";
import { useSelectedZone } from "context/SelectedZoneContext";

enum ActivityLogToggleStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

interface ActivityLogType {
  date?: Date;
  user?: User;
  message?: string;
  hierarchy?: Hierarchy;
  module?: ModuleInPrototype;
  action?: Action;
  field?: string;
  oldData?: string;
  newData?: string;
  prototypeId?: string | null;
}
const ActivityLogCard: FC<ActivityLogType> = ({
  date,
  user,
  message,
  hierarchy,
  module,
  action,
  field,
  oldData,
  newData,
  prototypeId,
}) => {
  const { isToggled, toggleOn, toggleOff } = useToggle(false);
  const allowedFields = ["title", "description", "mapped variant"];
  const showDetails =
    allowedFields.includes(field ?? "") && action === "update";

  return (
    <div className="activity-log">
      <div className="activity-log__container">
        <div className="activity-log__date__container">
          <div className="activity-log__date">
            {moment(date).format("DD MMM YYYY, hh:mm A")}
          </div>
          {showDetails && (
            <div
              className={`activity-log__action ${
                isToggled
                  ? ActivityLogToggleStatus.ACTIVE
                  : ActivityLogToggleStatus.INACTIVE
              }`}
              onClick={() => {
                isToggled ? toggleOff() : toggleOn();
              }}
            >
              {isToggled ? (
                <>
                  <EyeSlash className="icon" /> Hide Change
                </>
              ) : (
                <>
                  <Eye className="icon" /> Show Change
                </>
              )}
            </div>
          )}
        </div>
        <div className="activity-log__message">
          <span className="activity-log__message__user">{user?.name}</span>
          <span
            className={`activity-log__message__text ${
              (action === "remove" || action === "delete") && "gray-color"
            }`}
          >
            {message}
          </span>
        </div>
        <div className="activity-log__heirarchy">
          {hierarchy &&
            prototypeId &&
            module !== ModuleInPrototype.PROTOTYPE &&
            module !== ModuleInPrototype.PLAN && (
              <HierarchyBreadCrumb
                hierarchy={hierarchy}
                prototypeId={prototypeId}
              />
            )}
        </div>
      </div>
      {showDetails && (
        <div
          className={`activity-log__detailed__container ${
            isToggled
              ? ActivityLogToggleStatus.ACTIVE
              : ActivityLogToggleStatus.INACTIVE
          }`}
        >
          <div className="old-data">
            <div className="activity-log__detailed--title">old {field}:</div>
            <div className="activity-log__detailed--text multiline">
              {oldData}
            </div>
          </div>
          <div className="new-data">
            <div className="activity-log__detailed--title">new {field}:</div>
            <div className="activity-log__detailed--text multiline">
              {newData}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

interface HierarchyBreadCrumbType {
  hierarchy: Hierarchy;
  prototypeId: string;
}
const HierarchyBreadCrumb: FC<HierarchyBreadCrumbType> = ({
  hierarchy,
  prototypeId,
}) => {
  const { handleSetSelectedPlanId } = useSelectedPlan();
  const { updateSelectedLayerId } = useSelectedLayerId();
  // const { handleSetSelectedZoneId } = useSelectedZone();
  // const
  const { redirectToSpaceView } = useRedirect();
  const breadcrumbList = [];
  const { plan, space, layer, zone } = hierarchy;
  plan &&
    breadcrumbList.push({
      title: plan?.title,
      href: "#",
      onClick: () => {
        plan?.id && handleSetSelectedPlanId?.(plan?.id);
      },
    });
  zone &&
    breadcrumbList.push({
      title: zone?.title,
      // href: generatePath(ApiRoutes.ZONE, {
      //   id: prototypeId,
      //   planId: plan?.id,
      //   zoneId: zone?.id,
      // }),
    });
  space &&
    breadcrumbList.push({
      title: space?.title,
      href: generatePath(ApiRoutes.SPACE, {
        id: prototypeId,
        planId: plan?.id,
        zoneId: zone?.id,
        spaceId: space?.id,
      }),
    });
  layer &&
    breadcrumbList.push({
      title: layer?.title,
      href: "#",
      onClick: () => {
        layer?.id && updateSelectedLayerId?.(layer?.id);
        redirectToSpaceView(
          prototypeId,
          plan?.id ?? "",
          zone?.id ?? "",
          space?.id ?? "",
        );
      },
    });
  return <Breadcrumb items={breadcrumbList} size="sm" truncate />;
};

export default ActivityLogCard;
