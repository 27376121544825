import React, { FC } from "react";
import "./productGridView.scss";
import { Category } from "models/Category/category.model";
import { SubCategory } from "models/SubCategory/subCategory.model";
import { Type } from "models/Type/type.model";
import { Skeleton } from "antd";
import SubCategoryList from "./SubCategoryList";
import { useFilterToggle } from "context/FilterToggleContext";
import { ProductParams } from "models/Product/product.model";
import Spinner from "shared/components/Spinner";

interface ProductGridViewType {
  filterMeta: {
    categories: Category[];
    subCategories: SubCategory[];
    types: Type[];
  };
  filterLoading: boolean;
  setParams: React.Dispatch<React.SetStateAction<ProductParams>>;
}
const ProductGridView: FC<ProductGridViewType> = ({
  filterMeta,
  filterLoading,
  setParams,
}) => {
  const { categories, subCategories } = filterMeta;
  const { handleFilterOn } = useFilterToggle();

  return (
    <div className="category-container">
      <Spinner loading={filterLoading}>
        {categories?.map((category, idx) => (
          <>
            {idx ? (
              <div
                className="category-container__empty-1"
                id={category?.id}
              ></div>
            ) : (
              <></>
            )}
            <div className="category-container__empty-2"></div>
            <div className="category-card" key={category?.id}>
              <div className="category-card__header">
                <div className="category-card__header__title tw-font-light">
                  {category?.name}
                </div>
                <div
                  className="category-card__header__view-all"
                  onClick={() => {
                    setParams({
                      ...new ProductParams(),
                      categories: category?.id ? [category?.id] : [],
                    });
                    handleFilterOn?.("subCategories");
                  }}
                >
                  View All
                </div>
              </div>
              <SubCategoryList
                subCategories={subCategories?.filter(
                  (subCategory) => subCategory?.category?.id === category?.id,
                )}
                category={category}
                isLastList={idx + 1 === categories?.length}
              />
            </div>
          </>
        ))}
      </Spinner>
    </div>
  );
};

export default ProductGridView;
