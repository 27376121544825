import React from "react";
import useRedirect from "shared/hooks/useRedirect";
import { Back, Delete } from "shared/icons";
import Button from "shared/components/Button";
import { ButtonType } from "enums/buttonType.enum";
import useToggle from "shared/hooks/useToggle";
import DeleteModal from "../DeleteModal";
import { Space } from "antd";
import { PrototypeWithPlans } from "models/PrototypeWithPlans/prototypeWithPlans";
import { Plan } from "models/Plans/plans.model";
import { Space as SpaceModel } from "models/Space/space.model";
import { Layer } from "models/Layer/layer.model";
import { User } from "models/user.model";
import { userHasAccess } from "utils";
import { Module } from "enums/module.enum";
import { Method } from "enums/method.enum";

interface FormHeaderType {
  redirectToOnDiscard: () => void;
  handleDeleteItem: (itemId?: string) => void;
  isSubmitting: boolean;
  isValid: boolean;
  values: PrototypeWithPlans | Plan | SpaceModel | Layer | User;
  deleteBtnLabel?: string;
  module?: string;
}

const FormHeader = ({
  redirectToOnDiscard,
  handleDeleteItem,
  isSubmitting,
  isValid,
  values,
  module,
  deleteBtnLabel = "Delete Product",
}: FormHeaderType) => {
  const { goBack } = useRedirect();
  const { toggleOn, isToggled, toggleOff } = useToggle();

  const {
    toggleOn: toggleOnDelete,
    isToggled: isDeleteToggled,
    toggleOff: toggleOffDelete,
  } = useToggle();
  const isFormUpdate = !!values?.id;
  return (
    <>
      <div className="product-form-header">
        <div className="product-form-header__back">
          <span
            className="icon-container product-form-header__start"
            onClick={goBack}
          >
            <Back className="icon--back" />
            <span className="text-capitalize">back</span>
          </span>
        </div>
        <div className="product-form-header__end">
          {isFormUpdate &&
            (module === Module.USER_MANAGEMENT
              ? userHasAccess(Module.USER_MANAGEMENT, Method.DELETE)
              : true) && (
              <Button
                type={ButtonType.TEXT}
                className="product-form-header__end__delete"
                icon={<Delete className="icon icon--delete" />}
                onClick={toggleOnDelete}
              >
                {deleteBtnLabel}
              </Button>
            )}
          <Button
            type={ButtonType.DEFAULT}
            className="product-form-header__end__discard"
            onClick={toggleOn}
          >
            Discard
          </Button>
          <Button
            className="text-white"
            loading={isSubmitting}
            htmlType="submit"
            disabled={!isValid}
            type={ButtonType.PRIMARY}
          >
            {isFormUpdate ? "Update" : "Create"}
          </Button>
        </div>
      </div>
      <DeleteModal
        deleteText="Discard"
        hideTrash
        onCancel={toggleOff}
        onDelete={redirectToOnDiscard}
        open={isToggled}
        subTitle="Product info entered will be deleted."
        title="Are you sure to discard?"
        width={366}
      />
      <DeleteModal
        deleteText="Delete"
        onCancel={toggleOffDelete}
        onDelete={handleDeleteItem}
        open={isDeleteToggled}
        subTitle="Product will be deleted from the system"
        title={
          <Space align="start">
            <span className="text-medium">Delete</span>
            <span className="quotes">
              {"title" in values
                ? values?.title
                : "name" in values
                ? values?.name
                : ""}
            </span>
          </Space>
        }
        width={510}
      />
    </>
  );
};

export default FormHeader;
