import React, { useEffect, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { SpaceParams } from "shared/types/routeParams";
import SpaceService from "services/SpaceService/space.service";
import { Form, Formik, FormikConfig } from "formik";
import { Space } from "models/Space/space.model";
import useRedirect from "shared/hooks/useRedirect";
import PlanService from "services/PlanService/plan.service";
import spaceValidation from "./spaceValidation";
import Input from "shared/components/Input";
import TextArea from "shared/components/TextArea";
import Button from "shared/components/Button";
import { ButtonType } from "enums/buttonType.enum";

import "./spaceForm.scss";
import { Back, Delete, ViewDescription } from "shared/icons";
import DeleteModal, { DeleteModalProps } from "shared/components/DeleteModal";
import Spinner from "shared/components/Spinner";
import { useSelectedPlan } from "context/SelectedPlanContext";
import Notification from "shared/components/Notification";
import { NotificationTypes } from "enums/notificationTypes";
import { ApiRoutes } from "routes/routeConstants/apiRoutes";
import { usePinMarkers } from "context/PinMarkersContext";

const SpaceForm = () => {
  const { prototypeId, planId, spaceId, zoneId } = useParams<SpaceParams>();
  const [modalProps, setModalProps] = useState<DeleteModalProps>({
    open: false,
    loading: false,
    onCancel: () => {
      return;
    },
    onDelete: () => {
      return;
    },
    subTitle: "",
    title: <></>,
    deleteText: "",
    hideTrash: true,
  });
  const { plan, updatePlanDetails } = useSelectedPlan();
  const { removePinMarkerForSpaceId } = usePinMarkers();
  const {
    createSpace,
    updateSpace,
    showSpace,
    deleteSpace,
    space,
    spaceLoading,
    loading: spaceViewLoading,
  } = SpaceService();

  const { showPlan, loading } = PlanService();
  const navigate = useNavigate();
  const { goBack } = useRedirect();

  const handleSubmit: FormikConfig<Space>["onSubmit"] = async (space) => {
    if (!prototypeId || !planId || !zoneId) return;

    const updatedSpace = await (space?.id ? updateSpace : createSpace)(
      prototypeId,
      planId,
      zoneId,
      space,
    );
    if (plan && updatedSpace) {
      // const { spaces, ...planWithoutSpaces } = plan;
      // const updatedSpacesList = [...spaces];
      // updatedSpacesList.push(updatedSpace);
      const planZones = plan?.zones;

      if (planZones) {
        const updatedZone = planZones.find((zone) => zone.id === zoneId);
        if (updatedZone && updatedZone.spaces) {
          const updatedSpaceIndex = updatedZone.spaces?.findIndex(
            (space) => space.id === updatedSpace.id,
          );
          if (updatedSpaceIndex !== undefined && updatedSpaceIndex !== -1) {
            updatedZone.spaces[updatedSpaceIndex as number] = updatedSpace;
          } else {
            updatedZone.spaces?.push(updatedSpace);
          }
        }
      }

      // updatePlanDetails?.({ ...planWithoutSpaces, spaces: updatedSpacesList });
    }
    if (!updatedSpace) return;
    goBack();
  };

  const handleDelete = async () => {
    const deleted = await deleteSpace(
      prototypeId ?? "",
      planId ?? "",
      zoneId ?? "",
      spaceId ?? "",
    );
    if (!deleted) return;
    //For realtime update, once space is deleted
    // pin markers for that space should disappear
    spaceId && removePinMarkerForSpaceId?.(spaceId);
    // remove that space from space listing in the opened plan
    // plan &&
    //   spaceId &&
    //   updatePlanDetails?.({
    //     ...plan,
    //     spaces: plan?.spaces?.filter((space) => space?.id !== spaceId),
    //   });

    if (plan && zoneId && plan.zones) {
      const planZone = plan.zones.find((zone) => zone.id === zoneId);

      if (planZone && planZone.spaces) {
        planZone.spaces = planZone.spaces.filter(
          (space) => space.id !== spaceId,
        );

        updatePlanDetails?.({
          ...plan,
          zones: plan.zones.map((zone) =>
            zone.id === zoneId ? planZone : zone,
          ),
        });
      }
    }

    navigate(generatePath(ApiRoutes?.PROTOTYPE, { id: prototypeId }));
  };

  const handleEdit = async (
    submitForm: (() => Promise<void>) & (() => Promise<any>),
  ) => {
    await submitForm();
  };

  useEffect(() => {
    if (!prototypeId || !planId || !zoneId) return;

    if (!spaceId) {
      showPlan(prototypeId, planId);
      return;
    }

    showSpace(prototypeId, planId, zoneId, spaceId);
  }, [prototypeId, planId, spaceId, zoneId]);

  return (
    <Spinner loading={spaceViewLoading || loading}>
      <div className="space-form">
        <Formik
          initialValues={space}
          onSubmit={handleSubmit}
          validationSchema={spaceValidation}
        >
          {({ isSubmitting, submitForm, isValid }) => (
            <>
              <Form>
                <div className="space-form__container">
                  <div className="space-form__container__buttons">
                    <div
                      className="space-form__container__buttons__back"
                      onClick={goBack}
                    >
                      <span className="icon-container">
                        <Back className="icon--back" />
                        <span className="text-capitalize">back</span>
                      </span>
                    </div>
                    <div className="space-form__container__buttons__form-action">
                      {spaceId && (
                        <Button
                          type={ButtonType.TEXT}
                          icon={<Delete className="icon-delete" />}
                          className="button--delete"
                          onClick={() => {
                            setModalProps({
                              hideTrash: false,
                              onDelete: () => {
                                handleDelete();
                              },
                              onCancel: () => {
                                setModalProps({ open: false, ...modalProps });
                              },
                              open: true,
                              deleteText: "Delete",
                              subTitle:
                                "This space will be permanently deleted.",
                              loading: spaceLoading,
                            });
                          }}
                        >
                          Delete Space
                        </Button>
                      )}
                      <Button
                        type={ButtonType.DEFAULT}
                        onClick={() => {
                          setModalProps({
                            hideTrash: true,
                            onDelete: () => {
                              goBack();
                            },
                            onCancel: () => {
                              setModalProps({ ...modalProps });
                            },
                            open: true,
                            deleteText: "Discard",
                            title: "Are you sure to discard?",
                            subTitle: "The changes made will not be saved.",
                            width: 340,
                          });
                        }}
                      >
                        Discard
                      </Button>
                      <Button
                        htmlType={spaceId ? undefined : "submit"}
                        loading={spaceId ? false : isSubmitting}
                        onClick={() => {
                          spaceId
                            ? setModalProps({
                                hideTrash: true,
                                onDelete: () => {
                                  if (isValid) {
                                    handleEdit(submitForm);
                                  } else {
                                    setModalProps({
                                      ...modalProps,
                                      open: false,
                                    });
                                    Notification({
                                      message: "Invalid data provided",
                                      type: NotificationTypes.ERROR,
                                    });
                                  }
                                },
                                onCancel: () => {
                                  setModalProps({ ...modalProps });
                                },
                                open: true,
                                deleteText: "Update",
                                title: "Are you sure to update?",
                                width: 340,
                              })
                            : null;
                        }}
                        type={ButtonType.PRIMARY}
                      >
                        {space?.id ? "Update" : "Create"}
                      </Button>
                    </div>
                  </div>
                  <div className="space-form__container__img">
                    <div className="display-flex">
                      <Input.Formik
                        name="title"
                        placeholder="Enter Space Name"
                      />
                    </div>
                  </div>
                  <div className="space-form__description__container">
                    <div className="space-form__description__title">
                      <ViewDescription className="icon-view-description" />
                      <span>Space Description</span>
                    </div>
                    <TextArea.Formik
                      name="description"
                      rows={10}
                      className="space-form__description__input"
                    />
                  </div>
                </div>
              </Form>

              {/* Confirmation modal */}
              <DeleteModal {...modalProps} />
            </>
          )}
        </Formik>
      </div>
    </Spinner>
  );
};

export default SpaceForm;
