import React, { useRef, useState } from "react";
import { PrototypeWithPlans } from "models/PrototypeWithPlans/prototypeWithPlans";
import { Form, Formik, FormikConfig, FormikProps } from "formik";
import Input from "shared/components/Input";
import Button from "shared/components/Button";
import { ButtonType } from "enums/buttonType.enum";
import "./clientEdit.scss";
import UploadedImage from "shared/components/UploadedImage";
import { Tooltip } from "antd";
import UploadInput from "shared/components/UploadInput";
import { Back } from "shared/icons";
import DeleteModal, { DeleteModalProps } from "shared/components/DeleteModal";
import { Clients } from "models/ClientSettings/clientSettings.model";
import ClientService from "services/ClientSettings/clientSettings.service";
import { validationSchema } from "./clientEdit_validation";

interface ClientSettingsEditProps {
  handleBackClick: () => void;
  client: Clients;
}

const ClientSettingsEdit: React.FC<ClientSettingsEditProps> = ({
  handleBackClick,
  client,
}) => {
  const { updateClients, clientUpdateLoading } = ClientService();

  const [modalProps, setModalProps] = useState<DeleteModalProps>({
    open: false,
    loading: false,
    onCancel: () => {
      return;
    },
    onDelete: () => {
      return;
    },
    subTitle: "",
    title: <></>,
    deleteText: "",
    hideTrash: true,
  });

  const handleSubmit: FormikConfig<Clients>["onSubmit"] = async (client) => {
    const updateClient = updateClients(client);

    if (!updateClient) return;

    handleBackClick();
  };

  const handleEdit = async (
    submitForm: (() => Promise<void>) & (() => Promise<void>),
  ) => {
    await submitForm();
    handleBackClick();
  };

  const formikRef = useRef<FormikProps<Clients> | null>(null);

  return (
    <>
      <div className="client_edit_form">
        <Formik
          initialValues={client}
          onSubmit={handleSubmit}
          enableReinitialize
          validationSchema={validationSchema}
          innerRef={formikRef}
        >
          {({ isSubmitting, values, submitForm }) => (
            <>
              <Form>
                <div className="client_edit_form__container">
                  <div className="client_edit_form__container__buttons">
                    <div
                      className="client_edit_form__container__buttons__back"
                      onClick={handleBackClick}
                    >
                      <span className="icon-container">
                        <Back className="icon--back" />
                        <span className="text-capitalize">back</span>
                      </span>
                    </div>
                    <div className="client_edit_form__container__buttons__form-action">
                      <Button
                        type={ButtonType.DEFAULT}
                        onClick={() => {
                          setModalProps({
                            hideTrash: true,
                            onDelete: () => {
                              handleBackClick();
                            },
                            onCancel: () => {
                              setModalProps({ ...modalProps, open: false });
                            },
                            open: true,
                            deleteText: "Discard",
                            title: "Are you sure to discard?",
                            subTitle: "The changes made will not be saved.",
                            width: 340,
                          });
                        }}
                      >
                        Discard
                      </Button>
                      <Button
                        onClick={async () => {
                          await formikRef.current?.validateForm();
                          if (formikRef.current?.isValid)
                            setModalProps({
                              hideTrash: true,
                              onDelete: () => {
                                handleEdit(submitForm);
                              },
                              onCancel: () => {
                                setModalProps({ ...modalProps, open: false });
                              },
                              open: true,
                              deleteText: "Update",
                              title: "Are you sure to update?",
                              loading: isSubmitting,
                              width: 340,
                            });
                        }}
                        type={ButtonType.PRIMARY}
                      >
                        Update
                      </Button>
                    </div>
                  </div>
                  <div className="client_edit_form__container__img">
                    <div className="display-flex">
                      <div className="mg-r-8">
                        {values?.image ? (
                          <UploadedImage
                            expandable
                            width={44}
                            onDelete={() => {
                              client.image = undefined;
                              formikRef.current?.setValues(client);
                            }}
                            hideRemoveTextOnOverlay
                            src={values?.image?.url}
                          />
                        ) : (
                          <Tooltip title="Supported formats: JPG, JPEG & PNG in 1:1 ratio and < 10 MB">
                            <UploadInput.Formik
                              name={"image"}
                              width={20}
                              height={44}
                            />
                          </Tooltip>
                        )}
                      </div>
                      <Input.Formik
                        name="clientName"
                        placeholder="Enter Client Name"
                      />
                    </div>{" "}
                    <div className="client_edit_form__container__row">
                      <Input.Formik
                        label="Email"
                        name="email"
                        type="email"
                        placeholder="Enter"
                        className="inputfield"
                      />
                      <Input.Formik
                        label="Phone"
                        name="phoneNumber"
                        placeholder="Enter"
                        className="inputfield"
                      />{" "}
                    </div>
                  </div>
                </div>
              </Form>

              {/* Confirmation modal */}
              <DeleteModal {...modalProps} />
            </>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ClientSettingsEdit;
