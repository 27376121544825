export const priceUnitTypes = [
  { label: "Square foot", value: "squareFoot" },
  { label: "Square meter", value: "squareMeter" },
  { label: "Cubic yard", value: "cubicYard" },
  { label: "Cubic meter", value: "cubicMeter" },
  { label: "Linear foot", value: "linearFoot" },
  { label: "Meter", value: "meter" },
  { label: "Ton", value: "ton" },
  { label: "Metric ton", value: "metricTon" },
  { label: "Pound", value: "pound" },
  { label: "Kilogram", value: "kilogram" },
  { label: "Gallon", value: "gallon" },
  { label: "Liter", value: "liter" },
  { label: "Yard", value: "yard" },
  { label: "Piece", value: "piece" },
  { label: "Sheet", value: "sheet" },
  { label: "Roll", value: "roll" },
  { label: "Unit", value: "unit" },
  { label: "Other", value: "other" },
];

export const priceUnitTypesMap: Record<string, string> = priceUnitTypes.reduce(
  (acc, { label, value }) => ({ ...acc, [value]: label }),
  {},
);
