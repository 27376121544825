import React from "react";
import Tabs from "shared/components/Tabs";
import tabItems from "./tabItems";

import "./configurations.scss";
import { Method } from "enums/method.enum";
import { userHasAccess } from "utils";

const Configurations = () => {
  return (
    <div className="configurations">
      <Tabs
        items={tabItems?.filter((tabItem) =>
          userHasAccess(tabItem.key, Method.VIEW)
        )}
        tabPosition="top"
      />
    </div>
  );
};

export default Configurations;
