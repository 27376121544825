import * as Yup from "yup";
import { getLocationValidation } from "models/Location/location.validation";
import { currencyValidationSchemaV2 } from "models/CostPerItem/currency.validation";
import getObjectIdSchema from "shared/utils/getObjectIdValidation";

export const variantValidation = Yup.object().shape({
  color: getObjectIdSchema(false),
  cost: currencyValidationSchemaV2,
  count: Yup.number().typeError("Count must be a number"),
  // .required("Count is required"),
  image: getObjectIdSchema(false),
  leadTime: Yup.number().typeError("Lead Time must be a number"),
  location: getLocationValidation(false),
  material: getObjectIdSchema(false),
  // .required("Material is required"),
  name: Yup.string().required("Name is required"),
});
