import React from "react";
import { Copy, Edit } from "shared/icons";
import { useLocation, useParams } from "react-router-dom";
import { EditProductParams } from "shared/types/routeParams";
import useRedirect from "shared/hooks/useRedirect";
import { Product } from "models/Product/product.model";

import "./viewProductHeader.scss";
import Breadcrumb from "shared/components/Breadcrumb";
import { Space } from "antd";
import CopyToClipboard from "react-copy-to-clipboard";
import { ApiRoutes } from "routes/routeConstants/apiRoutes";
import QueryString from "qs";
import SkeletonInput from "antd/es/skeleton/Input";
import SkeletonButton from "antd/es/skeleton/Button";
import { Method } from "enums/method.enum";
import { userHasAccess } from "utils";
import { Module } from "enums/module.enum";

interface ViewProductProps {
  product: Product;
  loading: boolean;
}
const ViewProductHeader = ({ product, loading }: ViewProductProps) => {
  const { productId } = useParams<EditProductParams>();

  const { goBack, redirectToEditProduct } = useRedirect();

  const handleEditClick = () => {
    redirectToEditProduct(productId);
  };

  const breadcrumbs = [
    {
      title: product?.category?.name,
      href: "/products",
    },
    {
      title: product?.subCategory?.name,
      href: `${ApiRoutes.PRODUCTS}?${QueryString.stringify(
        {
          categories: [product?.category?.id],
          subCategories: [product?.subCategory?.id],
        },
        {
          skipNulls: true,
        }
      )}`,
    },
    {
      title: (
        <Space onClick={(e) => e.stopPropagation()}>
          <span>{product?.type?.name}</span>
          <Space className="view-product-header__back__copy text-grey-2">
            <span>(SKU: {product?.code})</span>
            <CopyToClipboard text={product?.code || ""}>
              <Copy className="icon-grey-3 icon--copy" />
            </CopyToClipboard>
          </Space>
        </Space>
      ),
    },
  ];

  return (
    <>
      <header className="view-product-header">
        <span
          className="icon-container view-product-header__back"
          onClick={goBack}
        >
          {loading ? (
            <SkeletonInput
              size="small"
              active
              className="breadcrumb-skeleton"
            />
          ) : (
            <Breadcrumb items={breadcrumbs} size="sm" />
          )}
        </span>
        <div className="view-product-header__end">
          <span className="icon-container" onClick={handleEditClick}>
            {loading ? (
              <SkeletonButton active size="small" />
            ) : (
              userHasAccess(Module.PRODUCT, Method.EDIT) && (
                <>
                  <Edit className="icon-primary icon--edit tw-w-8 tw-h-8 tw-mr-0" />
                  <span className="text-primary">Edit</span>
                </>
              )
            )}
          </span>
        </div>
      </header>
    </>
  );
};

export default ViewProductHeader;
