import { SubCategory } from "models/SubCategory/subCategory.model";
import QueryString from "qs";
import React from "react";
import { useNavigate } from "react-router-dom";
import Img from "../../../../assets/images/prodImg.png";
import "../productGridView.scss";
import { Category } from "models/Category/category.model";
import Carousel, { ResponsiveType } from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import LazyLoadedImage from "shared/components/LazyLoadedImage";

/* eslint-disable */
interface SubCategoryListType {
  subCategories?: SubCategory[];
  category?: Category;
  isLastList: boolean;
}

const carouselResponsiveWidthConfig: ResponsiveType = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1800 },
    items: 5,
    slidesToSlide: 5,
  },
  desktop: {
    breakpoint: { max: 1800, min: 1024 },
    items: 4,
    slidesToSlide: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const SubCategoryList = ({ subCategories, category }: SubCategoryListType) => {
  const navigate = useNavigate();

  return (
    <Carousel
      additionalTransfrom={0}
      arrows
      autoPlay={false}
      centerMode={false}
      className=""
      containerClass="rmc__container"
      dotListClass="rmc--dot--list"
      draggable
      focusOnSelect={false}
      infinite={false}
      itemClass="rmc__item"
      keyBoardControl
      minimumTouchDrag={80}
      pauseOnHover
      renderArrowsWhenDisabled={false}
      renderButtonGroupOutside={false}
      renderDotsOutside={false}
      responsive={carouselResponsiveWidthConfig}
      rewind={false}
      rewindWithAnimation={false}
      rtl={false}
      shouldResetAutoplay
      showDots={false}
      sliderClass=""
      swipeable
    >
      {subCategories?.map((subCategory) => (
        <div
          className="subcategory-list__contain__content-list"
          key={subCategory?.id}
          onClick={() => {
            navigate({
              pathname: location.pathname,
              hash: location.hash,
              search: QueryString.stringify(
                {
                  categories: [category?.id],
                  subCategories: [subCategory?.id],
                },
                {
                  skipNulls: true,
                },
              ),
            });
          }}
        >
          <div className="subcategory-list__contain__content-list__content hover-animation-cat">
            <div className="subcategory-list__contain__content-list__content__image">
              <LazyLoadedImage
                src={
                  subCategory?.image?.thumbnailUrl ||
                  subCategory?.image?.url ||
                  Img
                }
                alt={subCategory?.name}
              />
            </div>
            <div className="jus">
              <p className="subcategory-list__contain__content-list__content__title !text-2xl">
                {subCategory?.name}
              </p>
            </div>
          </div>
        </div>
      ))}
    </Carousel>
  );
};

export default SubCategoryList;
