import { useState } from "react";
import { deserialize, serialize } from "serializr";
import axiosInstance from "../../interceptor/axiosInstance";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import Axios, { CancelTokenSource } from "axios";
import Notification from "shared/components/Notification";
import { NotificationTypes } from "enums/notificationTypes";
import { generatePath } from "react-router-dom";
import { ProtoTypeNotification } from "models/ProtoTypeNotification/prototypeNotification.model";

const ProtoTypeNotificationService = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [prototypeNotificationLoading, setprotoTypeNotificationLoading] =
    useState(false);

  const [notificationsEdit, setNotificationsEdit] =
    useState<ProtoTypeNotification>(new ProtoTypeNotification());

  const [notifications, setNotifications] = useState<ProtoTypeNotification>(
    new ProtoTypeNotification(),
  );
  const [activityNotificationsToken, setActivityToken] =
    useState<CancelTokenSource>();

  const updatePrototypeNotification = async (
    notification: ProtoTypeNotification,
    protoTypeId: string,
  ) => {
    try {
      setprotoTypeNotificationLoading(true);
      const ENDPOINT = generatePath(
        ApiRoutes.PROTOTYPE_ACTIVITY_NOTIFICATIONS,
        { protoTypeId },
      );

      const { data } = await axiosInstance.put(ENDPOINT, {
        notification: serialize(ProtoTypeNotification, notification),
      });
      const activityNotificationUpdated = deserialize(
        ProtoTypeNotification,
        data["notification"],
      );
      setNotifications(activityNotificationUpdated);
      setNotificationsEdit({ ...activityNotificationUpdated });

      Notification({
        message: "Activity Notification Updated Successfully",
        type: NotificationTypes.SUCCESS,
      });
    } catch (ex) {
      Notification({
        message:
          (ex as Error)?.message || "Unable to update Activity Notification",
        type: NotificationTypes.ERROR,
      });
    } finally {
      setprotoTypeNotificationLoading(false);
    }
  };
  const fetchProtoTypeNotifications = async (protoTypeId: string) => {
    try {
      setLoading(true);

      activityNotificationsToken?.cancel(
        "protoType Notification Fetch Cancelled",
      );

      const newCancelToken = Axios.CancelToken.source();

      setActivityToken(newCancelToken);
      const ENDPOINT = generatePath(
        ApiRoutes.PROTOTYPE_ACTIVITY_NOTIFICATIONS,
        { protoTypeId },
      );

      const { data } = await axiosInstance.get(ENDPOINT, {
        cancelToken: newCancelToken.token,
      });
      const notificationResponse = deserialize(
        ProtoTypeNotification,
        data["notification"],
      );

      setNotifications(notificationResponse);
      setNotificationsEdit({ ...notificationResponse });

      setActivityToken(undefined);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    notifications,
    notificationsEdit,
    setNotificationsEdit,
    prototypeNotificationLoading,
    fetchProtoTypeNotifications,
    updatePrototypeNotification,
  };
};

export default ProtoTypeNotificationService;
