// src/CardWidget.tsx
import React, { useState } from "react";
import "./cardWidget.scss";
import UploadedImage from "shared/components/UploadedImage";

interface CardWidgetProps {
  name: string;
  imageUrl: string | undefined;
}

const CardWidget: React.FC<CardWidgetProps> = ({ name, imageUrl }) => {
  const [visible, setisVisible] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    setLoading(false);
  };
  return (
    <>
      {imageUrl ? (
        <UploadedImage
          expandable
          width={100}
          hideRemoveTextOnOverlay
          src={imageUrl}
          isCircle={true}
        />
      ) : (
        <div className="card">
          <div className="circle">
            <p className="name">{name.toUpperCase()}</p>
          </div>
        </div>
      )}
    </>
  );
};
export default CardWidget;
