import { generatePath, useNavigate } from "react-router-dom";
import { AppRoutes, NavigationRoutes } from "routes/routeConstants/appRoutes";
import qs from "qs";
import { AuthContext } from "context/AuthContext";
import { userHasAccess } from "utils";
import { Module } from "enums/module.enum";
import { Method } from "enums/method.enum";

import Notification from "shared/components/Notification";
import { NotificationTypes } from "enums/notificationTypes";
import { prototype } from "events";

const useRedirect = () => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  const redirectToLogin = () => navigate(NavigationRoutes.LOGIN);
  const accessItems = [
    {
      module: [Module.PROTOTYPE],
      method: Method.VIEW,
      route: NavigationRoutes.PROTOTYPE,
    },
    {
      module: [Module.PRODUCT],
      method: Method.VIEW,
      route: NavigationRoutes.PRODUCTS,
    },
    {
      module: [Module.PICKLIST],
      method: Method.VIEW,
      route: NavigationRoutes.PICKLIST,
    },
    {
      module: [Module.USER_MANAGEMENT],
      method: Method.VIEW,
      route: NavigationRoutes.USERS,
    },
    {
      module: [
        Module.CLIENT_SETTINGS,
        Module.USER_ACCESS_AND_LIMIT,
        Module.CATEGORY_CONFIGURATION,
      ],
      method: Method.VIEW,
      route: NavigationRoutes.SETTINGS,
    },
  ];

  const redirectToDashBoardListing = () => {
    for (const item of accessItems) {
      for (const module in item.module) {
        if (userHasAccess(item.module[module], item.method)) {
          return navigate(item.route);
        }
      }
      return navigate(NavigationRoutes.NO_ACCESS);
    }
  };

  const redirectToDashBoard = () => navigate(NavigationRoutes.PRODUCTS);

  const redirectToProductSearch = () =>
    navigate(NavigationRoutes.PRODUCTS_SEARCH);

  const redirectToAddProduct = (
    categoryId?: string,
    subCategoryId?: string,
    typeId?: string
  ) =>
    navigate({
      pathname: AppRoutes.ADD_PRODUCT,
      search: qs.stringify({ categoryId, subCategoryId, typeId }),
    });

  const redirectToViewProduct = (id?: string) => {
    if (!id) return;

    navigate(
      generatePath(AppRoutes.VIEW_PRODUCT, {
        productId: id,
      })
    );
  };

  const redirectToNotification = (protoTypeId: string) => {
    return navigate({
      pathname: NavigationRoutes.NOTIFICATION,
      search: qs.stringify({ prototypeId: protoTypeId }),
    });
  };

  const redirectToEditProduct = (id?: string) => {
    if (!id) return;

    navigate(
      generatePath(AppRoutes.EDIT_PRODUCT, {
        productId: id,
      })
    );
  };
  const redirectToEditUser = (userId: string) => {
    navigate(generatePath(NavigationRoutes.EDIT_USER, { userId }));
  };
  const redirectToPrototypes = () =>
    navigate(generatePath(NavigationRoutes.PROTOTYPE));

  const redirectToAddSpaceForm = (
    prototypeId: string,
    planId: string,
    zoneId: string
  ) =>
    navigate(
      generatePath(AppRoutes.ADD_SPACE, { prototypeId, planId, zoneId })
    );

  const redirectToEditSpaceForm = (
    prototypeId: string,
    planId: string,
    zoneId: string,
    spaceId: string
  ) => {
    navigate(
      generatePath(AppRoutes.EDIT_SPACE, {
        prototypeId,
        planId,
        zoneId,
        spaceId,
      })
    );
  };

  const redirectToAddZoneForm = (prototypeId: string, planId: string) =>
    navigate(generatePath(AppRoutes.ADD_ZONE, { prototypeId, planId }));

  const redirectToEditZoneForm = (
    prototypeId: string,
    planId: string,
    zoneId: string
  ) =>
    navigate(
      generatePath(AppRoutes.EDIT_ZONE, {
        prototypeId,
        planId,
        zoneId,
      })
    );

  const redirectToPrototypeView = (prototypeId: string) =>
    navigate({
      pathname: generatePath(NavigationRoutes.PROTOTYPE, {
        id: prototypeId,
      }),
      search: qs.stringify({ prototype: prototypeId }),
    });

  const redirectToViewPrototype = (prototypeId: string) =>
    navigate({
      pathname: generatePath(NavigationRoutes.VIEW_PROTOTYPE, {
        prototypeId,
      }),
    });

  const redirectToSpaceView = (
    prototypeId: string,
    planId: string,
    zoneId: string,
    spaceId: string
  ) =>
    navigate({
      pathname: generatePath(NavigationRoutes.VIEW_SPACE, {
        prototypeId,
        planId,
        zoneId,
        spaceId,
      }),
    });

  const redirectToZoneView = (
    prototypeId: string,
    planId: string,
    zoneId: string
  ) =>
    navigate({
      pathname: generatePath(NavigationRoutes.VIEW_ZONE, {
        prototypeId,
        planId,
        zoneId,
      }),
    });

  const redirectToAddPrototype = () =>
    navigate(generatePath(NavigationRoutes.ADD_PROTOTYPE));

  const redirectToAddPlan = (prototypeId: string) =>
    navigate(
      generatePath(AppRoutes.ADD_PLAN, {
        prototypeId,
      })
    );
  const redirectToEditPlan = (prototypeId: string, planId: string) =>
    navigate(
      generatePath(AppRoutes.EDIT_PLAN, {
        prototypeId,
        planId,
      })
    );

  const redirectToAddLayer = (
    prototypeId: string,
    planId: string,
    spaceId: string
  ) =>
    navigate(
      generatePath(AppRoutes.ADD_LAYER, {
        prototypeId,
        planId,
        spaceId,
      })
    );
  const redirectToEditLayer = (
    prototypeId: string,
    planId: string,
    spaceId: string,
    layerId: string
  ) =>
    navigate(
      generatePath(AppRoutes.EDIT_LAYER, {
        prototypeId,
        planId,
        spaceId,
        layerId,
      })
    );
  const redirectToPlanView = (prototypeId: string, planId: string) =>
    navigate(
      generatePath(AppRoutes.VIEW_PLAN, {
        prototypeId,
        planId,
      })
    );

  const redirectToUpdatePrototype = (
    prototypeId: string,
    fromNotification = false
  ) => {
    const path = generatePath(AppRoutes.UPDATE_PROTOTYPE, {
      prototypeId,
    });

    if (fromNotification) {
      return navigate({
        pathname: path,
        search: qs.stringify({ fromNotification: true }),
      });
    }

    return navigate(
      generatePath(AppRoutes.UPDATE_PROTOTYPE, {
        prototypeId,
      })
    );
  };

  const redirectToProductLists = (
    prototypeId: string,
    planId: string,
    spaceId: string,
    layerId: string
  ) => {
    navigate(
      generatePath(AppRoutes.PRODUCT_LIST, {
        prototypeId,
        planId,
        spaceId,
        layerId,
      })
    );
  };

  const redirectToAddUserForm = () => {
    navigate(NavigationRoutes.ADD_USER);
  };
  const redirectToUsers = () => {
    navigate(NavigationRoutes.USERS);
  };

  const redirectToProductListsReplace = (
    prototypeId: string,
    planId: string,
    spaceId: string,
    layerId: string,
    layerVariantId: string
  ) => {
    navigate(
      generatePath(AppRoutes.PRODUCT_REPLACE, {
        prototypeId,
        planId,
        spaceId,
        layerId,
        layerVariantId,
      })
    );
  };

  return {
    goBack,
    redirectToDashBoardListing,
    redirectToDashBoard,
    redirectToProductSearch,
    redirectToAddProduct,
    redirectToEditProduct,
    redirectToViewProduct,
    redirectToPrototypes,
    redirectToAddPrototype,
    redirectToUpdatePrototype,
    redirectToAddPlan,
    redirectToEditPlan,
    redirectToPlanView,
    redirectToAddSpaceForm,
    redirectToPrototypeView,
    redirectToViewPrototype,
    redirectToSpaceView,
    redirectToZoneView,
    redirectToAddLayer,
    redirectToEditLayer,
    redirectToProductLists,
    redirectToEditSpaceForm,
    redirectToAddUserForm,
    redirectToEditUser,
    redirectToUsers,
    redirectToProductListsReplace,
    redirectToLogin,
    redirectToNotification,
    redirectToAddZoneForm,
    redirectToEditZoneForm,
  };
};

export default useRedirect;
