import { Form, Formik } from "formik";
import React, { FC, useState } from "react";
import { CategoryConfiguration } from "models/CategoryConfiguration/categoryConfiguration.model";
import CategoryConfigInput from "shared/components/CategoryConfigInput";
import { ProductMeta } from "models/Product/product.model";
import Button from "shared/components/Button";
import { ButtonType } from "enums/buttonType.enum";
import { productMappingValidation } from "./productMapping.validation";
import DeleteConfigModal from "../DeleteConfigModal";
import cloneDeep from "lodash.clonedeep";
import Modal from "antd/es/modal/Modal";
import Loader from "shared/components/Loader";
import get from "lodash.get";

import "./deleteProductMapping.scss";
import { Skeleton } from "antd";
import Spinner from "shared/components/Spinner";

interface DeleteProductMappingProps {
  meta?: ProductMeta;
  config?: CategoryConfiguration;
  loading: boolean;
  onCancel: () => void;
  onDelete: () => void;
}

const DeleteProductMapping: FC<DeleteProductMappingProps> = ({
  config = new CategoryConfiguration(),
  meta,
  loading,
  onCancel,
  onDelete,
}) => {
  const [mappedConfig, setMappedConfig] = useState<CategoryConfiguration>();

  const isTypeDelete = !!config?.type?.id;

  const isSubCategoryDelete = !isTypeDelete && !!config?.subCategory?.id;

  const name = isTypeDelete
    ? "Type"
    : isSubCategoryDelete
      ? "Sub Category"
      : "Category";

  const closeDeleteModal = () => setMappedConfig(undefined);

  const isDifferentValue = <T extends object>(
    oldObj: T,
    newObj: T,
    path: string,
  ) => {
    const newValue = get(newObj, path);
    const oldValue = get(oldObj, path);

    return !!newValue && newValue !== oldValue;
  };

  const handleConfirmButtonDisable = (values: CategoryConfiguration) => {
    return isTypeDelete
      ? !isDifferentValue(config, values, "type.id")
      : isSubCategoryDelete
        ? !isDifferentValue(config, values, "subCategory.id")
        : !isDifferentValue(config, values, "category.id");
  };

  return (
    <>
      <Modal
        centered
        className="config-delete-modal"
        closable={false}
        footer={null}
        open={!!config}
        width={806}
      >
        <Spinner loading={loading}>
          <div className="delete-product-mapping">
            <div className="delete-product-mapping__header">
              <div className="text-heavy delete-product-mapping__header__title">
                {meta?.total} Products assigned with the {name}
              </div>
              <div className="text-sm delete-product-mapping__header__sub-title">
                Map the products with different {name} to proceed with the
                deletion
              </div>
            </div>
            <div className="delete-product-mapping__main">
              <Formik
                enableReinitialize
                initialValues={cloneDeep(config)}
                onSubmit={setMappedConfig}
                validationSchema={productMappingValidation}
              >
                {({ isValid, values }) => (
                  <Form>
                    <CategoryConfigInput />
                    <div className="delete-product-mapping__main__footer">
                      <Button onClick={onCancel} type={ButtonType.DEFAULT}>
                        cancel
                      </Button>
                      <Button
                        className="text-white"
                        disabled={
                          !isValid || handleConfirmButtonDisable(values)
                        }
                        htmlType="submit"
                        type={ButtonType.PRIMARY}
                      >
                        confirm
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          <DeleteConfigModal
            config={config}
            mappedConfig={mappedConfig}
            open={!!mappedConfig}
            onCancel={closeDeleteModal}
            onDelete={onDelete}
          />
        </Spinner>
      </Modal>
    </>
  );
};

export default DeleteProductMapping;
