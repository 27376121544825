import React from "react";
import { Close, Delete, ExpandArrowsAlt } from "shared/icons";

import "./uploadedImage.scss";
import useToggle from "shared/hooks/useToggle";
import ImagePreview from "../ImagePreview";
import LazyLoadedImage from "../LazyLoadedImage";

interface UploadedImageProps {
  expandable?: boolean;
  onDelete?: () => void;
  hideRemoveTextOnOverlay?: boolean;
  width?: string | number;
  height?: string | number;
  src?: string;
  aspectRatio?: "square" | "auto" | number;
  loaderClassName?: string;
  isCircle?: boolean;
}

const UploadedImage = ({
  expandable,
  aspectRatio,
  onDelete,
  hideRemoveTextOnOverlay,
  src,
  isCircle,
  loaderClassName,
}: UploadedImageProps) => {
  const { isToggled, toggleOn, toggleOff } = useToggle();

  return (
    <>
      <div
        className={
          isCircle ? "uploaded-image-circle" : "uploaded-image__container"
        }
      >
        <div
          className="uploaded-image"
          onClick={!expandable ? onDelete : undefined}
        >
          <div className="uploaded-image__overlay">
            <div className="uploaded-image__overlay__content">
              {expandable ? (
                <ExpandArrowsAlt
                  onClick={toggleOn}
                  className="icon--white icon--expand-arrows-alt"
                />
              ) : (
                <Delete className="icon--white icon--delete" />
              )}
              {!hideRemoveTextOnOverlay && <div>Remove</div>}
            </div>
          </div>
          <LazyLoadedImage
            aspectRatio={aspectRatio}
            loaderClassName={loaderClassName}
            imageClassName="uploaded-image__content"
            src={src ?? ""}
          />
        </div>
        <Close className="uploaded-image__delete--overlay" onClick={onDelete} />
      </div>
      <ImagePreview
        url={src}
        visible={isToggled}
        onClose={toggleOff}
        onDelete={() => {
          onDelete?.();
          toggleOff();
        }}
      />
    </>
  );
};

export default UploadedImage;
