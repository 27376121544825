import { useSelectedLayerId } from "context/SelectedLayerContext";
import { Coordinate } from "models/Coordinate/coordinate.model";
import React, { createContext, FC, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import LayerService from "services/LayerService/layer.service";

interface SelectedVariantModel {
  id: string;
  coordinates?: Coordinate;
}

interface SelectedVariantType {
  selectedVariant?: SelectedVariantModel;
  updateSelectedVariantId?: (variant?: SelectedVariantModel) => void;
  handlePinProductToLayer?: (coordinate: Coordinate) => void;
  handleUnpinProductFromLayer?: (selectedSpaceId: string) => void;
}

//create context
const SelectedVariantContext = createContext<SelectedVariantType>({});

// create context provider
const SelectedVariantState: FC<{ viewId?: string }> = ({
  viewId,
  children,
}) => {
  const [selectedVariant, setSelectedVariantId] =
    useState<SelectedVariantModel>();
  const { selectedLayerId, layer, updateLayerDetails } = useSelectedLayerId();
  const { prototypeId, planId, spaceId } = useParams();
  const updateSelectedVariantId = (variant?: SelectedVariantModel) => {
    setSelectedVariantId(variant);
  };
  const { pinProductToLayer, updatingPin } = LayerService();
  const handlePinProductToLayer = async (coordinate: Coordinate) => {
    //Updating Pin check is there to prevent multiple api calls on multiple button presses
    if (
      prototypeId &&
      planId &&
      spaceId &&
      selectedLayerId &&
      selectedVariant &&
      !updatingPin
    ) {
      const updatedProduct = await pinProductToLayer(
        prototypeId,
        planId,
        spaceId,
        selectedLayerId,
        selectedVariant?.id,
        coordinate,
      );
      if (layer && layer?.products && updatedProduct) {
        const updatedProducts = [...layer.products];
        const selectedProductIdx = layer?.products?.findIndex(
          (product) => product?.id === updatedProduct?.id,
        );
        updatedProducts?.splice(selectedProductIdx, 1, updatedProduct);
        const updatedPlan = { ...layer, products: updatedProducts };
        updateLayerDetails?.(updatedPlan);
      }
    }
  };
  const handleUnpinProductFromLayer = async (
    selectedLayerVariantId: string,
  ) => {
    if (
      prototypeId &&
      planId &&
      spaceId &&
      selectedLayerId &&
      selectedLayerVariantId &&
      !updatingPin
    ) {
      const updatedProduct = await pinProductToLayer(
        prototypeId,
        planId,
        spaceId,
        selectedLayerId,
        selectedLayerVariantId,
        { x: 1, y: 1 },
        "remove",
      );
      if (layer && layer?.products && updatedProduct) {
        const updatedProducts = [...layer.products];
        const selectedProductIdx = layer?.products?.findIndex(
          (product) => product?.id === updatedProduct?.id,
        );
        updatedProducts?.splice(selectedProductIdx, 1, updatedProduct);
        const updatedPlan = { ...layer, products: updatedProducts };
        updateLayerDetails?.(updatedPlan);
      }
    }
  };
  return (
    <SelectedVariantContext.Provider
      value={{
        selectedVariant,
        updateSelectedVariantId,
        handlePinProductToLayer,
        handleUnpinProductFromLayer,
      }}
    >
      {children}
    </SelectedVariantContext.Provider>
  );
};

//use context
export const useSelectedVariantId = () => {
  const context = useContext<SelectedVariantType>(SelectedVariantContext);
  if (!context) {
    throw new Error(
      "selectedVariant context must be used within selectedVariant context provider",
    );
  }
  return { ...context };
};
export default SelectedVariantState;

// WHY we have two different contexts SELECTED VARIANT AND OPENED Layer variants
// Selected variant has the id which is the layer variant selected inorder to pin it or unpin it to the layer diagram
// whereas Opened layer variant id is the layer variant selected when we click on viewing that variant variant
