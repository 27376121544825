import { useSelectedLayerId } from "context/SelectedLayerContext";
import { useSelectedPlan } from "context/SelectedPlanContext";
import { useSelectedZone } from "context/SelectedZoneContext";
import { Coordinate } from "models/Coordinate/coordinate.model";
import { LayerVariant } from "models/Layer/layer.model";
import { Space } from "models/Space/space.model";
import { Zone } from "models/Zone/zone.model";
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation, useParams } from "react-router-dom";

export interface PinMarkerType {
  coordinate: Coordinate;
  spaceId: string;
  spaceName: string;
}

interface PinMarkersContextType {
  pinMarkers: PinMarkerType[];
  addPinMarker?: (pinMarker: PinMarkerType) => void;
  removePinMarker?: (markerIdx: number) => void;
  removePinMarkerForSpaceId?: (spaceOrProductId: string) => void;
}
const initialValues = {
  pinMarkers: [],
};

//create context
const PinMarkersContext = createContext<PinMarkersContextType>(initialValues);

//add context  provider
const PinMarkersState: FC = ({ children }) => {
  const [pinMarkers, setPinMarkers] = useState<PinMarkerType[]>([]);
  const { selectedZoneId } = useSelectedZone();
  const { plan } = useSelectedPlan();
  const { layer } = useSelectedLayerId();

  // const zone = plan?.zones.find((zone) => zone.id === selectedZoneId);

  const addPinMarker = (pinMarker: PinMarkerType) => {
    setPinMarkers([...pinMarkers, pinMarker]);
  };

  const removePinMarker = (markerIdx: number) => {
    setPinMarkers(pinMarkers?.filter((marker, idx) => idx !== markerIdx));
  };
  // const { prototypeId, spaceId } = useParams();
  const location = useLocation();

  //Please note that here space Id corresponds to
  // the actual space id for Prototype and Plan Combined View Page
  // and respective product Id for Space and Layer Combined View Page

  const removePinMarkerForSpaceId = (spaceOrProductId?: string) => {
    setPinMarkers(
      pinMarkers?.filter((marker) => marker?.spaceId !== spaceOrProductId),
    );
  };

  const updatePinMarkers = (
    superSubViewList: (Space | LayerVariant | Zone)[],
  ) => {
    if (!superSubViewList?.length) {
      setPinMarkers([]);
      return;
    }

    const pinnedSuperSubViewToASubView = superSubViewList.filter(
      (superSubView: Space | LayerVariant | Zone) => !!superSubView?.isPinned,
    );

    const pinMarkers = pinnedSuperSubViewToASubView.map(
      (superSubView: Space | LayerVariant | Zone) => {
        return {
          spaceId: superSubView?.id ?? "",
          spaceName: superSubView?.title ?? "",
          coordinate: superSubView?.coordinate ?? new Coordinate(),
        };
      },
    );

    pinnedSuperSubViewToASubView?.length >= 0
      ? setPinMarkers(pinMarkers)
      : setPinMarkers([]);
  };

  /**
   * Update to handle zone pin, space pin, layer pin
   */
  // useEffect(() => {
  //   if (zone && zone.spaces) {
  //     updatePinMarkers(zone.spaces);
  //   }
  // }, [zone]);

  // useEffect(() => {
  //   layer && layer?.products && updatePinMarkers(layer?.products);
  // }, [layer]);

  useEffect(() => {
    if (selectedZoneId) {
      const zone = plan?.zones.find((zone) => zone.id === selectedZoneId);
      if (zone && zone.spaces) {
        updatePinMarkers(zone.spaces);
      }

      return;
    }

    const pathName = location.pathname;

    if (pathName.includes("spaces") && layer?.products) {
      updatePinMarkers(layer?.products);
      return;
    }

    const planZones = plan?.zones;
    if (planZones) {
      updatePinMarkers?.(planZones);
      return;
    }
  }, [layer, selectedZoneId, plan, location]);

  return (
    <PinMarkersContext.Provider
      value={{
        pinMarkers,
        addPinMarker,
        removePinMarker,
        removePinMarkerForSpaceId,
      }}
    >
      {children}
    </PinMarkersContext.Provider>
  );
};

//using the created context
export const usePinMarkers = () => {
  const context = useContext<PinMarkersContextType>(PinMarkersContext);
  if (!context) {
    throw new Error(
      "Pin markers context must be used within pin markers context",
    );
  }
  return {
    ...context,
  };
};

export default PinMarkersState;
