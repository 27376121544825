import { serializable } from "serializr";
import { DistanceUnit } from "enums/distanceUnit.enum";
import { MassUnit } from "enums/massUnit.enum";
import { parseNumber } from "shared/utils/parser";

class MetricUnit<T extends "distance" | "mass" = "distance"> {
  constructor(unit?: T extends "distance" ? DistanceUnit : MassUnit) {
    this.unit = unit;
  }

  @serializable
  unit?: T extends "distance" ? DistanceUnit : MassUnit;
}
export class Metric<
  T extends "distance" | "mass" = "distance",
> extends MetricUnit<T> {
  @serializable
  id?: string;

  @serializable
  value?: number;

  static getDistanceEquivalent(
    fromUnit: DistanceUnit,
    toUnit = DistanceUnit.CM,
  ): number {
    const map = new Map<DistanceUnit, Record<string, number>>([
      [
        DistanceUnit.CM,
        {
          [DistanceUnit.CM]: 1,
          [DistanceUnit.MM]: 10,
          [DistanceUnit.IN]: 0.39,
        },
      ],
      [
        DistanceUnit.MM,
        {
          [DistanceUnit.MM]: 1,
          [DistanceUnit.CM]: 0.1,
          [DistanceUnit.IN]: 0.04,
        },
      ],
      [
        DistanceUnit.IN,
        {
          [DistanceUnit.IN]: 1,
          [DistanceUnit.CM]: 2.54,
          [DistanceUnit.MM]: 25.4,
        },
      ],
    ]);

    return parseNumber(map.get(fromUnit)?.[toUnit]);
  }

  static getMassEquivalent(
    fromUnit = MassUnit.POUND,
    toUnit = MassUnit.KG,
  ): number {
    const map = new Map<MassUnit, Record<string, number>>([
      [
        MassUnit.KG,
        {
          [MassUnit.KG]: 1,
          [MassUnit.POUND]: 2.2,
        },
      ],
      [
        MassUnit.POUND,
        {
          [MassUnit.POUND]: 1,
          [MassUnit.KG]: 0.45,
        },
      ],
    ]);

    return parseNumber(map.get(fromUnit)?.[toUnit]);
  }
}

export class MetricQueryParams<T extends "distance" | "mass" = "distance"> {
  constructor(unit: T extends "distance" ? DistanceUnit : MassUnit) {
    this.unit = unit;
  }

  @serializable
  unit: T extends "distance" ? DistanceUnit : MassUnit;

  @serializable
  from = 0;

  @serializable
  to?: number;
}
