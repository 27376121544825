import React from "react";
import { PaginationProps as AntPaginationProps } from "antd";

const summarizePagination: AntPaginationProps["showTotal"] = (
  total,
  [start, end],
) => (
  <span className="text-book pagination__details">
    Showing {start} - {end || 0} of {total}
  </span>
);

export default summarizePagination;
