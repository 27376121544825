import React from "react";
import { Select, SelectProps } from "antd";
import { Field, ErrorMessage, useField } from "formik";
import Error from "../Error";
import { BaseOptionType, DefaultOptionType } from "rc-select/lib/Select";
import Label from "../Label";
import { ReactComponent as DownArrow } from "assets/icons/solid/down-arrow.svg";
import useToggle from "shared/hooks/useToggle";

import "./dropdownField.scss";

export interface DropdownProps<
  ValueType,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
> extends SelectProps<ValueType, OptionType> {
  label?: string;
  error?: string;
  touched?: boolean;
}

export interface DropdownWithFormikProps<
  ValueType,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
> extends DropdownProps<ValueType, OptionType> {
  name: string;
  hideError?: boolean;
}

const Dropdown = <
  ValueType,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
>({
  label,
  error,
  touched,
  ...props
}: DropdownProps<ValueType, OptionType>) => {
  const { isToggled: open, setToggle } = useToggle();

  return (
    <div className="dropdown-field">
      <Label label={label} hasError={touched && !!error} />
      <Select
        showSearch
        status={touched && error ? "error" : undefined}
        suffixIcon={
          <DownArrow className={`icon ${open ? "icon--opened" : ""}`} />
        }
        open={open}
        onDropdownVisibleChange={setToggle}
        filterOption={(search, option) =>
          option?.label?.toLowerCase()?.includes(search?.toLowerCase())
        }
        placeholder="Search and Select"
        {...props}
      />
    </div>
  );
};

const DropdownWithFormik = <
  ValueType,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
>(
  props: DropdownWithFormikProps<ValueType, OptionType>,
) => {
  const [{ value }, { error, touched }, { setValue }] = useField(props.name);

  return (
    <>
      <Field
        component={Dropdown}
        onChange={setValue}
        value={value}
        error={error}
        touched={touched}
        {...props}
      />
      {props.hideError ? (
        <></>
      ) : (
        <ErrorMessage name={props.name}>
          {(message) => <Error message={message} />}
        </ErrorMessage>
      )}
    </>
  );
};

Dropdown.Formik = DropdownWithFormik;

export default Dropdown;
