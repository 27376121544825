import React, { FC, useState } from "react";
import "./searchAndFilter.scss";
import { ReactComponent as SearchIcon } from "assets/icons/solid/search.svg";
import Button from "shared/components/Button";
import { ButtonType } from "enums/buttonType.enum";
import { ProductParams } from "models/Product/product.model";
import ProductsHeaderExtra from "../ProductsHeaderExtra";
import useRedirect from "shared/hooks/useRedirect";
import Input from "shared/components/Input";
import Dropdown from "shared/components/DropdownField";
import { Category } from "models/Category/category.model";
import { SubCategory } from "models/SubCategory/subCategory.model";
import { Type } from "models/Type/type.model";
import { CloseOutlined } from "@ant-design/icons";
import UsersHeaderExtra from "views/UserManagement/UsersHeaderExtra";
import { UserParams } from "models/user.model";
import { Module } from "enums/module.enum";
import { userHasAccess } from "utils";
import { Method } from "enums/method.enum";

interface SearchAndFilterParamTypes {
  params?: ProductParams;
  userParams?: UserParams;
  setUserParams?: React.Dispatch<React.SetStateAction<UserParams>>;

  setParams?: React.Dispatch<React.SetStateAction<ProductParams>>;
  onSearch: (search: string, searchCategory: string) => void;
  searchPlaceholder?: string;
  search?: string;
  hideDropdown?: boolean;
  filters?: {
    categories: Category[];
    subCategories: SubCategory[];
    types: Type[];
  };
  isFixed?: boolean;
  filterUserParams?: UserParams;

  filterParams?: ProductParams;
  fetchProducts?: (params: ProductParams) => void;
  fetchUsers?: (params: UserParams) => void;

  mapProductsToLayer?: boolean;
  handleMapProductToLayer?: () => void;
  mappingLoading?: boolean;
  isUser?: boolean;
  redirectOnCLose?: boolean;
}

const SearchAndFilter: FC<SearchAndFilterParamTypes> = ({
  params,
  setParams,
  hideDropdown,
  onSearch,
  setUserParams,
  filters,
  isFixed,
  filterParams,
  fetchProducts,
  searchPlaceholder,
  mapProductsToLayer,
  userParams,
  filterUserParams,
  fetchUsers,
  handleMapProductToLayer,
  mappingLoading,
  isUser,
  redirectOnCLose = true,
}) => {
  const { redirectToAddProduct, redirectToAddUserForm } = useRedirect();
  const options = [
    { label: "All", value: "all" },
    { label: "Product", value: "product" },
    { label: "Category", value: "category" },
    { label: "Sub Category", value: "subCategory" },
    { label: "Type", value: "type" },
    { label: "Vendor", value: "vendor" },
    { label: "Color", value: "color" },
    { label: "Material", value: "material" },
  ];
  const sortOptions = [
    { label: "Product Name", value: "product" },
    { label: "Vendor Name", value: "vendor" },
    { label: "Lead Time: low to high", value: "leadTime-asc" },
    { label: "Lead Time: high to low", value: "leadTime-desc" },
    { label: "Contract Sell Price: low to high", value: "cost-asc" },
    { label: "Contract Sell Price: high to low", value: "cost-desc" },
  ];
  const [searchParam, setSearchParam] = useState<string>("all");
  const onChangeHandle = (value: string) => {
    setSearchParam(value);
  };
  const [searchIn, setSearchIn] = useState<string>("");
  const onInputHandle = (val: string) => {
    setSearchIn(val);
  };
  const handleSort = (val: string) => {
    if (filterParams) {
      const sortOptions = [
        "leadTime-asc",
        "leadTime-desc",
        "cost-asc",
        "cost-desc",
      ];

      const sortAddedParams = { ...filterParams };

      if (sortOptions.includes(val)) {
        const [sortBy, sortDirection] = val.split("-");
        sortAddedParams.sortBy = sortBy;
        sortAddedParams.sortDirection =
          sortDirection === "desc" ? "desc" : "asc";
      } else {
        sortAddedParams.sortBy = val;
      }

      fetchProducts?.(sortAddedParams);
    }
  };

  const onEnterHandle = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      onSearch(searchIn, searchParam);
    }
  };
  const categoryId =
    filterParams?.categories?.length === 1
      ? filterParams?.categories?.[0]
      : undefined;
  const subCategoryId =
    categoryId && filterParams?.subCategories?.length === 1
      ? filterParams?.subCategories?.[0]
      : undefined;
  const typeId =
    subCategoryId && filterParams?.types?.length === 1
      ? filterParams?.types?.[0]
      : undefined;

  return (
    <div
      className={`search-filter-container ${
        isFixed ? "pd-lr" : "width-map-product"
      } ${mapProductsToLayer && "width-100vw"}`}
    >
      <div className="search-filter-container__content">
        <div className="search-bar">
          <Input.Search
            className="search-input"
            bordered={false}
            onSearch={onInputHandle}
            placeholder={`Search by ${
              searchPlaceholder ??
              (searchParam === "subCategory" ? "sub category" : searchParam)
            }`}
            onKeyDown={onEnterHandle}
            allowClear={{
              clearIcon: (
                <CloseOutlined
                  className="close-icon"
                  onClick={() => onSearch("", "")}
                />
              ),
            }}
          />
          {!hideDropdown && (
            <div className="display-flex">
              <div className="border-left"></div>
              <Dropdown
                options={options}
                bordered={false}
                showSearch={false}
                defaultValue="all"
                onChange={onChangeHandle}
                popupMatchSelectWidth={false}
              />
              <div className="search-bar__icon">
                <SearchIcon
                  className="cursor-pointer"
                  onClick={() => onSearch(searchIn, searchParam)}
                />
              </div>
            </div>
          )}
        </div>
        <div className="filter-button">
          {isUser ? (
            <UsersHeaderExtra
              filters={userParams}
              onFilterUpdate={setUserParams}
              redirectOnCLose={redirectOnCLose}
            />
          ) : (
            <ProductsHeaderExtra
              filterMeta={filters}
              filters={params}
              onFilterUpdate={setParams}
              redirectOnCLose={redirectOnCLose}
            />
          )}
        </div>

        {filterParams &&
        !(
          Object.keys(filterParams).length === 0 &&
          filterParams.constructor === Object
        ) ? (
          <div className="sort-container">
            <div className="display-flex align-items-center">
              <div className="sort-container__heading">Sort by :</div>
              <div className="min-width-7">
                <Dropdown
                  options={sortOptions}
                  bordered={false}
                  showSearch={false}
                  onChange={handleSort}
                  defaultValue="product"
                  popupMatchSelectWidth={false}
                />
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div>
        {(isUser
          ? userHasAccess(Module.USER_MANAGEMENT, Method.CREATE)
          : userHasAccess(Module.PRODUCT, Method.CREATE)) && (
          <Button
            type={ButtonType.PRIMARY}
            loading={mapProductsToLayer && mappingLoading}
            className="wide-button"
            onClick={
              mapProductsToLayer
                ? handleMapProductToLayer
                : () => {
                    isUser
                      ? redirectToAddUserForm()
                      : redirectToAddProduct(categoryId, subCategoryId, typeId);
                  }
            }
          >
            {mapProductsToLayer ? "Map" : isUser ? "Add User" : "Add Product"}
          </Button>
        )}
      </div>
    </div>
  );
};

export default SearchAndFilter;
