import { useState } from "react";
import { deserialize, serialize } from "serializr";
import { generatePath } from "react-router-dom";
import axiosInstance from "../../interceptor/axiosInstance";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { Space } from "../../models/Space/space.model";
import Notification from "shared/components/Notification";
import { NotificationTypes } from "enums/notificationTypes";
import { Coordinate } from "models/Coordinate/coordinate.model";

const SpaceService = () => {
  const [spaceLoading, setSpaceLoading] = useState(false);

  const [loading, setLoading] = useState(false);

  const [space, setSpace] = useState(new Space());

  const [updatingPin, setUpdatingPin] = useState(false);
  const [imgUploadLoading, setImgUploadLoading] = useState<boolean>(false);

  const createSpace = async (
    prototypeId: string,
    planId: string,
    zoneId: string,
    space: Space,
  ) => {
    try {
      setSpaceLoading(true);

      const ENDPOINT = generatePath(ApiRoutes.SPACES, {
        id: prototypeId,
        planId,
        zoneId,
      });

      const { data } = await axiosInstance.post(ENDPOINT, {
        space: serialize(Space, space),
      });

      const newSpace = deserialize(Space, data["space"]);

      setSpace(newSpace);

      Notification({
        message: `Created space ${newSpace?.title}`,
        type: NotificationTypes.SUCCESS,
      });

      return newSpace;
    } catch (ex) {
      Notification({
        message: (ex as Error)?.message || "Unable to create Space",
        type: NotificationTypes.ERROR,
      });
    } finally {
      setSpaceLoading(false);
    }
  };

  const updateSpace = async (
    prototypeId: string,
    planId: string,
    zoneId: string,
    space: Space,
  ) => {
    try {
      setSpaceLoading(true);

      const ENDPOINT = generatePath(ApiRoutes.SPACE, {
        id: prototypeId,
        planId,
        zoneId,
        spaceId: String(space.id),
      });
      const { data } = await axiosInstance.patch(ENDPOINT, {
        space: serialize(Space, space),
        action: "title",
      });

      const updatedSpace = deserialize(Space, data["space"]);

      setSpace(updatedSpace);
      Notification({
        message: `Updated space ${updatedSpace?.title}`,
        type: NotificationTypes.SUCCESS,
      });

      return updatedSpace;
    } catch (ex) {
      Notification({
        message: (ex as Error)?.message || "Unable to update Space",
        type: NotificationTypes.ERROR,
      });
    } finally {
      setSpaceLoading(false);
    }
  };
  const removeSpacePin = async (
    prototypeId: string,
    planId: string,
    zoneId: string,
    spaceId: string,
    coordinate: Coordinate,
    pin: "add" | "remove",
  ) => {
    try {
      setUpdatingPin(true);

      const ENDPOINT = generatePath(ApiRoutes.SPACE_PIN, {
        id: prototypeId,
        planId,
        zoneId,
        spaceId,
      });

      const { data } = await axiosInstance.put(ENDPOINT, {
        coordinate: serialize(Coordinate, coordinate),
        pin,
      });

      Notification({
        message: "Unpinned space to plan",
        type: NotificationTypes.SUCCESS,
      });
      return deserialize(Space, data["space"]);
    } catch (ex) {
      Notification({
        message: (ex as Error)?.message || "Unable to remove Pin",
        type: NotificationTypes.ERROR,
      });
      return;
    } finally {
      setUpdatingPin(false);
    }
  };
  const updateSpacePin = async (
    prototypeId: string,
    planId: string,
    zoneId: string,
    space: Space,
    coordinate: Coordinate,
    pin: "add" | "remove",
  ) => {
    try {
      setUpdatingPin(true);

      const ENDPOINT = generatePath(ApiRoutes.SPACE_PIN, {
        id: prototypeId,
        planId,
        zoneId,
        spaceId: String(space.id),
      });

      const { data } = await axiosInstance.put(ENDPOINT, {
        coordinate: serialize(Coordinate, coordinate),
        pin,
      });

      const updatedCoordinate = deserialize(Space, data["coordinate"]);
      setSpace(
        Object.assign(space, {
          coordinate: updatedCoordinate,
        }),
      );
      Notification({
        message: "Pinned space to zone",
        type: NotificationTypes.SUCCESS,
      });
      return deserialize(Space, data["space"]);
    } catch (ex) {
      Notification({
        message: (ex as Error)?.message || "Unable to add Pin",
        type: NotificationTypes.ERROR,
      });
    } finally {
      setUpdatingPin(false);
    }
  };

  const deleteSpace = async (
    prototypeId: string,
    planId: string,
    zoneId: string,
    spaceId: string,
  ) => {
    try {
      setSpaceLoading(true);

      const ENDPOINT = generatePath(ApiRoutes.SPACE, {
        id: prototypeId,
        planId,
        zoneId,
        spaceId,
      });

      await axiosInstance.delete(ENDPOINT);
      Notification({
        message: `Space deleted`,
        type: NotificationTypes.SUCCESS,
      });
      return true;
    } catch (ex) {
      Notification({
        message: (ex as Error)?.message || "Unable to delete Space",
        type: NotificationTypes.ERROR,
      });
      return false;
    } finally {
      setSpaceLoading(false);
    }
  };

  const showSpace = async (
    prototypeId: string,
    planId: string,
    zoneId: string,
    spaceId: string,
  ) => {
    try {
      setLoading(true);

      const ENDPOINT = generatePath(ApiRoutes.SPACE, {
        id: prototypeId,
        planId,
        zoneId,
        spaceId,
      });

      const { data } = await axiosInstance.get(ENDPOINT);

      const space = deserialize(Space, data["space"]);

      setSpace(space);

      return space;
    } catch (ex) {
      Notification({
        message: (ex as Error)?.message || "Unable to get Space",
        type: NotificationTypes.ERROR,
      });
    } finally {
      setLoading(false);
    }
  };

  const spaceGalleryImageUpload = (
    prototypeId: string,
    planId: string,
    zoneId: string,
    spaceId: string,
    imageId: string,
  ) => {
    try {
      setImgUploadLoading(true);
      const payload = {
        space: {
          gallery: [{ id: imageId }],
        },
        action: "add",
      };
      const res = axiosInstance.patch(
        generatePath(ApiRoutes.SPACE, {
          id: prototypeId,
          planId,
          spaceId,
          zoneId,
        }),
        payload,
      );
      Notification({
        message: `Added image to gallery`,
        type: NotificationTypes.SUCCESS,
      });
    } catch (ex) {
      Notification({
        message:
          (ex as Error)?.message || "Unable to upload image to the space",
        type: NotificationTypes.ERROR,
      });
    } finally {
      setImgUploadLoading(false);
    }
  };
  const spaceGalleryImageDelete = (
    prototypeId: string,
    planId: string,
    zoneId: string,
    spaceId: string,
    imageId: string,
  ) => {
    try {
      setImgUploadLoading(true);
      const payload = {
        space: {
          gallery: [{ id: imageId }],
        },
        action: "remove",
      };
      const res = axiosInstance.patch(
        generatePath(ApiRoutes.SPACE, {
          id: prototypeId,
          planId,
          spaceId,
          zoneId,
        }),
        payload,
      );
      Notification({
        message: `Deleted image from gallery`,
        type: NotificationTypes.SUCCESS,
      });
    } catch (ex) {
      Notification({
        message:
          (ex as Error)?.message || "Unable to upload image to the space",
        type: NotificationTypes.ERROR,
      });
    } finally {
      setImgUploadLoading(false);
    }
  };
  return {
    createSpace,
    deleteSpace,
    showSpace,
    space,
    spaceLoading,
    updateSpace,
    updateSpacePin,
    removeSpacePin,
    updatingPin,
    loading,
    spaceGalleryImageUpload,
    spaceGalleryImageDelete,
    imgUploadLoading,
    setSpace,
  };
};

export default SpaceService;
