import React, { FC } from "react";
import "./notificationPopup.scss";
// import NotificationService from "services/NotificationService/notification.service";
// import { Empty, Popover } from "antd";
// import moment from "moment";
// import { PrototypeActivity } from "models/PrototypeActivity/prototypeActivity.model";
// import {
//   activityIconSelector,
//   activityLogMessageGenerator,
//   getLeafNode,
// } from "utils";
// import Spinner from "shared/components/Spinner";
import { NotificationEnabled } from "shared/icons";
import { useQuery } from "shared/hooks/useNetwork";
import { NavigationRoutes } from "routes/routeConstants/appRoutes";
import { useMatch } from "react-router-dom";
import { ApiRoutes } from "routes/routeConstants/apiRoutes";

function getNotificationUrl(
  protoTypeId?: string | null,
  queryProtoId?: string | null,
): string {
  if (protoTypeId) {
    return `${NavigationRoutes.NOTIFICATION}?prototypeId=${protoTypeId}`;
  }

  if (queryProtoId) {
    return `${NavigationRoutes.NOTIFICATION}?prototypeId=${queryProtoId}`;
  }

  return NavigationRoutes.NOTIFICATION;
}

const NotificationPopup: FC = () => {
  const match = useMatch(NavigationRoutes.NOTIFICATION);
  const prototypeMatch = useMatch(ApiRoutes.PROTOTYPE);
  const query = useQuery();
  const queryProtoId = query.get("prototypeId");

  const prototypeId = prototypeMatch?.params?.id;
  const notificationLink = getNotificationUrl(prototypeId, queryProtoId);

  // const {
  //   notifications,
  //   notificationsLoading,
  //   fetchNotifications,
  //   clearAllNotifications,
  // } = NotificationService();
  // const handleClearAll = async () => {
  //   clearAllNotifications();
  // };

  const enabled = match && match.pathname;

  return (
    <a
      href={notificationLink}
      className={`notification-icon ${enabled ? "notification-icon__active" : ""}`}
    ></a>
  );
};
// interface NotifyCardProps {
//   notification: PrototypeActivity;
// }
// const NotifyCard: FC<NotifyCardProps> = ({ notification }) => {
//   const { action, createdAt, user, hierarchy, fieldUpdated, module } =
//     notification;
//   return (
//     <div className="notification-card">
//       <div
//         className={`notification-card__icon ${
//           (action === "remove" || action === "delete") && "grey"
//         }`}
//       >
//         {action && activityIconSelector(action, fieldUpdated)}
//       </div>
//       <div className="notification-card__details">
//         <div className="notification-card__details__text">
//           {action &&
//             hierarchy &&
//             user?.name +
//               activityLogMessageGenerator(
//                 action,
//                 getLeafNode(hierarchy),
//                 fieldUpdated,
//                 module,
//                 hierarchy
//               )}
//         </div>
//         <div className="notification-card__details__date">
//           {moment(createdAt).format("DD MMM YYYY, hh:mm A")}
//         </div>
//       </div>
//     </div>
//   );
// };
export default NotificationPopup;
