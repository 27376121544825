import React from "react";
import "./noAccess.scss";
import unAuthorized from "../../assets/images/unauthorized.svg";

const NoAccess = () => {
  return (
    <div className="no-access">
      <div>
        <div className="no-access__img">
          <img src={unAuthorized} alt="unauthorized" height={326} width={265} />
        </div>
        <div className="no-access__title">
          You don’t have access to this page
        </div>
        <div className="no-access__descr">
          Contact the administrator for more information
        </div>
      </div>
    </div>
  );
};

export default NoAccess;
