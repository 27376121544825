import { useState } from "react";
import { deserialize, serialize } from "serializr";
import { generatePath } from "react-router-dom";
import axiosInstance from "../../interceptor/axiosInstance";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { Zone } from "models/Zone/zone.model";
import Notification from "shared/components/Notification";
import { NotificationTypes } from "enums/notificationTypes";
import { Coordinate } from "models/Coordinate/coordinate.model";

const ZoneService = () => {
  const [zoneLoading, setZoneLoading] = useState(false);

  const [loading, setLoading] = useState(false);

  const [zone, setZone] = useState(new Zone());

  const [updatingPin, setUpdatingPin] = useState(false);
  const [imgUploadLoading, setImgUploadLoading] = useState<boolean>(false);

  const updateZoneDetails = (newZone: Zone) => {
    setZone(newZone);
  };

  const createZone = async (
    prototypeId: string,
    planId: string,
    zone: Zone,
  ) => {
    try {
      setZoneLoading(true);

      const ENDPOINT = generatePath(ApiRoutes.ZONES, {
        id: prototypeId,
        planId,
      });

      const { data } = await axiosInstance.post(ENDPOINT, {
        zone: serialize(Zone, zone),
      });

      const newZone = deserialize(Zone, data["zone"]);

      setZone(newZone);

      Notification({
        message: `Created zone ${newZone?.title}`,
        type: NotificationTypes.SUCCESS,
      });

      return newZone;
    } catch (ex) {
      Notification({
        message: (ex as Error)?.message || "Unable to create Zone",
        type: NotificationTypes.ERROR,
      });
    } finally {
      setZoneLoading(false);
    }
  };

  const updateZone = async (
    prototypeId: string,
    planId: string,
    zone: Zone,
  ) => {
    try {
      setZoneLoading(true);

      const ENDPOINT = generatePath(ApiRoutes.ZONE, {
        id: prototypeId,
        planId,
        zoneId: String(zone.id),
      });
      const { data } = await axiosInstance.patch(ENDPOINT, {
        zone: serialize(Zone, zone),
        action: "title",
      });

      const updatedZone = deserialize(Zone, data["zone"]);

      setZone(updatedZone);
      Notification({
        message: `Updated zone ${updatedZone?.title}`,
        type: NotificationTypes.SUCCESS,
      });

      return updatedZone;
    } catch (ex) {
      Notification({
        message: (ex as Error)?.message || "Unable to update Zone",
        type: NotificationTypes.ERROR,
      });
    } finally {
      setZoneLoading(false);
    }
  };
  const removeZonePin = async (
    prototypeId: string,
    planId: string,
    zoneId: string,
    coordinate: Coordinate,
    pin: "add" | "remove",
  ) => {
    try {
      setUpdatingPin(true);

      const ENDPOINT = generatePath(ApiRoutes.ZONE_PIN, {
        id: prototypeId,
        planId,
        zoneId,
      });

      const { data } = await axiosInstance.put(ENDPOINT, {
        coordinate: serialize(Coordinate, coordinate),
        pin,
      });

      Notification({
        message: "Unpinned zone from plan",
        type: NotificationTypes.SUCCESS,
      });
      return deserialize(Zone, data["zone"]);
    } catch (ex) {
      Notification({
        message: (ex as Error)?.message || "Unable to remove Pin",
        type: NotificationTypes.ERROR,
      });
      return;
    } finally {
      setUpdatingPin(false);
    }
  };

  const updateZonePin = async (
    prototypeId: string,
    planId: string,
    zoneId: string,
    coordinate: Coordinate,
    pin: "add" | "remove",
  ) => {
    try {
      setUpdatingPin(true);

      const ENDPOINT = generatePath(ApiRoutes.ZONE_PIN, {
        id: prototypeId,
        planId,
        zoneId,
      });

      const { data } = await axiosInstance.put(ENDPOINT, {
        coordinate: serialize(Coordinate, coordinate),
        pin,
      });

      const updatedCoordinate = deserialize(Zone, data["coordinate"]);

      Notification({
        message: "Pinned zone to plan",
        type: NotificationTypes.SUCCESS,
      });

      return deserialize(Zone, data["zone"]);
    } catch (ex) {
      Notification({
        message: (ex as Error)?.message || "Unable to update Pin",
        type: NotificationTypes.ERROR,
      });
      return;
    } finally {
      setUpdatingPin(false);
    }
  };

  const deleteZone = async (
    prototypeId: string,
    planId: string,
    zoneId: string,
  ) => {
    try {
      setZoneLoading(true);

      const ENDPOINT = generatePath(ApiRoutes.ZONE, {
        id: prototypeId,
        planId,
        zoneId,
      });

      await axiosInstance.delete(ENDPOINT);
      Notification({
        message: `Zone deleted`,
        type: NotificationTypes.SUCCESS,
      });
      return true;
    } catch (ex) {
      Notification({
        message: (ex as Error)?.message || "Unable to delete Zone",
        type: NotificationTypes.ERROR,
      });
      return false;
    } finally {
      setZoneLoading(false);
    }
  };

  const showZone = async (
    prototypeId: string,
    planId: string,
    zoneId: string,
  ) => {
    try {
      setLoading(true);

      const ENDPOINT = generatePath(ApiRoutes.ZONE, {
        id: prototypeId,
        planId,
        zoneId,
      });

      const { data } = await axiosInstance.get(ENDPOINT);

      const zone = deserialize(Zone, data["zone"]);

      setZone(zone);

      return zone;
    } catch (ex) {
      Notification({
        message: (ex as Error)?.message || "Unable to get Zone",
        type: NotificationTypes.ERROR,
      });
    } finally {
      setLoading(false);
    }
  };

  return {
    createZone,
    deleteZone,
    showZone,
    zone,
    zoneLoading,
    updateZone,
    removeZonePin,
    updatingPin,
    loading,
    imgUploadLoading,
    setZone,
    updateZoneDetails,
    updateZonePin,
  };
};

export default ZoneService;
