import { ColumnsType } from "antd/es/table";
import { Product } from "models/Product/product.model";
import React from "react";

const GetColumns: ColumnsType<Product> = [
  {
    title: "SKU",
    dataIndex: "code",
    key: "sku",
  },
  {
    title: "PRODUCT NAME",
    key: "name",
    dataIndex: "name",
    sorter: (product1: Product, product2: Product) => {
      return (product1?.name ?? "").localeCompare(product2?.name ?? "");
    },
  },
  {
    title: "VENDOR",
    key: "vendor",
    dataIndex: "vendor",
    render: (_: string, record: Product) => <>{record?.vendor?.name}</>,
    sorter: (product1: Product, product2: Product) => {
      return (product1?.vendor?.name ?? "").localeCompare(
        product2?.vendor?.name ?? "",
      );
    },
  },
  {
    title: "CATEGORY",
    key: "category",
    render: (_: string, record: Product) => (
      <>{record?.type?.subCategory?.category?.name}</>
    ),
    sorter: (product1: Product, product2: Product) => {
      return (product1?.type?.subCategory?.category?.name ?? "").localeCompare(
        product2?.type?.subCategory?.category?.name ?? "",
      );
    },
  },
  {
    title: "SUB CATEGORY",
    key: "subCategory",
    render: (_: string, record: Product) => (
      <>{record?.type?.subCategory?.name}</>
    ),
    sorter: (product1: Product, product2: Product) => {
      return (product1?.type?.subCategory?.name ?? "").localeCompare(
        product2?.type?.subCategory?.name ?? "",
      );
    },
  },
  {
    title: "TYPE",
    key: "type",
    render: (_: string, record: Product) => <>{record?.type?.name}</>,
    sorter: (product1: Product, product2: Product) => {
      return (product1?.type?.name ?? "").localeCompare(
        product2?.type?.name ?? "",
      );
    },
  },
];

export default GetColumns;
