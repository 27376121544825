import React, { FC, createContext, useContext, useState } from "react";

interface OpenedLayerVariantType {
  openedLayerVariantId?: string;
  setOpenedLayerVariantId?: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}

//create a context
const OpenedLayerVariantContext = createContext<OpenedLayerVariantType>({});

// create a context provider
const OpenedLayerVariantState: FC = ({ children }) => {
  const [openedLayerVariantId, setOpenedLayerVariantId] = useState<
    string | undefined
  >();
  return (
    <OpenedLayerVariantContext.Provider
      value={{ openedLayerVariantId, setOpenedLayerVariantId }}
    >
      {children}
    </OpenedLayerVariantContext.Provider>
  );
};

//using the context
export const useOpenedLayerVariant = () => {
  const context = useContext(OpenedLayerVariantContext);
  if (!context) throw new Error("use it within the OpenedLayerContext");
  return { ...context };
};

export default OpenedLayerVariantState;
