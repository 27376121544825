import React from "react";
import {
  Breadcrumb as AntBreadcrumb,
  BreadcrumbProps as AntBreadcrumbProps,
} from "antd";
import { RightArrow } from "shared/icons";

import "./breadcrumb.scss";

interface BreadcrumbProps extends AntBreadcrumbProps {
  size?: "sm" | "lg";
  truncate?: boolean;
}

const Breadcrumb = ({ size = "lg", truncate, ...props }: BreadcrumbProps) => {
  return (
    <div
      className={`breadcrumb breadcrumb--${size} ${truncate && "breadcrumb--truncate"}`}
    >
      <AntBreadcrumb
        separator={<RightArrow className="icon icon--right-arrow" />}
        {...props}
      />
    </div>
  );
};

export default Breadcrumb;
