import React, { useEffect, useState } from "react";
import GroupedInput from "shared/components/GroupedInput";
import Input from "shared/components/Input";
import StatusUpdate from "./StatusUpdate";
import Switch from "shared/components/Switch";
import { Tooltip } from "antd";
import { Info } from "shared/icons";
import { useField, useFormikContext } from "formik";
import { Sustainability } from "models/Sustainability/sustainability.model";
import { User } from "models/user.model";
import { useMeta } from "services/useMetaService/useMeta.service";
import { ApiRoutes } from "routes/routeConstants/apiRoutes";
import AddUser from "shared/components/AddUser";
import "./productFormContentLeftHeader.scss";
import { Product } from "models/Product/product.model";
import ErrorMessage from "shared/components/Error";
import { UserRole } from "enums/userRole";

const ProductFormContentLeftHeader = () => {
  const [, , { setValue }] = useField<Sustainability>("sustainability");
  const user = JSON.parse(localStorage.getItem("user") ?? "{}");

  const [{ value: vendor }, { error: vendorErr }, { setValue: setVendor }] =
    useField<User>("vendor");
  const { touched, errors } = useFormikContext<Product>();
  const { fetchMeta: fetchVendors, meta: vendors } = useMeta({
    key: "users",
    url: ApiRoutes.VENDORS,
    serializingClass: User,
  });

  const [vendorFlag, setVendorFlag] = useState(false);

  useEffect(() => {
    async function run() {
      await fetchVendors();
      if (user?.role === UserRole.VENDOR) {
        setVendorFlag(true);
        setVendor(user);
      }
    }

    run();
  }, [vendorFlag]);

  useEffect(() => {
    if (vendors?.length && user?.role !== UserRole.VENDOR) {
      // Set ACME vendor as default
      const acmeVendor = vendors?.find((v) => v.email === "acme@acme.com");
      if (acmeVendor && !vendor?.id) {
        setVendor(acmeVendor);
      }
    }
  }, [vendors]);

  return (
    <div className="product-form-content-left-header">
      <div className="product-form-content-left-header__vendor">
        {vendor && (
          <AddUser
            onSelect={setVendor}
            searchPlaceholder="Search Vendor"
            selectedUser={vendor}
            tooltipTitle="Select Vendor"
            users={vendors}
          />
        )}

        {touched.vendor && vendorErr ? (
          <ErrorMessage message={"Vendor is required"} />
        ) : (
          <></>
        )}
      </div>
      <div className="product-form-content-left-header__inputs">
        <GroupedInput
          items={[
            {
              label: "SKU",
              value: (
                <Input.Formik
                  className="p-0"
                  name="code"
                  bordered={false}
                  hideError
                />
              ),
            },
          ]}
          size="sm"
        />
        <GroupedInput
          items={[
            {
              label: "Product Status",
              value: <StatusUpdate />,
            },
          ]}
          size="sm"
        />
        <GroupedInput
          items={[
            {
              label: (
                <div className="product-form-content-left-header__inputs__sustainability">
                  <span>Sustainable Product</span>
                  <Tooltip title="Toggle on the button if the product is sustainable">
                    <Info className="icon--info" />
                  </Tooltip>
                </div>
              ),
              value: (
                <Switch.Formik
                  name="sustainability.isSustainable"
                  onChange={(isSustainable) =>
                    setValue({ isSustainable, description: "" })
                  }
                />
              ),
            },
          ]}
          size="sm"
        />
      </div>
      <div className="mg-t-12">
        {touched?.code && errors?.code ? (
          <ErrorMessage message={errors?.code} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ProductFormContentLeftHeader;
