import { useCanAddPin } from "context/CanAddPinContext";
import { DiagramView } from "enums/diagramView";
import React, { useEffect } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { ApiRoutes } from "routes/routeConstants/apiRoutes";
import { NavigationRoutes } from "routes/routeConstants/appRoutes";
import ZoneService from "services/ZoneService/zone.service";
import DualViewContainer from "shared/components/PrototypeCombined";
import {
  addEventListenerEscCancelPinning,
  removeEventListenerEscCancelPinning,
} from "utils";

const ZoneViewCombined = () => {
  const { loading, zone, showZone } = ZoneService();
  const { prototypeId, planId, zoneId } = useParams();
  const navigate = useNavigate();
  const breadCrumbItemList = [
    {
      title: zone?.plan?.prototype?.title?.toUpperCase(),
      href: generatePath(NavigationRoutes.VIEW_PROTOTYPE, { prototypeId }),
    },
    {
      title: zone?.plan?.title?.toUpperCase(),
      onClick: () =>
        navigate(generatePath(ApiRoutes?.PROTOTYPE, { id: prototypeId })),
      href: "#",
    },
    {
      title: zone?.title?.toUpperCase(),
      href: "#",
    },
  ];
  const { handleSetAddPin } = useCanAddPin();

  useEffect(() => {
    addEventListenerEscCancelPinning(handleSetAddPin);
    return () => {
      removeEventListenerEscCancelPinning(handleSetAddPin);
    };
  }, []);

  return (
    <DualViewContainer
      viewType={DiagramView.ZONE}
      loading={loading}
      subViewListingService={() => {
        showZone(prototypeId ?? "", planId ?? "", zoneId ?? "");
      }}
      viewDetails={zone}
      subViewList={zone?.spaces}
      breadCrumbItemList={breadCrumbItemList}
    />
  );
};

export default ZoneViewCombined;
