import { DefaultOptionType } from "antd/es/select";

export const generateDropdownOptions = <
  T extends object,
  K extends keyof T & string,
  V extends T[K] & string,
>(
  labelKey: K,
  valueKey: K,
  list: T[] = [],
): DefaultOptionType[] =>
  list.map((item) => ({
    label: item[labelKey],
    value: item[valueKey] as V,
  }));
