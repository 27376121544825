import React, { useEffect, useState } from "react";
import { Form, Formik, FormikConfig } from "formik";
import "./activityNotification.scss";
import ActivityNotifications from "shared/components/ActivityNotifications";
import { NotificationDisabled, NotificationEnabled } from "shared/icons";
import ProtoTypeNotificationService from "services/PrototypeService/prototype_notification.service";
import { ProtoTypeNotification } from "models/ProtoTypeNotification/prototypeNotification.model";
import ActivityNotificationsForm from "./ActivityNotificationForm";

interface ActivityNotificationPopUpProps {
  protoTypeId: string;
}

const ActivityNotificationPopUp: React.FC<ActivityNotificationPopUpProps> = ({
  protoTypeId,
}) => {
  const [togglePN, setTogglePN] = useState<boolean>(false);

  const {
    fetchProtoTypeNotifications,
    notifications,
    notificationsEdit,
    updatePrototypeNotification,
    setNotificationsEdit,
  } = ProtoTypeNotificationService();

  useEffect(() => {
    fetchProtoTypeNotifications(protoTypeId);
  }, []);

  const handleSubmit: FormikConfig<ProtoTypeNotification>["onSubmit"] = (
    params,
  ) => {
    setTogglePN(false);
    updatePrototypeNotification(params, protoTypeId);
  };
  return (
    <>
      <div
        className="activity-notifications-header-extra"
        onClick={() => {
          setTogglePN(!togglePN);
        }}
      >
        <div className="filter-container">
          <div className="row">
            {notificationsEdit?.activityNotification?.overall ? (
              <NotificationEnabled className="icon" />
            ) : (
              <NotificationDisabled className="icon" />
            )}{" "}
            <div className="filter-container__text">Activity Notifications</div>
          </div>
        </div>
      </div>
      <Formik
        enableReinitialize
        initialValues={notificationsEdit}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit }) => (
          <Form>
            <ActivityNotifications
              open={togglePN}
              onClose={() => {
                setNotificationsEdit({ ...notifications });
                setTogglePN(false);
              }}
              onUpdate={handleSubmit}
            >
              <ActivityNotificationsForm
                notification={notificationsEdit}
                onChange={(noti) => {
                  setNotificationsEdit(noti);
                }}
              />
            </ActivityNotifications>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ActivityNotificationPopUp;
