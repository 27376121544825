import React, { useEffect, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { ZoneParams } from "shared/types/routeParams";

import ZoneService from "services/ZoneService/zone.service";
import { Form, Formik, FormikConfig } from "formik";
import { Zone } from "models/Zone/zone.model";
import useRedirect from "shared/hooks/useRedirect";
import PlanService from "services/PlanService/plan.service";
import zoneValidation from "./zoneValidation";
import Input from "shared/components/Input";
import TextArea from "shared/components/TextArea";
import Button from "shared/components/Button";
import { ButtonType } from "enums/buttonType.enum";

import "./zoneForm.scss";
import { Back, Delete, ViewDescription } from "shared/icons";
import DeleteModal, { DeleteModalProps } from "shared/components/DeleteModal";
import Spinner from "shared/components/Spinner";
import { useSelectedPlan } from "context/SelectedPlanContext";
import Notification from "shared/components/Notification";
import { NotificationTypes } from "enums/notificationTypes";
import { ApiRoutes } from "routes/routeConstants/apiRoutes";
import { usePinMarkers } from "context/PinMarkersContext";
import { useSelectedZone } from "context/SelectedZoneContext";
import { Space, Tooltip } from "antd";
import Label from "shared/components/Label";
import AttachmentPreview from "shared/components/AttachmentPreview";
import UploadInput from "shared/components/UploadInput";
import UploadedImage from "shared/components/UploadedImage";

const ZoneForm = () => {
  const { prototypeId, planId, zoneId } = useParams<ZoneParams>();
  const { handleSetSelectedZoneId, updateZoneDetails } = useSelectedZone();
  const { plan, updatePlanDetails } = useSelectedPlan();
  const {
    createZone,
    updateZone,
    showZone,
    deleteZone,
    zone,
    zoneLoading,
    loading: zoneViewLoading,
  } = ZoneService();

  const [modalProps, setModalProps] = useState<DeleteModalProps>({
    open: false,
    loading: false,
    onCancel: () => {
      return;
    },
    onDelete: () => {
      return;
    },
    subTitle: "",
    title: <></>,
    deleteText: "",
    hideTrash: true,
  });

  const { showPlan, loading } = PlanService();
  const navigate = useNavigate();
  const { goBack } = useRedirect();

  const handleSubmit: FormikConfig<Zone>["onSubmit"] = async (zone) => {
    if (!prototypeId || !planId) return;

    const updatedZone = await (zone?.id ? updateZone : createZone)(
      prototypeId,
      planId,
      zone,
    );

    if (plan && updatedZone) {
      const { zones, ...planWithoutZones } = plan;

      const updatedZoneList = zones.map((zone) => {
        if (zone?.id === updatedZone?.id) {
          return {
            ...updatedZone,
            spaces: zone?.spaces,
          };
        }
        return zone;
      });

      if (zone?.id) {
        updatePlanDetails?.({
          ...planWithoutZones,
          zones: updatedZoneList,
        });
      } else {
        const filtered = updatedZoneList.filter(
          (zone) => zone?.id !== updatedZone?.id,
        );

        if (!zoneId) {
          filtered.push(updatedZone);
        }

        updatePlanDetails?.({ ...planWithoutZones, zones: filtered });
      }
    }

    if (!updatedZone) return;
    goBack();
  };

  const handleDelete = async () => {
    const deleted = await deleteZone(
      prototypeId ?? "",
      planId ?? "",
      zoneId ?? "",
    );

    if (!deleted) return;
    plan &&
      zoneId &&
      updatePlanDetails?.({
        ...plan,
        zones: plan?.zones?.filter((zone) => zone?.id !== zoneId),
      });

    navigate(generatePath(ApiRoutes?.PROTOTYPE, { id: prototypeId }));
  };

  const handleEdit = async (
    submitForm: (() => Promise<void>) & (() => Promise<any>),
  ) => {
    await submitForm();
  };

  useEffect(() => {
    if (!prototypeId || !planId || !zoneId) return;

    if (!zoneId) {
      showPlan(prototypeId, planId);
      return;
    }

    showZone(prototypeId, planId, zoneId);
  }, [prototypeId, planId, zoneId]);

  return (
    <Spinner loading={zoneViewLoading || loading}>
      <div className="space-form">
        <Formik
          initialValues={zone}
          onSubmit={handleSubmit}
          validationSchema={zoneValidation}
        >
          {({ isSubmitting, submitForm, isValid, values, setFieldValue }) => (
            <>
              <Form>
                <div className="space-form__container">
                  <div className="space-form__container__buttons">
                    <div
                      className="space-form__container__buttons__back"
                      onClick={goBack}
                    >
                      <span className="icon-container">
                        <Back className="icon--back" />
                        <span className="text-capitalize">back</span>
                      </span>
                    </div>
                    <div className="space-form__container__buttons__form-action">
                      {zoneId && (
                        <Button
                          type={ButtonType.TEXT}
                          icon={<Delete className="icon-delete" />}
                          className="button--delete"
                          onClick={() => {
                            setModalProps({
                              hideTrash: false,
                              onDelete: () => {
                                handleDelete();
                              },
                              onCancel: () => {
                                setModalProps({ open: false, ...modalProps });
                              },
                              open: true,
                              deleteText: "Delete",
                              subTitle:
                                "This zone will be permanently deleted.",
                              loading: zoneLoading,
                            });
                          }}
                        >
                          Delete Zone
                        </Button>
                      )}
                      <Button
                        type={ButtonType.DEFAULT}
                        onClick={() => {
                          setModalProps({
                            hideTrash: true,
                            onDelete: () => {
                              goBack();
                            },
                            onCancel: () => {
                              setModalProps({ ...modalProps });
                            },
                            open: true,
                            deleteText: "Discard",
                            title: "Are you sure to discard?",
                            subTitle: "The changes made will not be saved.",
                            width: 340,
                          });
                        }}
                      >
                        Discard
                      </Button>
                      <Button
                        htmlType={zoneId ? undefined : "submit"}
                        loading={zoneId ? false : isSubmitting}
                        onClick={() => {
                          zoneId
                            ? setModalProps({
                                hideTrash: true,
                                onDelete: () => {
                                  if (isValid) {
                                    handleEdit(submitForm);
                                  } else {
                                    setModalProps({
                                      ...modalProps,
                                      open: false,
                                    });
                                    Notification({
                                      message: "Invalid data provided",
                                      type: NotificationTypes.ERROR,
                                    });
                                  }
                                },
                                onCancel: () => {
                                  setModalProps({ ...modalProps });
                                },
                                open: true,
                                deleteText: "Update",
                                title: "Are you sure to update?",
                                width: 340,
                              })
                            : null;
                        }}
                        type={ButtonType.PRIMARY}
                      >
                        {zone?.id ? "Update" : "Create"}
                      </Button>
                    </div>
                  </div>
                  <div className="space-form__container__img">
                    <div className="display-flex">
                      <div className="mg-r-8 tw-w-[44px] tw-h-[44px] tw-mr-8">
                        {values?.image ? (
                          <UploadedImage
                            expandable
                            width={44}
                            hideRemoveTextOnOverlay
                            src={values?.image?.url}
                            onDelete={() => {
                              setFieldValue("image", undefined);
                            }}
                          />
                        ) : (
                          <Tooltip title="Supported formats: JPG, JPEG & PNG in 1:1 ratio and < 10 MB">
                            <UploadInput.Formik
                              name={"image"}
                              width={20}
                              height={44}
                            />
                          </Tooltip>
                        )}
                      </div>
                      <Input.Formik
                        name="title"
                        placeholder="Enter Zone Name"
                      />
                    </div>
                  </div>
                  <div className="space-form__description__container">
                    <div className="space-form__description__title">
                      <ViewDescription className="icon-view-description" />
                      <span>Zone Description</span>
                    </div>
                    <TextArea.Formik
                      name="description"
                      rows={10}
                      className="space-form__description__input"
                    />
                  </div>

                  <div className="plan-form__description__diagram">
                    {values?.diagram?.id ? (
                      <Space direction="vertical">
                        <Label label="Zone Diagram" />
                        <AttachmentPreview
                          key={values?.diagram?.id}
                          type="delete"
                          attachment={values?.diagram}
                          onClick={() => setFieldValue("diagram", undefined)}
                        />
                      </Space>
                    ) : (
                      <UploadInput.Formik
                        className="plan-form__container__diagram"
                        primaryTheme
                        label="Zone Diagram"
                        name="diagram"
                        note="Supported formats: JPG, JPEG & PNG and < 50MB"
                        title="Upload zone diagram"
                        options={{
                          restrictions: {
                            maxFileSize: 50 * 1024 * 1024, // 50MB
                          },
                        }}
                      />
                    )}
                  </div>
                </div>
              </Form>

              {/* Confirmation modal */}
              <DeleteModal {...modalProps} />
            </>
          )}
        </Formik>
      </div>
    </Spinner>
  );
};

export default ZoneForm;
