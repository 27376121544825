import React, { FC } from "react";
import "./error.scss";

interface ErrorMessageProps {
  message?: string;
}

const ErrorMessage: FC<ErrorMessageProps> = ({ message }) => {
  return message ? <span className="text-book">{message}</span> : <></>;
};

export default ErrorMessage;
