import { useSelectedPlan } from "context/SelectedPlanContext";
import { Coordinate } from "models/Coordinate/coordinate.model";
import { Zone } from "models/Zone/zone.model";
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import ZoneService from "services/ZoneService/zone.service";

interface SelectedZoneType {
  selectedZoneId?: string;
  zone?: Zone;
  zoneLoading?: boolean;
  handleSetSelectedZoneId?: (zoneId?: string) => void;
  updateZoneDetails?: (zone: Zone) => void;
  handleUpdateZonePin?: (
    coordinate: Coordinate,
    pin: "add" | "remove",
    planId: string,
    selectedZoneId?: string,
  ) => void;
  handleRemoveZonePin?: (zoneId: string) => void;
}

export const SelectedZoneContext = createContext<SelectedZoneType>({});

interface SelectedZonePropsType {
  viewId?: string;
  planId?: string;
}

const SelectedZoneState: FC<SelectedZonePropsType> = ({
  viewId,
  planId,
  children,
}) => {
  const { selectedPlanId, plan, updatePlanDetails } = useSelectedPlan();
  const [selectedZoneId, setSelectedZoneId] = useState<string>();
  const handleSetSelectedZoneId = (zoneId?: string) => {
    setSelectedZoneId(zoneId);
  };
  const {
    zone,
    loading: zoneLoading,
    showZone,
    updateZoneDetails,
    updateZonePin,
    removeZonePin,
  } = ZoneService();

  useEffect(() => {
    if (viewId && selectedZoneId && planId)
      showZone(viewId, planId, selectedZoneId);
  }, [selectedZoneId, planId]);

  const handleUpdateZonePin = async (
    coordinate: Coordinate,
    pin: "add" | "remove",
    planId: string,
    zoneId?: string,
  ) => {
    if (viewId && zoneId) {
      const updatedZone = await updateZonePin(
        viewId,
        planId,
        zoneId,
        coordinate,
        pin,
      );

      if (plan && plan.zones && updatedZone) {
        const newZone = plan.zones.map((zone) => {
          if (zone.id === zoneId) {
            return updatedZone;
          }
          return zone;
        });

        updatePlanDetails?.({
          ...plan,
          zones: newZone,
        });
      }
    }
  };

  const handleRemoveZonePin = async (zoneId?: string) => {
    if (viewId && zoneId && selectedPlanId) {
      const updatedZone = await removeZonePin(
        viewId,
        selectedPlanId,
        zoneId,
        { x: 0, y: 0 },
        "remove",
      );

      if (plan && plan.zones && updatedZone) {
        const newZone = plan.zones.map((zone) => {
          if (zone.id === zoneId) {
            return updatedZone;
          }
          return zone;
        });

        updatePlanDetails?.({
          ...plan,
          zones: newZone,
        });
      }
    }
  };

  return (
    <SelectedZoneContext.Provider
      value={{
        selectedZoneId,
        handleSetSelectedZoneId,
        zone,
        zoneLoading,
        updateZoneDetails,
        handleUpdateZonePin,
        handleRemoveZonePin,
      }}
    >
      {children}
    </SelectedZoneContext.Provider>
  );
};

export const useSelectedZone = () => {
  const context = useContext<SelectedZoneType>(SelectedZoneContext);
  if (!context) {
    throw new Error(
      "SelectedZoneContext must be used within a SelectedZoneContextProvider",
    );
  }
  return { ...context };
};

export default SelectedZoneState;
