import React from "react";
import { Tooltip } from "antd";
import { HideDescription, ViewDescription } from "shared/icons";

import "./toggleDescriptionIcon.scss";

interface ToggleDescriptionIconProps {
  active?: boolean;
  onActiveUpdate?: () => void;
}

const ToggleDescriptionIcon = ({
  active,
  onActiveUpdate,
}: ToggleDescriptionIconProps) => {
  return (
    <Tooltip
      className="toggle-description-icon"
      placement="top"
      title={active ? "Hide Description" : "View Description"}
    >
      <span onClick={onActiveUpdate}>
        {active ? (
          <HideDescription className="icon icon--hide-description" />
        ) : (
          <ViewDescription className="icon icon--view-description" />
        )}
      </span>
    </Tooltip>
  );
};

export default ToggleDescriptionIcon;
