import axiosInstance from "interceptor/axiosInstance";
import {
  AddToPicklistParams,
  Picklist,
  PicklistedProducts,
} from "models/Picklist";
import Notification from "shared/components/Notification";

import { NotificationTypes } from "enums/notificationTypes";
import { useState } from "react";
import { ApiRoutes } from "routes/routeConstants/apiRoutes";
import { deserialize, serialize } from "serializr";
import { PaginationParams } from "models/PaginationParams";
import { generatePath } from "react-router-dom";
import { notification } from "antd";

const PicklistService = () => {
  const [loading, setLoading] = useState(false);
  const [initialpicklist, setInitialPicklists] = useState<Picklist[]>([]);

  const [picklists, setPicklists] = useState<Picklist[]>([]);
  const [picklistedVariants, setPicklistedVariants] =
    useState<PicklistedProducts>();
  const [updateLoading, setUpdateLoading] = useState(false);
  const [currentId, setCurrentId] = useState("");

  const fetchPicklists = async (search = "") => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(ApiRoutes.PICKLISTS, {
        params: { search },
      });
      const dataPicklists = deserialize(
        Picklist,
        data["picklists"] as Picklist[],
      );
      if (search === "") {
        setInitialPicklists(dataPicklists);
      }
      setPicklists(dataPicklists);
    } finally {
      setLoading(false);
    }
  };

  const createPicklist = async (picklist: Picklist) => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.post(ApiRoutes.PICKLISTS, {
        picklist: serialize(Picklist, picklist),
      });

      const newPicklist = deserialize(Picklist, data["picklist"]);
      setPicklists((currentObjects) => [newPicklist, ...currentObjects]);
      setInitialPicklists((currentObjects) => [newPicklist, ...currentObjects]);

      Notification({
        message: `${newPicklist.name} created`,
        type: NotificationTypes.SUCCESS,
      });
      return newPicklist;
    } catch (err) {
      Notification({
        message: `Could not create picklist`,
        type: NotificationTypes.ERROR,
      });
    } finally {
      setLoading(false);
    }
  };

  const updatePicklist = async (id: string, picklist: Picklist) => {
    try {
      setLoading(true);
      const URL = generatePath(ApiRoutes.PICKLIST, { id });
      const { data } = await axiosInstance.put(URL, { picklist });
      const updatedPicklist = deserialize(Picklist, data.picklist);
      const idx = picklists?.findIndex((pl) => pl.id === updatedPicklist.id);
      if (picklists) {
        const tempPl = [...picklists];
        if (idx !== undefined && idx >= 0 && idx < tempPl.length) {
          tempPl[idx] = updatedPicklist;
          setPicklists(tempPl);
          setInitialPicklists(tempPl);
        }
      } else {
        setPicklists([updatedPicklist]);
        setInitialPicklists([updatedPicklist]);
      }
      return updatedPicklist;
    } finally {
      setLoading(false);
    }
  };

  const addProductVariantToPicklists = async (
    picklist: AddToPicklistParams,
    newProduct = true,
  ) => {
    notification.destroy();
    setUpdateLoading(true);
    if (picklist.products != undefined) {
      if (picklist.products[0]?.quantity != undefined) {
        setCurrentId(picklist.products![0]!.variantId!.id!);
      }
    }
    try {
      const { data } = await axiosInstance.patch(ApiRoutes.PICKLIST_PRODUCT, {
        picklist: serialize(AddToPicklistParams, picklist),
      });
      const updatedProduct = data?.picklists?.[0];
      if (updatedProduct?.quantity === 0) {
        await fetchPicklistedVariants(picklist?.ids?.[0] ?? "");
        return;
      }
      const idx = picklistedVariants?.picklist?.products?.findIndex((p) => {
        return p?.variantId?.id === updatedProduct?.variantId;
      });
      if (picklistedVariants?.picklist?.products) {
        picklistedVariants.picklist.products[idx ?? 0].quantity =
          updatedProduct?.quantity;
      }
      Notification({
        message: newProduct
          ? "Product added to picklist"
          : "Updated the product count",
        type: NotificationTypes.SUCCESS,
      });
    } catch (err) {
      Notification({
        message: newProduct
          ? "Product could not be added to picklist"
          : "Product count could not be updated",
        type: NotificationTypes.SUCCESS,
      });
    } finally {
      setUpdateLoading(false);
      setCurrentId("");
    }
  };

  const fetchPicklistedVariants = async (
    id: string,
    params?: PaginationParams,
  ) => {
    try {
      setLoading(true);
      const URL = generatePath(ApiRoutes.PICKLIST, { id });
      const { data } = await axiosInstance.get(URL, {
        params,
      });
      setPicklistedVariants(deserialize(PicklistedProducts, data));
    } finally {
      setLoading(false);
    }
  };

  const clearPicklist = async (id: string) => {
    try {
      setLoading(true);
      const URL = generatePath(ApiRoutes.PICKLIST_CLEAR, { id });
      const { data } = await axiosInstance.put(URL);
      await fetchPicklistedVariants(id);
      const updatedPicklist = deserialize(Picklist, data.picklist);
      const idx = picklists?.findIndex((pl) => pl.id === updatedPicklist.id);
      if (picklists) {
        const tempPl = [...picklists];
        if (idx !== undefined && idx >= 0 && idx < tempPl.length) {
          tempPl[idx] = updatedPicklist;
          setPicklists(tempPl);
          setInitialPicklists(tempPl);
        }
      } else {
        setPicklists([updatedPicklist]);
        setInitialPicklists([updatedPicklist]);
      }
      return updatedPicklist;
    } finally {
      setLoading(false);
    }
  };

  const removeProductVariantFromPicklist = async (
    picklistId: string,
    productId: string,
  ) => {
    try {
      const URL = generatePath(ApiRoutes.PICKLIST_REMOVE_PRODUCT, {
        picklistId,
        productId,
      });
      await axiosInstance.patch(URL);
      await fetchPicklistedVariants(picklistId);
    } catch (ex) {
      Notification({
        message: `Could not remove product`,
        type: NotificationTypes.ERROR,
      });
    }
  };

  const exportPicklist = async (picklistId: string) => {
    try {
      const URL = generatePath(ApiRoutes.PICKLIST_EXPORT, { picklistId });
      const { data } = await axiosInstance.get(URL, {
        responseType: "blob",
        headers: {
          Accept: "application/pdf",
        },
      });
      return data;
    } catch (err) {
      Notification({
        message: "Could not export the file!",
        type: NotificationTypes.ERROR,
      });
    }
  };

  const deletePicklist = async (id: string) => {
    try {
      setLoading(true);
      const URL = generatePath(ApiRoutes.PICKLIST, { id });
      const { data } = await axiosInstance.delete(URL);
      await fetchPicklists();
      setPicklistedVariants(undefined);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    picklists,
    setPicklists,
    currentId,
    fetchPicklists,
    createPicklist,
    addProductVariantToPicklists,
    picklistedVariants,
    initialpicklist,
    setPicklistedVariants,
    fetchPicklistedVariants,
    updatePicklist,
    clearPicklist,
    removeProductVariantFromPicklist,
    exportPicklist,
    deletePicklist,
    updateLoading,
  };
};

export default PicklistService;
