import React from "react";
import { CategoryConfiguration } from "models/CategoryConfiguration/categoryConfiguration.model";
import DeleteModal from "shared/components/DeleteModal";
import { ModalProps } from "antd";
import ConfigurationCategoryService from "services/ConfigurationCategoryService/configurationCategory.service";

interface DeleteConfigModalProps extends Omit<ModalProps, "title"> {
  onDelete: () => void;
  onCancel: () => void;
  config: CategoryConfiguration;
  mappedConfig?: CategoryConfiguration;
}

const DeleteConfigModal = ({
  config,
  mappedConfig,
  onCancel,
  onDelete,
  ...props
}: DeleteConfigModalProps) => {
  const { deleteCategory, deleteSubCategory, deleteType } =
    ConfigurationCategoryService();

  const isTypeDelete = !!config?.type?.id;

  const isSubCategoryDelete = !isTypeDelete && !!config?.subCategory?.id;

  const name = isTypeDelete
    ? "Type"
    : isSubCategoryDelete
      ? "Sub Category"
      : "Category";

  const handleSubmit = async () => {
    const isDeleted = await (
      isTypeDelete
        ? deleteType
        : isSubCategoryDelete
          ? deleteSubCategory
          : deleteCategory
    )(config, mappedConfig);

    if (!isDeleted) return;

    onDelete();
    onCancel();
  };

  return (
    <DeleteModal
      title={`Delete ${name}`}
      subTitle={`${name} will be deleted from the system`}
      onCancel={onCancel}
      onDelete={handleSubmit}
      {...props}
    />
  );
};

export default DeleteConfigModal;
