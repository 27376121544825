import React, { useState } from "react";

const useToggle = (initialToggle?: boolean) => {
  const [isToggled, setToggle] = useState(!!initialToggle);

  const toggle = () => setToggle((open) => !open);

  const toggleOn = () => setToggle(true);

  const toggleOff = () => {
    setToggle(false);
  };

  return {
    isToggled,
    toggleOff,
    toggleOn,
    toggle,
    setToggle,
  };
};

export default useToggle;
