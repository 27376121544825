import React, { useEffect } from "react";
import Tabs from "shared/components/Tabs";
import { getTabItems } from "./tabItems";
import "./productsFilter.scss";
import { useFilterToggle } from "context/FilterToggleContext";

const ProductsFilter = () => {
  const { defaultKey, setDefaultKey } = useFilterToggle();
  return (
    <div className="products-filter">
      <Tabs
        items={getTabItems()}
        activeKey={defaultKey}
        onChange={(activeKey) => {
          setDefaultKey?.(activeKey);
        }}
      />
    </div>
  );
};

export default ProductsFilter;
