import React, { useState } from "react";
import { Table as AntTable, TableProps as AntTableProps } from "antd";
import { ReactComponent as RightArrow } from "assets/icons/solid/right-arrow.svg";
import { ReactComponent as LeftArrow } from "assets/icons/solid/left-arrow.svg";
import summarizePagination from "../Pagination/renderPaginationTotal";
import EditableRow from "./EditableRow";
import EditableCell from "./EditableCell";
import { EditableColumnsType } from "shared/types/editableColumn.type";

import "./table.scss";

interface TableProps<T> extends Omit<AntTableProps<T>, "columns"> {
  search?: string;
  searchPlaceholder?: string;
  onSearch?: (search: string) => void;
  columns: EditableColumnsType<T>;
  headerExtra?: React.ReactElement;
  isMapProduct?: boolean;
}

const Table = <T extends object>({
  search,
  searchPlaceholder,
  onSearch,
  pagination,
  headerExtra,
  onChange,
  isMapProduct,
  ...props
}: TableProps<T>) => {
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <div className="table">
      <div>{headerExtra}</div>
      <div className={isMapProduct ? "table-container" : ""}>
        <AntTable<T>
          components={{
            body: {
              cell: EditableCell,
              row: EditableRow,
            },
          }}
          pagination={
            pagination !== false && {
              nextIcon: <RightArrow className="icon" />,
              showTotal: summarizePagination,
              prevIcon: <LeftArrow className="icon" />,
              showSizeChanger: false,
              onChange: setCurrentPage,
              ...pagination,
            }
          }
          onChange={onChange}
          rowClassName="cursor-pointer"
          {...props}
        />
      </div>
    </div>
  );
};

export default Table;
