import React from "react";
import AppRoutes from "./routes";
import { AuthProvider } from "./context/AuthContext";
import { ConfigProvider } from "antd";
import variables from "./styles/variables";
import RequireNetwork from "./shared/components/HOC/requireNetwork";

import "./styles/_main.scss";
import "./App.css";

const {
  BORDER_RADIUS,
  BORDER_COLOR,
  BLUE,
  BLUE_LIGHT_20,
  CONTROL_OUTLINE,
  DANGER,
  FONT_FAMILY,
  GREY,
  GREY_2,
  GREY_4,
  PRIMARY_BLACK,
  PRIMARY_COLOR,
  PRIMARY_DARK_10,
  PRIMARY_LIGHT_30,
  PRIMARY_LIGHT_60,
  PRIMARY_LIGHT_70,
  TRANSPARENT,
  INPUT_BOX_SHADOW,
  RADIO_BORDER_COLOR,
  SELECT_BOX_SHADOW,
  SELECT_OPTION_HOVER,
  SUCCESS,
} = variables;

const App = () => {
  return (
    <ConfigProvider
      prefixCls="wzmh"
      theme={{
        token: {
          borderRadius: BORDER_RADIUS,
          colorBgContainerDisabled: GREY_4,
          colorBorder: BORDER_COLOR,
          colorPrimary: PRIMARY_COLOR,
          colorPrimaryHover: PRIMARY_COLOR,
          colorTextPlaceholder: GREY_2,
          controlItemBgActiveHover: BORDER_COLOR,
          controlOutline: CONTROL_OUTLINE,
          fontFamily: FONT_FAMILY,
          lineHeight: 1.714,
          colorError: DANGER,
          colorErrorBorder: DANGER,
        },
        components: {
          Input: {
            boxShadow: INPUT_BOX_SHADOW,
            borderRadius: 8,
          },
          Radio: {
            colorBorder: RADIO_BORDER_COLOR,
          },
          Select: {
            boxShadow: SELECT_BOX_SHADOW,
            controlItemBgHover: SELECT_OPTION_HOVER,
          },
          Pagination: {
            borderRadius: 100,
            colorText: GREY_2,
            colorPrimary: PRIMARY_BLACK,
            fontSize: 12,
          },
          Switch: {
            colorBorder: SUCCESS,
            colorPrimary: SUCCESS,
            colorPrimaryHover: SUCCESS,
          },
          Button: {
            colorBorder: "none",
            colorBgContainerDisabled: PRIMARY_LIGHT_60,
            colorBgTextActive: TRANSPARENT,
            colorBgTextHover: TRANSPARENT,
            colorLink: PRIMARY_COLOR,
            colorLinkActive: PRIMARY_COLOR,
            colorLinkHover: PRIMARY_COLOR,
            colorPrimary: PRIMARY_COLOR,
            colorPrimaryActive: PRIMARY_LIGHT_30,
            colorPrimaryBorderHover: PRIMARY_LIGHT_70,
            colorPrimaryHover: PRIMARY_DARK_10,
            colorTextDisabled: PRIMARY_COLOR,
            colorText: GREY,
            colorTextBase: GREY,
            opacityLoading: 1,
          },
          Popover: {
            borderRadiusLG: 8,
          },
          Modal: {
            borderRadiusLG: 16,
          },
          Tabs: {
            colorPrimary: PRIMARY_COLOR,
            colorPrimaryActive: GREY,
            colorPrimaryHover: GREY,
          },
          Breadcrumb: {
            colorBgTextHover: "transparent",
            colorText: GREY,
            colorTextDescription: GREY_2,
          },
        },
      }}
    >
      <RequireNetwork>
        <AuthProvider>
          <AppRoutes />
        </AuthProvider>
      </RequireNetwork>
    </ConfigProvider>
  );
};

export default App;
