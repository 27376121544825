import UserRole from "models/UserRole/userRole.model";
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import UserRoleService from "services/UserRoleService/userRole.service";

interface UserRolesType {
  userRoles: UserRole[];
  getUserRoles?: () => void;
  putUserRoles?: (userRole: UserRole) => Promise<void>;

  loading: boolean;
}
const initialValues = {
  userRoles: [],
  loading: true,
};
// create context
export const UserRolesContext = createContext<UserRolesType>(initialValues);

//create context provider
const UserRolesState: FC = ({ children }) => {
  const { userRoles, getUserRoles, loading, putUserRoles } = UserRoleService();
  return (
    <UserRolesContext.Provider
      value={{ userRoles, loading, getUserRoles, putUserRoles }}
    >
      {children}
    </UserRolesContext.Provider>
  );
};

//using context
export const useUserRoles = () => {
  const context = useContext<UserRolesType>(UserRolesContext);
  if (!context) {
    throw new Error(
      "UserRolesContext must be used within a UserRolesContextProvider",
    );
  }
  return { ...context };
};

export default UserRolesState;
