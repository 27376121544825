import React from "react";
import "./marker.scss";
import { Eye, Pin, ProductPin, RemovePin } from "shared/icons";
import { Popover, Tooltip } from "antd";
import { useSelectedPlan } from "context/SelectedPlanContext";
import { useSelectedSpace } from "context/SelectedSpaceContext";
import useRedirect from "shared/hooks/useRedirect";
import { useParams } from "react-router-dom";
import { usePinMarkers } from "context/PinMarkersContext";
import { Space } from "models/Space/space.model";
import { useSelectedVariantId } from "context/SelectedVariantContext";
import { useOpenedLayerVariant } from "context/OpenedLayerVariantContext";
import { DiagramView } from "enums/diagramView";
import { useSelectedZone } from "context/SelectedZoneContext";

interface MarkerProps {
  dataset: number[][];
  noiseIndex: number;
  space?: Space;
  selectedMarker?: string;
  setSelectedMarker?: React.Dispatch<React.SetStateAction<string | undefined>>;
  viewType: DiagramView.PROTOTYPE | DiagramView.SPACE;
}

const Marker = ({
  noiseIndex,
  space,
  selectedMarker,
  setSelectedMarker,
  viewType,
}: MarkerProps) => {
  const dualViewSelectedPlanContext = useSelectedPlan();
  const { removePin, handleSetSelectedSpace, selectedSpace } =
    useSelectedSpace();
  const { selectedZoneId, handleSetSelectedZoneId } = useSelectedZone();

  const { redirectToSpaceView } = useRedirect();
  const { prototypeId } = useParams();
  const { removePinMarker } = usePinMarkers();
  const { handleUnpinProductFromLayer, selectedVariant } =
    useSelectedVariantId();
  const { setOpenedLayerVariantId } = useOpenedLayerVariant();
  const isViewPrototype = viewType === DiagramView.PROTOTYPE;

  const handleRemovePin = () => {
    if (isViewPrototype) {
      removePinMarker?.(noiseIndex);
      removePin?.(space?.id ?? "");
    } else {
      handleUnpinProductFromLayer?.(space?.id ?? "");
    }
  };
  const content = (
    <div className="pin-marker-details">
      <div className="title">{space?.title}</div>{" "}
      <div>
        <Tooltip title="Remove Pin">
          <RemovePin className="icon-pin" onClick={handleRemovePin} />
        </Tooltip>
      </div>
    </div>
  );
  const isSelected =
    (isViewPrototype ? selectedSpace?.id : selectedVariant?.id) === space?.id;

  let markerSubtitle = "View Space";
  if (viewType === DiagramView.SPACE) {
    markerSubtitle = "View Product";
  } else if (viewType === DiagramView.PROTOTYPE && !selectedZoneId) {
    markerSubtitle = "View Zone";
  }

  return (
    <Popover content={content} className="marker" placement="bottom">
      <span
        className="marker"
        style={{
          cursor: "pointer",
        }}
        onClick={() => {
          setSelectedMarker?.(space?.id);
          space?.id &&
            handleSetSelectedSpace?.({
              id: space?.id,
              coordinates: space?.coordinate,
            });
        }}
      >
        <div
          className={`marker__outer ${isSelected && "color-black"}`}
          id={space?.id}
        >
          <>
            <Tooltip title={markerSubtitle}>
              <Eye
                className="icon-pin icon-eye-hover"
                onClick={() => {
                  if (viewType === DiagramView.SPACE) {
                    setOpenedLayerVariantId?.(space?.id);
                  } else if (
                    prototypeId &&
                    dualViewSelectedPlanContext?.selectedPlanId &&
                    selectedZoneId &&
                    space?.id
                  ) {
                    redirectToSpaceView(
                      prototypeId,
                      dualViewSelectedPlanContext?.selectedPlanId,
                      selectedZoneId,
                      space?.id,
                    );
                  } else if (viewType === DiagramView.PROTOTYPE) {
                    handleSetSelectedZoneId?.(space?.id);
                  }
                }}
              />
            </Tooltip>
            {isViewPrototype ? (
              <Pin className="icon-pin" />
            ) : (
              <ProductPin className="icon-pin" />
            )}
          </>
        </div>
      </span>
    </Popover>
  );
};

export default Marker;
