import React from "react";
import { Checkbox } from "shared/components/Checkbox";
import { useField } from "formik";
import get from "lodash.get";

import "./groupedChecklist.scss";

interface GroupedChecklistProps<T, U> {
  getGroupTitle: (groupElements: U[], id: string) => T | undefined;
  groupElements: U[];
  groupItems: (items: T[]) => Map<string, T[]>;
  items: T[];
  labelKey?: keyof T;
  name: string;
  onChange: (chosenIds: string[]) => void;
  title: string;
  valueKey?: string;
}

interface IGroup {
  name?: string;
  id?: string;
}

const GroupedChecklist = <T extends IGroup, U extends IGroup>({
  title,
  groupItems,
  items,
  labelKey = "name",
  valueKey = "id",
  groupElements,
  getGroupTitle,
  name,
  onChange,
}: GroupedChecklistProps<T, U>) => {
  const [{ value: chosenIds }] = useField<string[]>(name);

  const groupedItems = groupItems(items);

  const groupedContent: React.ReactElement[] = [];

  groupedItems.forEach((group, groupId) =>
    groupedContent.push(
      <div className="text-capitalize grouped-checklist__content">
        <div className="grouped-checklist__content__title">
          {getGroupTitle(groupElements, groupId)?.name}
        </div>
        <div className="grouped-checklist__content__list">
          {group?.map((value) => (
            <Checkbox
              key={get(value, valueKey)}
              checked={chosenIds.includes(get(value, valueKey))}
              onChange={(e) =>
                onChange(
                  e.target.checked
                    ? [...chosenIds, get(value, valueKey)]
                    : chosenIds?.filter((id) => id !== get(value, valueKey)),
                )
              }
            >
              {value[labelKey]}
            </Checkbox>
          ))}
        </div>
      </div>,
    ),
  );

  return (
    <div className="grouped-checklist">
      <div className="grouped-checklist__title">{title}</div>
      {groupedContent}
    </div>
  );
};

export default GroupedChecklist;
