export enum Module {
  PRODUCT = "Product",
  PICKLIST = "Picklist",
  USER_MANAGEMENT = "User Management",
  CATEGORY_CONFIGURATION = "Category Configuration",
  CLIENT_SETTINGS = "Client Settings",
  PROTOTYPE = "Prototype",
  USER_ACCESS_AND_LIMIT = "User Access And Limit",
  SPACE_CONFIGURATION = "Space Configuration",
}
