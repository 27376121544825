import React, { FC, useEffect } from "react";
import "./activity.scss";
import PrototypeService from "services/PrototypeService/prototype.service";
import ActivityLogCard from "./ActivityLogCard";
import { Steps } from "antd";
import Button from "shared/components/Button";
import {
  activityIconSelector,
  activityLogMessageGenerator,
  getLeafNode,
} from "utils";
import { PaginationParams } from "models/PaginationParams";
import { ButtonType } from "enums/buttonType.enum";
import Spinner from "shared/components/Spinner";
import ActivityNotificationPopUp from "views/Prototypes/ActivityNotifications";
import NotificationService from "services/NotificationService/notification.service";

interface ActivityLogType {
  prototypeId?: string | null;
}

const ActivityLog: FC<ActivityLogType> = ({ prototypeId }) => {
  const {
    getActivityLogs,
    activityLogs,
    activityLogsMeta,
    activityLogsLoading,
  } = PrototypeService();

  const {
    notifications,
    notificationsLoading,
    fetchNotifications,
    // clearAllNotifications,
  } = NotificationService();

  const handlePagination = () => {
    if (prototypeId) {
      activityLogsMeta?.nextPage &&
        getActivityLogs(prototypeId, {
          ...new PaginationParams(),
          page: activityLogsMeta?.nextPage,
        });
    }
  };

  useEffect(() => {
    if (prototypeId) {
      getActivityLogs(prototypeId);
    } else {
      fetchNotifications();
    }
  }, []);

  const activityLogsData = prototypeId ? activityLogs : notifications;

  const stepItems = activityLogsData?.map(
    ({
      createdAt,
      hierarchy,
      user,
      action,
      fieldUpdated,
      module,
      oldData,
      newData,
    }) => ({
      title: action && hierarchy && (
        <ActivityLogCard
          message={activityLogMessageGenerator(
            action,
            getLeafNode(hierarchy),
            fieldUpdated,
            module,
            hierarchy,
          )}
          hierarchy={hierarchy}
          user={user}
          date={createdAt}
          module={module}
          action={action}
          field={fieldUpdated}
          oldData={oldData}
          newData={newData}
          prototypeId={prototypeId}
        />
      ),
      icon: action && activityIconSelector(action, fieldUpdated),
    }),
  );

  return (
    <div className="activity-container">
      <div className="activity-container__heading">
        <div className="activity-container__heading__left">ACTIVITY</div>
        <div className="activity-container__heading__right">
          {prototypeId && (
            <ActivityNotificationPopUp protoTypeId={prototypeId} />
          )}
        </div>
      </div>

      <div className="activity-logs scrollbar-visible">
        <Spinner
          loading={
            (activityLogsLoading || notificationsLoading) &&
            !activityLogsMeta?.nextPage
          }
        >
          <Steps items={stepItems} direction="vertical" />
        </Spinner>
        {activityLogsMeta?.nextPage && prototypeId && (
          <Button
            loading={activityLogsLoading || notificationsLoading}
            type={ButtonType.DEFAULT}
            onClick={handlePagination}
            disabled={!activityLogsMeta?.nextPage}
          >
            Show more
          </Button>
        )}
      </div>
    </div>
  );
};

export default ActivityLog;
