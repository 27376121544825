import { serializable, object } from "serializr";
import { Attachment } from "models/Attachment/attachment.model";

export class Prototype {
  @serializable
  id?: string;

  @serializable
  title?: string;

  @serializable
  description?: string;

  @serializable(object(Attachment))
  image?: Attachment;
}
