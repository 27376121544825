import { NotificationTypes } from "enums/notificationTypes";
import axiosInstance from "interceptor/axiosInstance";
import { PrototypeActivity } from "models/PrototypeActivity/prototypeActivity.model";
import { useState } from "react";
import { ApiRoutes } from "routes/routeConstants/apiRoutes";
import { deserialize } from "serializr";
import Notification from "shared/components/Notification";

const NotificationService = () => {
  const [notificationsLoading, setNotificationsLoading] =
    useState<boolean>(false);
  const [latestNotificationId, setLatestNotificationId] = useState<string>();
  const [notifications, setNotifications] = useState<PrototypeActivity[]>();

  const fetchNotifications = async () => {
    try {
      setNotificationsLoading(true);
      const { data } = await axiosInstance.get(ApiRoutes.NOTIFICATIONS);
      const notificationList = deserialize(
        PrototypeActivity,
        data["activities"] as unknown[],
      );
      setNotifications(notificationList);
      notificationList.length &&
        setLatestNotificationId(notificationList[0]?.id);
    } catch (err) {
      Notification({
        message: "Could not fetch notifications",
        type: NotificationTypes.ERROR,
      });
    } finally {
      setNotificationsLoading(false);
    }
  };
  const clearAllNotifications = async () => {
    try {
      setNotificationsLoading(true);
      await axiosInstance.patch(ApiRoutes.CLEAR_NOTIFICATIONS, {
        clear_all: { last_read_notification: { id: latestNotificationId } },
      });
      setNotifications([]);
      setLatestNotificationId(undefined);
    } catch (err) {
      Notification({
        message: "Could not clear notifications",
        type: NotificationTypes.ERROR,
      });
    } finally {
      setNotificationsLoading(false);
    }
  };
  return {
    fetchNotifications,
    clearAllNotifications,
    notificationsLoading,
    notifications,
  };
};

export default NotificationService;
