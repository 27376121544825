import React, { useEffect, useState } from "react";
import { PrototypeWithPlans } from "models/PrototypeWithPlans/prototypeWithPlans";
import { Form, Formik, FormikConfig } from "formik";
import PrototypeService from "services/PrototypeService/prototype.service";
import Input from "shared/components/Input";
import Button from "shared/components/Button";
import { ButtonType } from "enums/buttonType.enum";
import { useParams } from "react-router-dom";
import { PrototypeParams } from "shared/types/routeParams";
import TextArea from "shared/components/TextArea";
import useRedirect from "shared/hooks/useRedirect";
import prototypeValidation from "./prototypeValidation";
import "./prototypeForm.scss";
import UploadedImage from "shared/components/UploadedImage";
import { Tooltip } from "antd";
import UploadInput from "shared/components/UploadInput";
import { Back, Delete, ViewDescription } from "shared/icons";
import DeleteModal, { DeleteModalProps } from "shared/components/DeleteModal";
import Spinner from "shared/components/Spinner";
import Notification from "shared/components/Notification";
import { NotificationTypes } from "enums/notificationTypes";
import { userHasAccess } from "utils";
import { Module } from "enums/module.enum";
import { Method } from "enums/method.enum";
import { useQuery } from "shared/hooks/useNetwork";

const PrototypeForm = () => {
  const { prototypeId } = useParams<PrototypeParams>();
  const query = useQuery();
  const fromNotification = query.get("fromNotification");

  const {
    loading,
    prototype,
    createPrototype,
    updatePrototype,
    showPrototype,
    prototypeLoading,
    deletePrototype,
  } = PrototypeService();

  const {
    redirectToPrototypes,
    redirectToViewPrototype,
    redirectToNotification,
  } = useRedirect();
  const [modalProps, setModalProps] = useState<DeleteModalProps>({
    open: false,
    loading: false,
    onCancel: () => {
      return;
    },
    onDelete: () => {
      return;
    },
    subTitle: "",
    title: <></>,
    deleteText: "",
    hideTrash: true,
  });

  const handleSubmit: FormikConfig<PrototypeWithPlans>["onSubmit"] = async (
    prototype
  ) => {
    const updatedPrototype = await (prototype?.id
      ? updatePrototype
      : createPrototype)(prototype);

    if (!updatedPrototype) return;

    updatedPrototype?.id
      ? redirectToViewPrototype(updatedPrototype.id)
      : redirectToPrototypes();
  };

  const handleEdit = async (
    submitForm: (() => Promise<void>) & (() => Promise<any>)
  ) => {
    await submitForm();
    prototypeId ? redirectToViewPrototype(prototypeId) : redirectToPrototypes();
  };

  const handleDelete = async (id: string) => {
    await deletePrototype(id);
    setModalProps({ ...modalProps, open: false });
    redirectToPrototypes();
  };

  useEffect(() => {
    if (!prototypeId) return;

    showPrototype(prototypeId);
  }, [prototypeId]);

  return (
    <>
      <Spinner loading={loading}>
        <div className="prototype-form">
          <Formik
            initialValues={prototype}
            onSubmit={handleSubmit}
            validationSchema={prototypeValidation}
            enableReinitialize
          >
            {({ isSubmitting, values, submitForm, setFieldValue, isValid }) => (
              <>
                <Form>
                  <div className="prototype-form__container">
                    <div className="prototype-form__container__buttons">
                      <div
                        className="prototype-form__container__buttons__back"
                        onClick={() => {
                          if (fromNotification && prototypeId) {
                            return redirectToNotification(prototypeId);
                          }

                          prototypeId
                            ? redirectToViewPrototype(prototypeId)
                            : redirectToPrototypes();
                        }}
                      >
                        <span className="icon-container">
                          <Back className="icon--back" />
                          <span className="text-capitalize">back</span>
                        </span>
                      </div>
                      <div className="prototype-form__container__buttons__form-action">
                        {prototypeId &&
                          userHasAccess(Module.PROTOTYPE, Method.DELETE) && (
                            <Button
                              type={ButtonType.TEXT}
                              icon={<Delete className="icon-delete" />}
                              className="button--delete"
                              onClick={() => {
                                setModalProps({
                                  hideTrash: false,
                                  onDelete: () => {
                                    handleDelete(prototypeId ?? "");
                                  },
                                  onCancel: () => {
                                    setModalProps({
                                      ...modalProps,
                                      open: false,
                                    });
                                  },
                                  open: true,
                                  deleteText: "Delete",
                                  subTitle:
                                    "This prototype will be permanently deleted.",
                                  loading: prototypeLoading,
                                });
                              }}
                            >
                              Delete Prototype
                            </Button>
                          )}
                        <Button
                          type={ButtonType.DEFAULT}
                          onClick={() => {
                            setModalProps({
                              hideTrash: true,
                              onDelete: () => {
                                prototypeId
                                  ? redirectToViewPrototype(prototypeId)
                                  : redirectToPrototypes();
                              },
                              onCancel: () => {
                                setModalProps({ ...modalProps, open: false });
                              },
                              open: true,
                              deleteText: "Discard",
                              title: "Are you sure to discard?",
                              subTitle: "The changes made will not be saved.",
                              width: 340,
                            });
                          }}
                        >
                          Discard
                        </Button>
                        <Button
                          htmlType={prototype?.id ? undefined : "submit"}
                          loading={prototype?.id ? false : isSubmitting}
                          onClick={() => {
                            prototype?.id &&
                              setModalProps({
                                hideTrash: true,
                                onDelete: () => {
                                  if (isValid) {
                                    handleEdit(submitForm);
                                  } else {
                                    setModalProps({
                                      ...modalProps,
                                      open: false,
                                    });
                                    Notification({
                                      message: "Invalid data provided",
                                      type: NotificationTypes.ERROR,
                                    });
                                  }
                                },
                                onCancel: () => {
                                  setModalProps({ ...modalProps, open: false });
                                },
                                open: true,
                                deleteText: "Update",
                                title: "Are you sure to update?",
                                loading: isSubmitting,
                                width: 340,
                              });
                          }}
                          type={ButtonType.PRIMARY}
                        >
                          {prototype?.id ? "Update" : "Create"}
                        </Button>
                      </div>
                    </div>
                    <div className="prototype-form__container__img">
                      <div className="display-flex">
                        <div className="mg-r-8">
                          {values?.image ? (
                            <UploadedImage
                              expandable
                              width={44}
                              hideRemoveTextOnOverlay
                              src={values?.image?.url}
                              onDelete={() => {
                                setFieldValue("image", undefined);
                              }}
                            />
                          ) : (
                            <Tooltip title="Supported formats: JPG, JPEG & PNG in 1:1 ratio and < 10 MB">
                              <UploadInput.Formik
                                name={"image"}
                                width={20}
                                height={44}
                              />
                            </Tooltip>
                          )}
                        </div>
                        <Input.Formik
                          name="title"
                          placeholder="Enter Prototype Name"
                        />
                      </div>
                    </div>
                    <div className="prototype-form__description__container">
                      <div className="prototype-form__description__title">
                        <ViewDescription className="icon-view-description" />
                        <span>Prototype Description</span>
                      </div>
                      <TextArea.Formik
                        name="description"
                        rows={10}
                        className="prototype-form__description__input"
                      />
                    </div>
                  </div>
                </Form>

                {/* Confirmation modal */}
                <DeleteModal {...modalProps} />
              </>
            )}
          </Formik>
        </div>
      </Spinner>
    </>
  );
};

export default PrototypeForm;
