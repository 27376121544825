import { alias, object, serializable } from "serializr";

export class ProtoTypeId {
  @serializable
  id?: string;
}
export class ActivityNotification {
  @serializable
  id?: string;

  @serializable
  overall?: boolean;

  @serializable(alias("prototype_id"))
  protoTypeId?: boolean;

  @serializable(alias("prototype_attributes_update_activity"))
  protoTypeAttributesUpdateActivity?: boolean;

  @serializable(alias("plan_creation_activity"))
  planCreationActivity?: boolean;

  @serializable(alias("plan_attributes_update_activity"))
  planAttributesUpdateActivity?: boolean;

  @serializable(alias("plan_diagram_update_activity"))
  planDiagramUpdateActivity?: boolean;

  @serializable(alias("plan_deletion_activity"))
  planDeletionActivity?: boolean;

  @serializable(alias("space_creation_activity"))
  spaceCreationActivity?: boolean;

  @serializable(alias("space_attributes_update_activity"))
  spaceAttributesUpdateActivity?: boolean;

  @serializable(alias("space_gallery_activity"))
  spaceGalleryActivity?: boolean;

  @serializable(alias("space_pin_update_activity"))
  spacePinUpdateActivity?: boolean;

  @serializable(alias("space_deletion_activity"))
  spaceDeletionActivity?: boolean;

  @serializable(alias("layer_creation_activity"))
  layerCreationActivity?: boolean;

  @serializable(alias("layer_deletion_activity"))
  layerDeletionActivity?: boolean;

  @serializable(alias("layer_attributes_update_activity"))
  layerAttributesUpdateActivity?: boolean;

  @serializable(alias("layer_diagram_update_activity"))
  layerDiagramUpdateActivity?: boolean;

  @serializable(alias("product_map_creation_activity"))
  productMapCreationActivity?: boolean;

  @serializable(alias("product_map_update_activity"))
  productMapUpdateActivity?: boolean;

  @serializable(alias("product_map_remove_activity"))
  productMapRemoveActivity?: boolean;

  @serializable(alias("product_pin_update_activity"))
  productPinUpdateActivity?: boolean;
}
export class ProtoTypeNotification {
  @serializable
  id?: string;

  @serializable(alias("prototype_id", object(ProtoTypeId)))
  protoTypeId?: ProtoTypeId;

  @serializable(alias("activity_notification", object(ActivityNotification)))
  activityNotification?: ActivityNotification;
}
