import { useState } from "react";
import { deserialize, serialize } from "serializr";
import axiosInstance from "../../interceptor/axiosInstance";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import {
  Product,
  ProductMeta,
  ProductMetaParams,
  ProductParams,
  MaxValuesMeta,
} from "../../models/Product/product.model";
import Notification from "shared/components/Notification";
import { NotificationTypes } from "enums/notificationTypes";
import { generatePath, useLocation } from "react-router-dom";
import { ProductFilters } from "models/ProductFilters/productFilters.model";
import { PaginationMeta } from "models/PaginationMeta";
import Axios, { CancelTokenSource } from "axios";
import QueryString from "qs";

function cleanupProduct(payload: Product) {
  const cleanedPayload = { ...payload };

  if (payload?.measurement) {
    const measurement = { ...payload.measurement };
    if (!measurement?.length?.value) {
      delete measurement.length;
    }

    if (!measurement?.width?.value) {
      delete measurement.width;
    }

    if (!measurement?.height?.value) {
      delete measurement.height;
    }

    if (!measurement?.weight?.value) {
      delete measurement.weight;
    }

    if (Object.keys(measurement).length === 0) {
      delete payload.measurement;
    }
  }

  if (payload?.productionLocation) {
    if (!payload?.productionLocation.country) {
      delete payload.productionLocation;
    }
  }

  return cleanedPayload;
}

const ProductService = () => {
  const location = useLocation();

  const [loading, setLoading] = useState<boolean>(false);

  const [productLoading, setProductLoading] = useState<boolean>(false);

  const [products, setProducts] = useState<Product[]>([]);

  const [product, setProduct] = useState(
    Object.assign<Product, Partial<Product>>(new Product(), {
      name: "Untitled product",
    }),
  );

  const [filters, setFilters] = useState(new ProductFilters());

  const [filtersLoading, setFiltersLoading] = useState(false);

  const [params, setParams] = useState<ProductParams>(
    ProductParams.initializeParams(
      QueryString.parse(location?.search, {
        ignoreQueryPrefix: true,
      }),
    ),
  );

  const [maxValuesMeta, setMaxValuesMeta] = useState<MaxValuesMeta>();

  const [productsMeta, setProductsMeta] = useState(new ProductMeta());

  const [productsMetaLoading, setProductsMetaLoading] = useState(false);

  const [meta, setMeta] = useState<PaginationMeta>();

  const [productsToken, setProductsToken] = useState<CancelTokenSource>();

  const fetchProducts = async (queryParams = params) => {
    try {
      setLoading(true);

      productsToken?.cancel("Products Fetch Cancelled");

      const newCancelToken = Axios.CancelToken.source();

      setProductsToken(newCancelToken);

      const { data } = await axiosInstance.get(ApiRoutes.PRODUCTS, {
        params: serialize(ProductParams, queryParams),
        cancelToken: newCancelToken.token,
      });
      const products = deserialize(Product, data["products"] as unknown[]);
      const meta = deserialize(PaginationMeta, data["meta"]);

      setMeta(meta);
      setProducts(products);
      setProductsToken(undefined);
    } finally {
      setLoading(false);
    }
  };

  const fetchProduct = async (id: string) => {
    try {
      setLoading(true);
      const ENDPOINT = generatePath(ApiRoutes.PRODUCT, {
        id,
      });
      const { data } = await axiosInstance.get(ENDPOINT);
      const product = deserialize(Product, data["product"]);
      setProduct(product);
    } finally {
      setLoading(false);
    }
  };

  const createProduct = async (product: Product) => {
    try {
      setProductLoading(true);
      const cleanedProduct = cleanupProduct(product);
      const payload = { product: serialize(Product, cleanedProduct) };

      const response = await axiosInstance.post(ApiRoutes.PRODUCTS, payload);
      const data = deserialize(Product, response.data["product"]) as Product;
      setProduct(data);
      return data;
    } catch (ex) {
      Notification({
        message: (ex as Error)?.message || "Product Creation Failed",
        type: NotificationTypes.ERROR,
      });
    } finally {
      setProductLoading(false);
    }
  };

  const updateProduct = async (product: Product) => {
    try {
      setProductLoading(true);

      const payload = { product: serialize(Product, product) };

      const ENDPOINT = generatePath(ApiRoutes.PRODUCT, {
        id: product?.id,
      });

      const response = await axiosInstance.put(ENDPOINT, payload);

      const data = deserialize(Product, response.data["product"]) as Product;

      setProduct(data);

      return data;
    } catch (ex) {
      Notification({
        message: (ex as Error)?.message || "Product Creation Failed",
        type: NotificationTypes.ERROR,
      });
    } finally {
      setProductLoading(false);
    }
  };

  const deleteProduct = async (productId: string) => {
    try {
      setProductLoading(true);

      const ENDPOINT = generatePath(ApiRoutes.PRODUCT, {
        id: productId,
      });

      await axiosInstance.delete(ENDPOINT);

      Notification({
        message: "Product Deleted",
        type: NotificationTypes.SUCCESS,
      });
      return true;
    } catch (ex) {
      Notification({
        message: "Unable to delete Product",
        type: NotificationTypes.ERROR,
      });
      return false;
    } finally {
      setProductLoading(false);
    }
  };

  const fetchFilters = async () => {
    try {
      setFiltersLoading(true);

      const { data } = await axiosInstance.get(ApiRoutes.PRODUCT_FILTERS);

      const filters = deserialize(ProductFilters, data);

      setFilters(filters);
    } finally {
      setFiltersLoading(false);
    }
  };

  const fetchProductsMeta = async (params = new ProductMetaParams()) => {
    try {
      setProductsMetaLoading(true);
      const { data } = await axiosInstance.get(ApiRoutes.PRODUCT_META, {
        params: serialize(ProductMetaParams, params),
      });

      const meta = deserialize(ProductMeta, data["products"]);
      setProductsMeta(meta);
    } finally {
      setProductsMetaLoading(false);
    }
  };

  const fetchProductCode = async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(ApiRoutes.PRODUCTS_CODE);

      const product = deserialize(Product, data);

      setProduct(product);
    } finally {
      setLoading(false);
    }
  };
  const fetchMaxValuesMeta = async () => {
    const { data } = await axiosInstance.get(ApiRoutes.PRODUCT_MAX_VALUES);
    const maxVals = deserialize(MaxValuesMeta, data);
    setMaxValuesMeta(maxVals);
    return maxVals;
  };

  return {
    loading,
    product,
    products,
    productLoading,
    createProduct,
    fetchProducts,
    fetchProduct,
    updateProduct,
    deleteProduct,
    fetchFilters,
    filters,
    params,
    setParams,
    meta,
    filtersLoading,
    productsMeta,
    productsMetaLoading,
    fetchProductsMeta,
    fetchProductCode,
    maxValuesMeta,
    fetchMaxValuesMeta,
  };
};

export default ProductService;
