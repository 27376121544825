import * as Yup from "yup";

const getObjectIdSchema = (required = true, requiredMsg?: string) =>
  Yup.object().shape({
    id: Yup.string()[required ? "required" : "notRequired"](
      "Image is required",
    ),
  });

export default getObjectIdSchema;
