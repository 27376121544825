import { LocationError } from "shared/constants/location";
import getObjectIdSchema from "shared/utils/getObjectIdValidation";
import * as Yup from "yup";

export const getLocationValidation = (isProvinceRequired = true) =>
  Yup.object()
    .shape({
      country: getObjectIdSchema(false).notRequired(),
      province: getObjectIdSchema(
        isProvinceRequired,
        LocationError.PROVINCE_REQUIRED,
      )[isProvinceRequired ? "required" : "notRequired"](),
    })
    .notRequired();
