import React, { useState } from "react";
import { DragDrop, FileInput } from "@uppy/react";
import { DragDropProps } from "@uppy/react/src/DragDrop";
import getUppyInstance from "shared/utils/getUppyInstance";
import { useUppyMultipartUpload } from "shared/hooks/useUppyMultipartUpload";
import { Attachment } from "models/Attachment/attachment.model";
import { useField } from "formik";
import Error from "shared/components/Error";
import { WithFormikProps } from "shared/types/formikFieldProps.type";
import { Restrictions, UppyOptions } from "@uppy/core";
import Label from "../Label";
import { FileInputProps } from "@uppy/react/src/FileInput";
import loaderAnimation from "assets/json/dot-loader.json";

import "@uppy/core/dist/style.min.css";
// import "@uppy/dashboard/dist/style.min.css";
import "@uppy/drag-drop/dist/style.min.css";
import "@uppy/status-bar/dist/style.min.css";
import "./uploadInput.scss";
import Lottie from "react-lottie";
import useToggle from "shared/hooks/useToggle";

interface UploadInputProps extends Partial<DragDropProps> {
  title?: string;
  onAttachmentChange?: (attachments: Attachment[]) => void;
  hideStatusBar?: boolean;
  options?: UppyOptions;
  label?: string;
  fileInputProps?: Omit<FileInputProps, "uppy">;
  validateAspectRatio?: boolean;
  primaryTheme?: boolean;
  hideLoaderOnComplete?: boolean;
}

const defaultUppyRestrictions: Restrictions = {
  allowedFileTypes: [".jpeg", ".png", ".jpg"],
  maxNumberOfFiles: 1,
  maxFileSize: 10 * 1024 * 1024, // 10MB
};

const UploadInput = ({
  title,
  onAttachmentChange,
  width,
  height,
  hideStatusBar,
  label,
  options: { restrictions, ...options } = {},
  fileInputProps,
  validateAspectRatio,
  primaryTheme,
  hideLoaderOnComplete,
  ...props
}: UploadInputProps) => {
  const { isToggled: inProgress, setToggle: setInProgress } = useToggle(false);
  const [uppy] = useState(() =>
    getUppyInstance({
      restrictions: {
        ...defaultUppyRestrictions,
        ...restrictions,
      },
      ...options,
    }),
  );

  const locale = {
    strings: {
      dropHereOr: title || "",
    },
  };

  uppy.on("upload-progress", () => {
    if (!inProgress) {
      setInProgress(true);
    }
  });
  uppy.on("file-added", () => {
    if (!inProgress) {
      setInProgress(true);
    }
  });
  uppy.on("complete", () => {
    if (hideLoaderOnComplete) {
      setInProgress(false);
    }
  });
  uppy.on("restriction-failed", () => {
    setInProgress(false);
    uppy.cancelAll();
  });
  uppy.once("upload-error", () => {
    setInProgress(false);
    uppy.cancelAll();
  });

  useUppyMultipartUpload(uppy, onAttachmentChange, validateAspectRatio);

  return (
    <div
      className={"upload-input".concat(
        primaryTheme ? " upload-input--primary" : "",
      )}
    >
      <Label label={label} />
      <div className="upload-input__wrapper">
        {inProgress && (
          <div className="attachment-upload-loader">
            <Lottie
              isClickToPauseDisabled
              options={{
                animationData: loaderAnimation,
                ...options,
              }}
              {...props}
            />
          </div>
        )}
        {fileInputProps ? (
          <FileInput uppy={uppy} {...fileInputProps} />
        ) : (
          <DragDrop
            uppy={uppy}
            width={width}
            height={height}
            // locale={{
            //   strings: {
            //     dropHereOr: title,
            //   },
            // }}
            locale={locale}
            {...props}
          />
        )}
      </div>
    </div>
  );
};

const UploadInputWithFormik = ({
  name,
  ...props
}: WithFormikProps<UploadInputProps>) => {
  const [{ value }, { error, touched }, { setValue }] = useField<
    Attachment | Attachment[]
  >(name);

  const message = (error as Attachment)?.id;

  const handleAttachmentChange: UploadInputProps["onAttachmentChange"] = (
    attachment,
  ) => {
    const isMultipleAttachment = Array.isArray(value);

    setValue(
      isMultipleAttachment ? [...value, ...attachment] : attachment?.[0],
    );
  };
  return (
    <div>
      <UploadInput onAttachmentChange={handleAttachmentChange} {...props} />
      {message && touched && <Error message={message} />}
    </div>
  );
};

UploadInput.Formik = UploadInputWithFormik;

export default UploadInput;
