import { useSelectedPlan } from "context/SelectedPlanContext";
import { useSelectedZone } from "context/SelectedZoneContext";
import { Coordinate } from "models/Coordinate/coordinate.model";
import { Plan } from "models/Plans/plans.model";
import { Space } from "models/Space/space.model";
import React, { FC, createContext, useContext, useRef, useState } from "react";
import { ReactZoomPanPinchContentRef } from "react-zoom-pan-pinch";
import SpaceService from "services/SpaceService/space.service";
import { selectedSpaceType } from "shared/components/PrototypeCombined";

interface SelectedSpaceType {
  selectedSpace?: selectedSpaceType;
  handleSetSelectedSpace?: (space?: selectedSpaceType) => void;
  handleUpdateSpacePin?: (
    coordinate: Coordinate,
    pin: "add" | "remove",
    selectedZoneId?: string,
  ) => void;
  updatingPin?: boolean;
  removePin?: (spaceId: string) => void;
  updatePlanDetails?: (plan: Plan) => void;
  ref?: React.RefObject<ReactZoomPanPinchContentRef>;
}
// create context
export const SelectedSpaceContext = createContext<SelectedSpaceType>({});

const updatePlanZoneSpaces = (updatedSpace: Space, plan: Plan) => {
  const zoneCopy = [...plan.zones];
  for (let i = 0; i < zoneCopy.length; i++) {
    if (zoneCopy[i]?.spaces) {
      const spaceIndex = zoneCopy[i].spaces?.findIndex(
        (space) => space.id === updatedSpace.id,
      );

      if (spaceIndex !== undefined && spaceIndex !== -1) {
        (zoneCopy[i].spaces as Space[])[spaceIndex] = updatedSpace;
        break;
      }
    }
  }
  return {
    ...plan,
    zones: zoneCopy,
  };
};

//create context provider
const SelectedSpaceState: FC<{ viewId?: string }> = ({ children, viewId }) => {
  const [selectedSpace, setSelectedSpace] = useState<selectedSpaceType>();
  const { selectedPlanId, plan, updatePlanDetails } = useSelectedPlan();

  const { selectedZoneId } = useSelectedZone();

  const handleSetSelectedSpace = (space?: selectedSpaceType) => {
    setSelectedSpace(space);
  };
  const ref = useRef<ReactZoomPanPinchContentRef>(null);

  const { updateSpacePin, updatingPin, removeSpacePin } = SpaceService();

  const handleUpdateSpacePin = async (
    coordinate: Coordinate,
    pin: "add" | "remove",
    selectedZoneId?: string,
  ) => {
    if (viewId && selectedZoneId && selectedSpace && selectedPlanId) {
      const updatedSpace = await updateSpacePin(
        viewId,
        selectedPlanId,
        selectedZoneId,
        selectedSpace,
        coordinate,
        pin,
      );

      if (plan && plan?.zones && updatedSpace) {
        const updatedPlan = updatePlanZoneSpaces(updatedSpace, plan);

        updatePlanDetails?.(updatedPlan);
      }
    }
  };

  const removePin = async (spaceId: string) => {
    if (viewId && selectedZoneId && spaceId && selectedPlanId) {
      const updatedSpace = await removeSpacePin(
        viewId,
        selectedPlanId,
        selectedZoneId,
        spaceId,
        { x: 0, y: 0 },
        "remove",
      );

      if (plan && plan?.zones && updatedSpace) {
        const updatedPlan = updatePlanZoneSpaces(updatedSpace, plan);

        updatePlanDetails?.(updatedPlan);
      }
    }
  };
  return (
    <SelectedSpaceContext.Provider
      value={{
        selectedSpace,
        handleSetSelectedSpace,
        handleUpdateSpacePin,
        updatingPin,
        removePin,
        ref,
      }}
    >
      {children}
    </SelectedSpaceContext.Provider>
  );
};

//use context
export const useSelectedSpace = () => {
  const context = useContext<SelectedSpaceType>(SelectedSpaceContext);
  if (!context) {
    throw new Error(
      "selected space context must be used within selected space provider",
    );
  }
  return { ...context };
};

export default SelectedSpaceState;
