import { Category } from "models/Category/category.model";
import { SubCategory } from "models/SubCategory/subCategory.model";
import { Type } from "models/Type/type.model";
import { useContext, createContext } from "react";

export interface ProductFiltersState {
  categories: Category[];
  subCategories: SubCategory[];
  types: Type[];
}

const initialValues: ProductFiltersState = {
  categories: [],
  subCategories: [],
  types: [],
};

export const ProductFiltersContext =
  createContext<ProductFiltersState>(initialValues);

export const useProductFilters = () => {
  const context = useContext<ProductFiltersState>(ProductFiltersContext);

  if (!context)
    throw new Error(
      "ProductFiltersContext must be used within a ProductFiltersContextProvider",
    );

  return {
    ...context,
  };
};
