import React, { FC, createContext, useContext, useState } from "react";

interface CanAddPinType {
  canAddPin: boolean;
  handleSetAddPin?: (val: boolean) => void;
  detailsToBePinned?: { id: string; name: string };
  handleSetAddPinningDetails?: (id: string, name: string) => void;
}

const initialValues = {
  canAddPin: false,
};

// create context
export const CanAddPinContext = createContext<CanAddPinType>(initialValues);

//create context provider
const CanAddPinState: FC = ({ children }) => {
  const [canAddPin, setCanAddPin] = useState<boolean>(false);
  const [detailsToBePinned, setDetailsToBePinned] = useState<{
    id: string;
    name: string;
  }>();
  const handleSetAddPin = (val: boolean) => {
    setCanAddPin(val);
  };
  const handleSetAddPinningDetails = (id: string, name: string) => {
    setDetailsToBePinned({ id, name });
  };
  return (
    <CanAddPinContext.Provider
      value={{
        canAddPin,
        handleSetAddPin,
        detailsToBePinned,
        handleSetAddPinningDetails,
      }}
    >
      {children}
    </CanAddPinContext.Provider>
  );
};

//using context
export const useCanAddPin = () => {
  const context = useContext<CanAddPinType>(CanAddPinContext);
  if (!context) {
    throw new Error(
      "CanAddPinContext must be used within a CanAddPinContextProvider",
    );
  }
  return { ...context };
};

export default CanAddPinState;
