import React, { FC } from "react";
import { Product } from "models/Product/product.model";
import Collapse from "shared/components/Collapse";
import { ViewDescription } from "shared/icons";

interface ProductDescriptionProps {
  product: Product;
}

const ProductDescription: FC<ProductDescriptionProps> = (
  props: ProductDescriptionProps,
) => {
  const prodDescription = props.product.description;
  const items = [
    {
      id: 1,
      prodDescription,
      getHeader: () => (
        <div className="collapse-icon-header">
          <ViewDescription className="icon-grey-3 icon--view-description" />
          <span>Description</span>
        </div>
      ),
    },
  ];

  return (
    <div className="product-description">
      <Collapse defaultActiveKey={items?.[0]?.id} list={items}>
        {({ prodDescription }) => <p>{prodDescription}</p>}
      </Collapse>
    </div>
  );
};

export default ProductDescription;
