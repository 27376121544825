import axios from "axios";
import { ApiRoutes } from "../routes/routeConstants/apiRoutes";
import { Response } from "../shared/types/response.type";
import { NavigationRoutes } from "routes/routeConstants/appRoutes";
import { LocalStorageKey } from "enums/localStorageKey";
import Notification from "shared/components/Notification";
import { NotificationTypes } from "enums/notificationTypes";
import { TokenType } from "enums/tokenType";
import { deserialize } from "serializr";
import { User } from "models/user.model";
import { Token } from "models/Token/token.model";
import { getItem, setItem } from "shared/utils/localStorage";
import qs from "qs";

interface RequestQueue {
  reject: (err: Error) => void;
  resolve: (token: string) => void;
}

let isTokenValid = true;

const queuedRequests: RequestQueue[] = [];

const processQueue = (error?: Error, token = "") => {
  queuedRequests.forEach(({ resolve, reject }) =>
    error ? reject(error) : resolve(token),
  );
  queuedRequests.length = 0;
};

export const getToken = () => {
  const token = getItem<Token>(LocalStorageKey.TOKEN, true);

  return `Bearer ${token?.accessToken}`;
};

export const getHeaders = () => ({
  "Content-Type": "application/json",
  Authorization: getToken(),
});

const axiosInstance = axios.create({
  baseURL: ApiRoutes.BASE_URL,
  timeout: 20000,
  paramsSerializer: (p) => qs.stringify(p),
});

axiosInstance.interceptors.request.use(function (config) {
  config.headers = getHeaders();
  return config;
});

axiosInstance.interceptors.response.use(
  (response): Response => {
    return {
      message: response.statusText,
      ...response,
    };
  },
  (error) => {
    const { config: originalRequest, response, _retry } = error;

    const logout = () => {
      localStorage.clear();
      window.location.replace(NavigationRoutes.LOGIN);
    };

    if (response?.status === 401) {
      if (_retry) return logout();

      if (!isTokenValid)
        return new Promise((resolve, reject) => {
          queuedRequests.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = `bearer ${token}`;
            return axiosInstance.request(originalRequest);
          })
          .catch((ex) => Promise.reject(ex));

      originalRequest._retry = true;

      isTokenValid = false;

      const refreshToken = getItem<Token>(
        LocalStorageKey.TOKEN,
        true,
      )?.refreshToken;

      const payload = {
        user: {
          token_type: TokenType.REFRESH,
          refresh_token: refreshToken,
        },
      };

      return axiosInstance
        .post<{ user: User; token: Token }>(ApiRoutes.USER_LOGIN, payload)
        .then(({ data, status }) => {
          if (status >= 200 && status <= 299) {
            const user = deserialize(User, data["user"]);

            const token = deserialize(Token, data["token"]);

            setItem(LocalStorageKey.USER, user);

            setItem(LocalStorageKey.TOKEN, token);

            window.dispatchEvent(
              new StorageEvent("storage", {
                key: "USER",
                newValue: JSON.stringify({
                  admin: user,
                  token: token,
                }),
              }),
            );

            originalRequest.headers["Authorization"] =
              "bearer " + token?.accessToken;
            processQueue(undefined, token?.accessToken);
            return axiosInstance.request(originalRequest);
          }
        })
        .catch((ex) => {
          processQueue(ex);
          logout();
        })
        .finally(() => {
          isTokenValid = true;
        });
    } else if (response?.status === 500) {
      Notification({
        message: "Something went wrong.",
        type: NotificationTypes.ERROR,
      });
    } else if (response?.status === 403) {
      Notification({
        message: response.message ?? "You don't have relevant Access",
        type: NotificationTypes.ERROR,
      });
    } else {
      Notification({
        message: response?.data?.message,
        description: "Please try again!",
        type: NotificationTypes.ERROR,
      });
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
