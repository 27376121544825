import React, { useState } from "react";
import ProductFormContentLeft from "./ProductFormContentLeft";
import ProductFormContentRight from "./ProductFormContentRight";
import MultipleVariant from "./MultipleVariant";
import { SubCategory } from "models/SubCategory/subCategory.model";
import ProductAttachments from "./ProductAttachments";
import { useFormikContext } from "formik";
import { Product } from "models/Product/product.model";

import "./productFormContent.scss";
import { parseNumber } from "shared/utils/parser";
import ErrorMessage from "shared/components/Error";

const ProductFormContent = ({
  selectedCategoryId,
  selectedSubCategoryId,
  selectedTypeId,
  notEdit,
}: any) => {
  const [subCategories, setSubCategories] = useState<SubCategory[]>([]);

  const { values, errors, touched } = useFormikContext<Product>();

  const attachmentCount =
    parseNumber(!!values?.threeDimensionModel?.id) +
    values?.attachments?.length;
  return (
    <div className="product-form-content">
      <div className="product-form-content__main">
        <ProductFormContentLeft />
        <ProductFormContentRight
          onSubCategoriesUpdate={setSubCategories}
          selectedCategoryId={selectedCategoryId}
          selectedSubCategoryId={selectedSubCategoryId}
          selectedTypeId={selectedTypeId}
          notEdit={notEdit}
        />
      </div>
      <div className="product-form-content__variants">
        <div className="product-form-content__variants__title">Variants</div>
        <MultipleVariant subCategories={subCategories} />
        {touched?.variants && errors?.variants && (
          <ErrorMessage message="Variant is required" />
        )}
      </div>
      <div className="product-form-content__attachments">
        <div className="product-form-content__attachments__title">
          Attachments {attachmentCount ? <>({attachmentCount})</> : <></>}
        </div>
        <ProductAttachments />
      </div>
    </div>
  );
};

export default ProductFormContent;
