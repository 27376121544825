import { useState } from "react";
import { deserialize, serialize } from "serializr";
import { generatePath } from "react-router-dom";
import axiosInstance from "../../interceptor/axiosInstance";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import {
  Attachment,
  AttachmentPresignedUrl,
} from "../../models/Attachment/attachment.model";
import Axios from "axios";
import { AttachmentFormat } from "enums/attachmentFormat";
import { CLOUD_PROVIDER } from "shared/constants";

const AttachmentService = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const [attachment, setAttachment] = useState<Attachment>();

  const [attachments, setAttachments] = useState<Attachment[]>([]);

  const [attachmentPreSignedUrl, setAttachmentPreSignedUrl] =
    useState<AttachmentPresignedUrl>();

  const [isUploading, setIsUploading] = useState(false);

  const [isAttachmentAdding, setIsAttachmentAdding] = useState(false);

  const generatePresignedUrl = async (file: File) => {
    try {
      setLoading(true);

      const payload = {
        attachment: serialize(Attachment, {
          format: file.type || AttachmentFormat.JPEG,
          name: file.name,
        }),
      };

      const { data } = await axiosInstance.post(
        ApiRoutes.ATTACHMENT_PRESIGNED_URL,
        payload,
      );

      const attachmentPreSignedUrl = deserialize(
        AttachmentPresignedUrl,
        data["attachment"],
      );

      setAttachmentPreSignedUrl(attachmentPreSignedUrl);

      return attachmentPreSignedUrl;
    } finally {
      setLoading(false);
    }
  };

  const uploadToS3 = async (attachment: Attachment, file: File) => {
    try {
      if (!attachment.url) return;

      setIsUploading(true);

      const { data } = await Axios.put(attachment.url, file);

      return data;
    } finally {
      setIsUploading(false);
    }
  };

  const addAttachment = async (attachment: Attachment) => {
    try {
      setLoading(true);

      const response = await axiosInstance.post(ApiRoutes.ATTACHMENTS, {
        attachment: serialize(Attachment, attachment),
      });

      const data = deserialize(Attachment, response.data["attachment"]);

      setAttachment(data);

      setAttachments((attachments) => [...attachments, data]);

      return data;
    } finally {
      setLoading(false);
    }
  };

  const uploadAttachment = async (
    file: File,
    key?: string,
    format?: string,
  ) => {
    try {
      setIsAttachmentAdding(true);

      let fileKey = key;
      let fileFormat = format;
      if (CLOUD_PROVIDER === "aws") {
        const attachment = await generatePresignedUrl(file);

        await uploadToS3(attachment, file);

        fileKey = attachment.key;
        fileFormat = attachment.format;
      }

      const newAttachment = await addAttachment({
        s3Key: fileKey,
        size: file.size,
        name: file.name,
        format: fileFormat,
      });

      return newAttachment;
    } catch (ex) {
      setIsAttachmentAdding(false);
    }
  };

  const deleteAttachment = async (attachmentId: string) => {
    try {
      setLoading(true);
      const API_URL = generatePath(ApiRoutes.ATTACHMENTS_SPECIFIC, {
        id: attachmentId,
      });
      await axiosInstance.delete(API_URL);
      setAttachment(undefined);
      setAttachments((attachments) =>
        attachments?.filter(({ id }) => id !== attachmentId),
      );
      return true;
    } catch (error) {
      return false;
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    attachment,
    attachmentPreSignedUrl,
    addAttachment,
    deleteAttachment,
    generatePresignedUrl,
    attachments,
    setAttachment,
    isUploading,
    uploadAttachment,
    isAttachmentAdding,
  };
};

export default AttachmentService;
