import React, { useEffect } from "react";

import "./notificationPage.scss";
import ActivityLog from "shared/components/PrototypeCombined/Activity";
import { Edit } from "shared/icons";
import useRedirect from "shared/hooks/useRedirect";
import PrototypeService from "services/PrototypeService/prototype.service";
import Spinner from "shared/components/Spinner";
import { useQuery } from "shared/hooks/useNetwork";

function AllNotifications() {
  return (
    <div className="notification-container tw-m-auto">
      <div className="notification-page__heading-container">
        <h1 className="heading tw-tracking-wide">All Activities</h1>
      </div>
      <div className="tw-mx-auto tw-max-w-7xl tw-mt-16">
        <ActivityLog />
      </div>
    </div>
  );
}

function PrototypeNotifications(props: { prototypeId: string }) {
  const { prototypeId } = props;
  const { prototypeLoading, showPrototype, prototype } = PrototypeService();
  const { redirectToUpdatePrototype } = useRedirect();

  useEffect(() => {
    showPrototype(prototypeId);
  }, []);

  return (
    <div className="notification-container tw-m-auto">
      <Spinner loading={prototypeLoading}>
        <div className="notification-page__heading-container tw-items-center tw-justify-between">
          <h1 className="heading tw-tracking-wide tw-ml-16">
            {prototype.title}
          </h1>
          <div
            className="notification-container__edit-icon"
            onClick={() => {
              redirectToUpdatePrototype(prototypeId ?? "", true);
            }}
          >
            <Edit className="icon--edit tw-w-12 tw-h-12" />
          </div>
        </div>
        <div className="tw-mx-auto tw-max-w-7xl tw-mt-16">
          <ActivityLog prototypeId={prototypeId} />
        </div>
      </Spinner>
    </div>
  );
}

const Notifications = () => {
  const query = useQuery();
  const prototypeId = query.get("prototypeId");

  return prototypeId ? (
    <PrototypeNotifications prototypeId={prototypeId} />
  ) : (
    <AllNotifications />
  );
};

export default Notifications;
