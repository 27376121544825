import React, { useEffect, useMemo, useState } from "react";
import ProductService from "services/ProductService/product.service";
import { ProductParams } from "models/Product/product.model";
import "./products.scss";
import CategoryNavbar from "./CategoryNavbar";
import ProductGridView from "./ProductGridView";
import SearchAndFilter from "./SearchAndFilter";
import QueryString from "qs";
import { useLocation, useNavigate } from "react-router-dom";
import FilteredProducts from "./FilteredProducts";
import FilterToggleState from "context/FilterToggleContext";
import { ProductFiltersContext } from "context/ProductFilterContext";
import PicklistToggleState from "context/PicklistToggleContext";
import PicklistDrawer from "shared/components/PicklistDrawer";

const Products = () => {
  const { filters, fetchFilters, filtersLoading } = ProductService();

  const location = useLocation();
  const parsedQuery = useMemo(
    () =>
      QueryString.parse(location.search, {
        ignoreQueryPrefix: true,
      }) as unknown as ProductParams,
    [location],
  );

  const { fetchProducts, params, setParams } = ProductService();
  const noFilter = () => !parsedQuery || !Object.keys(parsedQuery).length;

  const navigate = useNavigate();
  const handleSearch = (search = "", searchCategory: string) =>
    navigate({
      pathname: location.pathname,
      hash: location.hash,
      search: QueryString.stringify(
        { search, searchCategory },
        {
          skipNulls: true,
        },
      ),
    });

  useEffect(() => {
    fetchProducts(params);
  }, [params]);
  const { categories, subCategories, types } = filters;
  useEffect(() => {
    fetchFilters();
  }, []);
  return (
    <ProductFiltersContext.Provider
      value={{
        categories,
        subCategories,
        types,
      }}
    >
      <FilterToggleState>
        <PicklistToggleState>
          <PicklistDrawer />
          {noFilter() ? (
            <>
              <CategoryNavbar setParams={setParams} />
              <SearchAndFilter
                filters={filters}
                params={params}
                setParams={setParams}
                onSearch={handleSearch}
                filterParams={parsedQuery}
                search={params?.search}
                isFixed
              />
            </>
          ) : (
            <></>
          )}
          {noFilter() ? (
            <ProductGridView
              filterLoading={filtersLoading}
              filterMeta={filters}
              setParams={setParams}
            />
          ) : (
            parsedQuery && (
              <FilteredProducts
                filterParams={parsedQuery}
                filterMeta={filters}
                filterLoading={filtersLoading}
                params={params}
                setParams={setParams}
                onSearch={handleSearch}
              />
            )
          )}
        </PicklistToggleState>
      </FilterToggleState>
    </ProductFiltersContext.Provider>
  );
};

export default Products;
