import { serializable, alias, object, primitive, custom } from "serializr";
import { Color } from "../Color/color.model";
import { CostPerItem } from "../CostPerItem/costPerItem.model";
import { Location } from "models/Location/location.model";
import { Attachment } from "models/Attachment/attachment.model";
import { Product } from "models/Product/product.model";
import { Material } from "models/Material/material.model";
import { Finish } from "models/Finish/finish.model";
import { CurrencyUnit } from "enums/currencyUnit.enum";

export class Variant {
  constructor(id?: string) {
    this.id = id;
    this.name = "Variant 1"; // Add default name
  }

  @serializable(
    custom(
      (id) => (id.includes("-") ? undefined : id),
      (id) => id
    )
  )
  id?: string;

  @serializable
  count?: number;

  @serializable
  name?: string;

  @serializable(object(Material))
  material?: Material;

  @serializable(object(Finish))
  finish?: Finish;

  @serializable(object(CostPerItem))
  cost?: CostPerItem = new CostPerItem(CurrencyUnit.CAD, 0);

  @serializable(object(Color))
  color?: Color;

  @serializable(object(Attachment))
  image?: Attachment;

  @serializable(object(Location))
  location?: Location;

  @serializable(alias("lead_time", primitive()))
  leadTime?: number;

  @serializable(alias("is_caster", primitive()))
  isCaster?: string;

  @serializable(alias("light_type", primitive()))
  lightType?: string;

  @serializable(alias("color_temperature", primitive()))
  colorTemperature?: string;

  static populateVariantFromProduct = (
    variant: Variant,
    product: Product
  ): Variant =>
    Object.assign(variant, {
      ...variant,
      name: product?.name,
      image: product?.image,
      leadTime: product?.inventory?.leadTime,
      location: product?.inventory?.location,
      cost: product?.inventory?.costPerItem,
      count: product?.inventory?.availableCount,
    });
}
