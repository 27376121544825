import { PrototypeError } from "shared/constants/prototype";
import * as Yup from "yup";

export default Yup.object().shape({
  title: Yup.string()
    .required(PrototypeError.TITLE_REQUIRED)
    .max(120, PrototypeError.TITLE_LENGTH)
    .trim()
    .strict(true),
  description: Yup.string()
    .required(PrototypeError.DESCRIPTION_REQUIRED)
    .trim()
    .strict(true),
});
