import React from "react";
import { TabsProps } from "antd";
import CategoryConfiguration from "./CategoryConfiguration";
import UserRoleManagement from "views/UserRoleManagement";
import ClientSettings from "./ClientSettings";
import { Module } from "enums/module.enum";
import SpaceConfiguration from "./SpaceConfiguration";

const tabItems: TabsProps["items"] = [
  {
    key: Module.CATEGORY_CONFIGURATION,
    label: "Product Configuration",
    children: <CategoryConfiguration />,
  },
  {
    key: Module.SPACE_CONFIGURATION,
    label: "Space Configuration",
    children: <SpaceConfiguration />,
  },
  {
    key: Module.USER_ACCESS_AND_LIMIT,
    label: "User Limit & Access",
    children: <UserRoleManagement />,
  },
  {
    key: Module.CLIENT_SETTINGS,
    label: "Client Settings",
    children: <ClientSettings />,
  },
];

export default tabItems;
