import React, { FC } from "react";
import { useEffect, useRef, useState } from "react";
import "./diagramView.scss";
import ImageMarker from "../../ImageMarker";
import {
  ReactZoomPanPinchContentRef,
  TransformComponent,
  TransformWrapper,
} from "react-zoom-pan-pinch";
import { useCanAddPin } from "context/CanAddPinContext";
import { usePinMarkers } from "context/PinMarkersContext";
import { DiagramView as DiagramViewEnum } from "enums/diagramView";
import {
  ZoomInOutlined,
  ZoomOutOutlined,
  RedoOutlined,
} from "@ant-design/icons";
import { useSelectedSpace } from "context/SelectedSpaceContext";
interface DiagramViewType {
  imgUrl: string;
  closeIcon?: React.ReactElement;
  viewType: DiagramViewEnum.PROTOTYPE | DiagramViewEnum.SPACE;
}

const DiagramView: FC<DiagramViewType> = ({ imgUrl, viewType, closeIcon }) => {
  const { canAddPin } = useCanAddPin();
  const { pinMarkers, addPinMarker } = usePinMarkers();

  const [className, setClassName] = useState("");

  const handleEsc = (evt: { key: string }) => {
    if (evt.key === "Escape") setClassName("");
  };
  useEffect(() => {
    window.addEventListener("keydown", handleEsc);

    return () => window.removeEventListener("keydown", handleEsc);
  }, []);
  const selectedSpaceContext = useSelectedSpace();

  return (
    <div className={`diagram-view-outer cursor-${viewType}`}>
      <div
        className={`diagram-view ${className}  ${
          canAddPin && "black-overlay"
        }  ${canAddPin && "diagram-view-pin-cursor"}`}
      >
        <TransformWrapper
          ref={selectedSpaceContext.ref}
          disablePadding
          centerZoomedOut
          initialScale={1}
          initialPositionY={0}
        >
          {({ zoomIn, zoomOut, resetTransform }) => (
            <React.Fragment>
              {closeIcon}
              {
                <div className="dual-view-icons__icon-gp__zoom_icon">
                  <ZoomInOutlined
                    className="icon"
                    onClick={() => {
                      zoomIn();
                    }}
                  />

                  <ZoomOutOutlined
                    className="icon"
                    onClick={() => {
                      zoomOut();
                    }}
                  />
                  <RedoOutlined
                    className="icon"
                    onClick={() => {
                      resetTransform();
                    }}
                  />
                </div>
              }
              <TransformComponent contentClass="pointer-all">
                {/* <Controls {...props} /> */}
                <ImageMarker
                  markers={pinMarkers ?? []}
                  viewType={viewType}
                  src={imgUrl}
                  onAddMarker={(marker) => canAddPin && addPinMarker?.(marker)}
                  extraClass={canAddPin ? "black-overlay-img" : ""}
                />
              </TransformComponent>{" "}
            </React.Fragment>
          )}
        </TransformWrapper>
      </div>
      <div className={`${canAddPin ? "text-overlay" : "display-none"}`}>
        Place the pin here or press esc to cancel
      </div>
      <div
        className={`${canAddPin ? "background-overlay" : "display-none"}`}
      ></div>
    </div>
  );
};

export default DiagramView;
