import React, { useCallback, useState } from "react";
import { Button, Popover, Tooltip } from "antd";
import { Add } from "shared/icons";
import { Form, Formik } from "formik";
import Input from "../../Input";
import { ButtonType } from "enums/buttonType.enum";
import { Picklist } from "models/Picklist";
import { createPicklistValidationSchema } from "../picklistDrawer.validation";

interface AddPicklistPopoverProps {
  createPicklistHandle: (data: Picklist) => void;
}

const formInitialValues: Picklist = {
  name: "",
};

const AddPicklistPopover = ({
  createPicklistHandle,
}: AddPicklistPopoverProps) => {
  const [addPopover, setAddPopover] = useState(false);

  const handleSubmit = (values: Picklist) => {
    try {
      createPicklistHandle(values);
      setAddPopover(false);
    } catch (err) {
      console.log(err);
    }
  };

  const onOpenChangeHandle = useCallback(() => {
    setAddPopover(!addPopover);
  }, [addPopover]);

  return (
    <>
      <p>Select Picklist</p>
      <Tooltip title="New picklist">
        <Popover
          open={addPopover}
          trigger="click"
          placement="bottom"
          onOpenChange={onOpenChangeHandle}
          content={
            <Formik
              initialValues={formInitialValues}
              onSubmit={handleSubmit}
              validationSchema={createPicklistValidationSchema}
            >
              {() => (
                <Form className="add-item__form">
                  <div className="add-item__form__label">Picklist</div>
                  <div className="display-flex">
                    <Input.Formik name="name" placeholder="Enter" />
                  </div>
                  <div className="add-item__form__buttons">
                    <Button
                      type={ButtonType.TEXT}
                      htmlType="reset"
                      onClick={() => {
                        setAddPopover(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button type={ButtonType.LINK} htmlType="submit">
                      Add
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          }
        >
          <Add className="icon-primary config-item-form__add" />
        </Popover>
      </Tooltip>
    </>
  );
};

export default AddPicklistPopover;
