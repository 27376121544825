import React, { useEffect, useState } from "react";
import { Popover, Space } from "antd";
import { ButtonType } from "enums/buttonType.enum";
import Button from "shared/components/Button";
import {
  Add,
  Close,
  Delete,
  DownArrow,
  Edit,
  Export,
  Lock,
  More,
  Search,
} from "shared/icons";
import Select, { BaseOptionType, DefaultOptionType } from "antd/es/select";
import { Picklist as PicklistModel } from "models/Picklist";
import { createPicklistValidationSchema } from "shared/components/PicklistDrawer/picklistDrawer.validation";
import { Formik, Form, Field } from "formik";
import Input from "shared/components/Input";
import Switch from "shared/components/Switch";
import useToggle from "shared/hooks/useToggle";
import DeleteModal from "shared/components/DeleteModal";
import { picklistStatus } from "enums/picklistStatus.enum";
import "../picklist.scss";
import { PickListStatus } from "enums/picklist";
import { userHasAccess } from "utils";
import { Module } from "enums/module.enum";
import { Method } from "enums/method.enum";

interface DropdownOptionType extends DefaultOptionType {
  picklist: PicklistModel;
}

interface PicklistButtonsProps {
  picklists: PicklistModel[] | undefined;
  selectedPicklist: PicklistModel | undefined;
  setSelectedPicklist: React.Dispatch<
    React.SetStateAction<PicklistModel | undefined>
  >;
  createPicklist: (
    picklist: PicklistModel
  ) => Promise<PicklistModel | undefined>;
  updatePicklist: (
    id: string,
    picklist: PicklistModel
  ) => Promise<PicklistModel | undefined>;
  onSearchPicklists: (search: string) => void;
  clearPicklist: (id: string) => Promise<void | PicklistModel>;
  serviceLoading: boolean;
  exportPicklist: (picklistId: string) => Promise<Blob>;
  deletePicklist: (picklistId: string) => Promise<void>;
  fetchPicklists: (search?: string) => Promise<void>;
}

const PicklistTabButtons = ({
  picklists,
  selectedPicklist,
  setSelectedPicklist,
  createPicklist,
  updatePicklist,
  onSearchPicklists,
  clearPicklist,
  serviceLoading,
  exportPicklist,
  deletePicklist,
  fetchPicklists,
}: PicklistButtonsProps) => {
  const [addPopover, setAddPopover] = useState(false);
  const [editPopover, setEditPopover] = useState(false);
  const [optionsPopover, setOptionsPopover] = useState(false);
  const [search, setSearch] = useState("");

  const {
    isToggled: clearModalToggled,
    toggleOn: clearModalToggleOn,
    toggleOff: clearModalToggleOff,
  } = useToggle();

  const {
    isToggled: deleteModalToggled,
    toggleOn: deleteModalToggleOn,
    toggleOff: deleteModalToggleOff,
  } = useToggle();

  const createPicklistInitialValues: PicklistModel = { name: "" };

  const onCreatePicklist = async (values: PicklistModel) => {
    const newPicklist = await createPicklist(values);
    setSelectedPicklist(newPicklist);
  };

  const onUpdatePicklist = async (values: PicklistModel) => {
    const updatedPicklist = await updatePicklist(
      selectedPicklist?.id ?? "",
      values
    );
    setSelectedPicklist(updatedPicklist);
  };

  const onSearchSubmitHandle = (values: { search: string }) => {
    onSearchPicklists(values.search);
  };

  const onClearPicklistHandle = () => {
    clearPicklist(selectedPicklist?.id ?? "");
    clearModalToggleOff();
  };

  const onDeletePicklistHandle = () => {
    deletePicklist(selectedPicklist?.id ?? "");
    setSelectedPicklist(undefined);
    deleteModalToggleOff();
  };

  const onExportHandle = async (id: string | undefined) => {
    if (!id) {
      return;
    }
    const blob = await exportPicklist(id);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${id}.pdf`;
    link.click();
  };
  const { isToggled: open, setToggle } = useToggle();

  useEffect(() => {
    setAddPopover(false);
    setEditPopover(false);
    setOptionsPopover(false);
  }, [selectedPicklist, picklists]);

  return (
    <>
      <Space wrap className="picklist__header__selector">
        <>
          <Select
            onClick={() => {
              setSearch("");
              onSearchSubmitHandle({ search: "" });
            }}
            popupMatchSelectWidth={false}
            placeholder="Search and Select"
            defaultValue={selectedPicklist?.id}
            open={open}
            onDropdownVisibleChange={setToggle}
            suffixIcon={
              <DownArrow
                className={`suffix-icon${open ? "__open" : ""} ml-4`}
              />
            }
            value={selectedPicklist?.id}
            onChange={(_, option: BaseOptionType) => {
              setSelectedPicklist(
                picklists?.filter((picklist) => picklist.id === option.value)[0]
              );
            }}
            dropdownRender={(menu) => (
              <>
                <div className="picklist-tab__dropdown__search">
                  <Formik
                    initialValues={{ search: "" }}
                    onSubmit={() => onSearchSubmitHandle({ search: search })}
                  >
                    {({ handleSubmit }) => (
                      <Form className="search__bar">
                        <Field
                          as={Input.Search}
                          name="search"
                          value={search}
                          className="search-bar__field"
                          onClick={(event: { stopPropagation: () => void }) => {
                            event.stopPropagation();
                          }}
                          onChange={(event: {
                            target: { value: string | undefined };
                          }) => {
                            setSearch(event.target.value ?? "");
                            if (event.target.value === "")
                              onSearchSubmitHandle({
                                search: (event.target.value ?? "") as string,
                              });
                          }}
                          onPressEnter={handleSubmit}
                        />
                        <Button
                          className="search__button"
                          type={ButtonType.DEFAULT}
                          htmlType="submit"
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                        >
                          <Search className="cursor-pointer" />
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </div>
                <div className="picklist-tab__dropdown__menu">{menu}</div>
              </>
            )}
          >
            {picklists?.map((pick) => (
              <Select.Option value={pick.id} label={pick.name} key={pick.id}>
                <Space
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {pick.name}
                  {pick.status === PickListStatus.CLOSED && (
                    <div
                      className={`picklist__status__marker picklist__status__marker-${
                        pick?.status === PickListStatus.CLOSED
                      }`}
                    >
                      <div>
                        <Lock className="picklist__status__marker_icon" />
                      </div>{" "}
                      Closed
                    </div>
                  )}
                </Space>
              </Select.Option>
            ))}
          </Select>
        </>
      </Space>

      <div className="picklist__header__buttons">
        <Space>
          <Popover
            open={addPopover}
            trigger="click"
            placement="bottom"
            onOpenChange={() => {
              setAddPopover(!addPopover);
            }}
            content={
              <Formik
                initialValues={createPicklistInitialValues}
                onSubmit={onCreatePicklist}
                validationSchema={createPicklistValidationSchema}
              >
                {({ resetForm, values, isSubmitting }) => (
                  <Form className="add-item__form">
                    <div className="add-item__form__label">Picklist</div>
                    <div className="display-flex">
                      <Input.Formik name="name" placeholder="Enter" />
                    </div>
                    <div className="add-item__form__buttons">
                      <Button
                        type={ButtonType.TEXT}
                        htmlType="reset"
                        onClick={() => {
                          resetForm();
                          setAddPopover(false);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        loading={isSubmitting}
                        type={ButtonType.LINK}
                        onClick={() => {
                          onCreatePicklist(values);
                          setAddPopover(!addPopover);
                          resetForm();
                        }}
                      >
                        Add
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            }
          >
            {userHasAccess(Module.PICKLIST, Method.CREATE) && (
              <Button
                type={ButtonType.DEFAULT}
                className="picklist__header__buttons--button"
              >
                <Add /> New Picklist
              </Button>
            )}
          </Popover>
        </Space>
        {selectedPicklist && (
          <>
            <Space>
              <Button
                type={ButtonType.DEFAULT}
                className="picklist__header__buttons--button"
                onClick={() => {
                  onExportHandle(selectedPicklist?.id);
                }}
              >
                <Export /> Export Picklist
              </Button>
            </Space>
            <Space>
              {(userHasAccess(Module.PICKLIST, Method.EDIT) ||
                userHasAccess(Module.PICKLIST, Method.DELETE) ||
                editPopover) && (
                <Popover
                  open={optionsPopover}
                  trigger="click"
                  placement="bottom"
                  onOpenChange={() => {
                    setOptionsPopover(!optionsPopover);
                    setEditPopover(false);
                  }}
                  content={
                    <>
                      {editPopover ? (
                        <Formik
                          initialValues={{
                            name: selectedPicklist.name,
                            status: selectedPicklist.status,
                          }}
                          onSubmit={onUpdatePicklist}
                          enableReinitialize
                        >
                          {({ resetForm, values, setValues }) => {
                            return (
                              <>
                                <Form className="picklist__popover">
                                  <div className="display-flex">
                                    <Input.Formik
                                      name="name"
                                      placeholder="Enter"
                                      label="Picklist"
                                    />
                                  </div>
                                  <div className="display-flex edit__picklist--switch">
                                    <Switch.Formik
                                      id="picklist__popover__edit__switch__status"
                                      name="status"
                                      label="Status"
                                      unCheckedLabel="Closed"
                                      checkedLabel="Open"
                                      checked={
                                        values?.status === picklistStatus.OPEN
                                      }
                                      onChange={(checked) => {
                                        setValues({
                                          ...values,
                                          status: checked
                                            ? picklistStatus.OPEN
                                            : picklistStatus.CLOSED,
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="add-item__form__buttons">
                                    <Button
                                      type={ButtonType.TEXT}
                                      htmlType="reset"
                                      onClick={() => {
                                        resetForm();
                                        setEditPopover(false);
                                        setOptionsPopover(false);
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      type={ButtonType.LINK}
                                      htmlType="submit"
                                    >
                                      Update
                                    </Button>
                                  </div>
                                </Form>
                              </>
                            );
                          }}
                        </Formik>
                      ) : (
                        <>
                          <div className="picklist__popover__options">
                            {userHasAccess(Module.PICKLIST, Method.EDIT) && (
                              <Button
                                type={ButtonType.TEXT}
                                onClick={() => {
                                  setEditPopover(true);
                                }}
                              >
                                <Edit className="tw-w-8 tw-h-8" /> Edit Picklist
                              </Button>
                            )}
                            {userHasAccess(Module.PICKLIST, Method.EDIT) && (
                              <Button
                                type={ButtonType.TEXT}
                                disabled={
                                  selectedPicklist.status ===
                                  PickListStatus.CLOSED
                                }
                                onClick={() => {
                                  setOptionsPopover(false);
                                  clearModalToggleOn();
                                }}
                              >
                                <Close /> Clear Picklist
                              </Button>
                            )}
                            {userHasAccess(Module.PICKLIST, Method.DELETE) && (
                              <Button
                                type={ButtonType.TEXT}
                                onClick={() => {
                                  setOptionsPopover(false);
                                  deleteModalToggleOn();
                                }}
                              >
                                <Delete /> Delete Picklist
                              </Button>
                            )}
                          </div>
                        </>
                      )}
                    </>
                  }
                >
                  <Button
                    type={ButtonType.DEFAULT}
                    className="picklist__header__buttons--button button--options"
                  >
                    <More />
                  </Button>
                </Popover>
              )}
            </Space>
          </>
        )}
      </div>

      <DeleteModal
        loading={serviceLoading}
        hideTrash={true}
        title={"Are you sure to clear the picklist?"}
        subTitle={"Products added to the picklist will be removed"}
        deleteText="Clear"
        onDelete={() => {
          onClearPicklistHandle();
        }}
        onCancel={() => {
          clearModalToggleOff();
        }}
        open={clearModalToggled}
        width={456}
      />

      <DeleteModal
        loading={serviceLoading}
        title={"Are you sure to delete the picklist?"}
        subTitle={"Picklist will be deleted from system"}
        onDelete={() => {
          onDeletePicklistHandle();
        }}
        onCancel={() => {
          deleteModalToggleOff();
        }}
        open={deleteModalToggled}
      />
    </>
  );
};

export default PicklistTabButtons;
