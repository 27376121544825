import React from "react";
import ProductFormContentLeftHeader from "./ProductFormContentLeftHeader";
import Input from "shared/components/Input";
import ContainedTextArea from "shared/components/ContainedTextArea";
import { useField } from "formik";

import "./productFormContentLeft.scss";

const ProductFormContentLeft = () => {
  const [{ value: isSustainable }] = useField<boolean>(
    "sustainability.isSustainable",
  );

  return (
    <div className="product-form-content-left">
      <ProductFormContentLeftHeader />
      <Input.Formik className="product-form-content-left__name" name="name" />
      <ContainedTextArea.Formik
        name="description"
        label="Product description"
      />
      {isSustainable && (
        <ContainedTextArea.Formik
          name="sustainability.description"
          label="Sustainability description"
        />
      )}
    </div>
  );
};

export default ProductFormContentLeft;
