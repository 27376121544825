import { Country } from "models/Country/country.model";
import { useContext, createContext } from "react";

export interface LocationState {
  countries: Country[];
  loading?: boolean;
}

const initialValues: LocationState = {
  countries: [],
};

export const LocationContext = createContext<LocationState>(initialValues);

export const useLocation = () => {
  const context = useContext<LocationState>(LocationContext);

  if (!context)
    throw new Error(
      "CountryContext must be used within a CountryContextProvider",
    );

  return {
    ...context,
  };
};
