import { CategoryConfiguration } from "shared/constants/categoryConfiguration";
import getObjectIdSchema from "shared/utils/getObjectIdValidation";
import * as Yup from "yup";

export const productMappingValidation = Yup.object().shape({
  category: getObjectIdSchema(
    true,
    CategoryConfiguration.CATEGORY_REQUIRED,
  ).required("Category is required"),
  subCategory: getObjectIdSchema(
    true,
    CategoryConfiguration.SUB_CATEGORY_REQUIRED,
  ).required("Sub-category is required"),
  type: getObjectIdSchema(true, CategoryConfiguration.TYPE_REQUIRED).required(),
});
