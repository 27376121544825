import { Field, Action, ModuleInPrototype } from "enums/prototypeActivity";
import { Hierarchy } from "models/PrototypeActivity/prototypeActivity.model";
import UserRole from "models/UserRole/userRole.model";
import React from "react";
import {
  CreateActivity,
  DeleteActivity,
  DiagramUpdateActivity,
  GalleryImageAddActivity,
  GalleryImageDeleteActivity,
  PinRemoveActivity,
  PinUpdateActivity,
  UpdateActivity,
} from "shared/icons";

export type ImagePosition = {
  top: number;
  left: number;
  width: number;
  height: number;
};
export type MousePosition = {
  clientX: number;
  pageY: number;
};
export const calculateMarkerPosition = (
  mousePosition: MousePosition,
  imagePosition: ImagePosition,
  scrollY: number,
  bufferLeft: number,
  bufferTop: number
) => {
  const pixelsLeft = mousePosition.clientX - imagePosition.left;
  let pixelsTop;
  if (imagePosition.top < 0) {
    pixelsTop = mousePosition.pageY - scrollY + imagePosition.top * -1;
  } else {
    pixelsTop = mousePosition.pageY - scrollY - imagePosition.top;
  }
  const top = ((pixelsTop - bufferTop) * 100) / imagePosition.height;
  const left = ((pixelsLeft - bufferLeft) * 100) / imagePosition.width;
  return [top, left];
};
export const activityLogMessageGenerator = (
  action: Action,
  moduleName: string,
  field?: Field,
  module?: ModuleInPrototype,
  hierarchy?: Hierarchy
) => {
  switch (action) {
    case Action.CREATE:
      return ` created ${moduleName}`;
    case Action.DELETE:
      return ` deleted ${moduleName}`;
    case Action.UPDATE:
      if (field === Field.MAPPED_VARIANT) {
        return ` updated mapped variant as ${hierarchy?.variant}`;
      } else {
        return ` updated ${moduleName} ${field}`;
      }
    case Action.ADD:
      if (field === Field.PIN) {
        return ` added ${moduleName} ${field}`;
      } else {
        return ` added an image in ${moduleName} gallery`;
      }
    case Action.REMOVE:
      if (field === Field.PIN) {
        return ` removed ${moduleName} ${field}`;
      } else if (module === "variant") {
        return ` removed mapped variant ${hierarchy?.variant}`;
      } else {
        return ` removed an image in ${moduleName} gallery`;
      }
    default:
      return ` mapped ${hierarchy?.variant}`;
    //default case has action as 'mapped'
  }
};
export const activityIconSelector = (action: Action, field?: Field) => {
  switch (field) {
    case Field.DIAGRAM:
      return <DiagramUpdateActivity className="icon-activity" />;
    case Field.GALLERY:
      if (action === Action.ADD)
        return <GalleryImageAddActivity className="icon-activity" />;
      else return <GalleryImageDeleteActivity className="icon-activity" />;
    case Field.PIN:
      if (action === Action.REMOVE)
        return <PinRemoveActivity className="icon-activity" />;
      else return <PinUpdateActivity className="icon-activity" />;
    default:
      switch (action) {
        case Action.CREATE:
          return <CreateActivity className="icon-activity" />;
        case Action.MAP:
          return <CreateActivity className="icon-activity" />;
        case Action.UPDATE:
          return <UpdateActivity className="icon-activity" />;
        default:
          return <DeleteActivity className="icon-activity" />;
      }
  }
};
// in a hierarchy, get the lowest node
//ToDo: update the any to HIERARCHY TYPE in the next PR, while has model files
export const getLeafNode = (hierarchy: Hierarchy) => {
  //ORDER OF HIERARCHY is topmost is prototype, then plan, space, layer, variant
  const leafNode =
    hierarchy?.variant ??
    hierarchy?.layer?.title ??
    hierarchy?.space?.title ??
    hierarchy?.plan?.title ??
    hierarchy?.prototypeTitle ??
    "";
  return leafNode;
};

export const cancelPinningOnEsc = (
  event: { keyCode: number; key: string },
  handleSetAddPin: ((val: boolean) => void) | undefined
) => {
  if (event.key === "Escape") {
    handleSetAddPin?.(false);
  }
};
export const addEventListenerEscCancelPinning = (
  handleSetAddPin: ((val: boolean) => void) | undefined
) => {
  document.addEventListener(
    "keydown",
    (event) => cancelPinningOnEsc(event, handleSetAddPin),
    false
  );
};
export const removeEventListenerEscCancelPinning = (
  handleSetAddPin: ((val: boolean) => void) | undefined
) => {
  document.removeEventListener(
    "keydown",
    (event) => cancelPinningOnEsc(event, handleSetAddPin),
    false
  );
};
export function moduleTitle(title: string) {
  if (title.toLowerCase() === "products") {
    return "product";
  }
  if (title.toLowerCase() === "users") {
    return "User Management";
  }
  return title;
}
export function userHasAccess(title: string, method: string) {
  const existingUserRole = localStorage.getItem("user_role_access");
  let userRole = new UserRole();
  userRole = existingUserRole ? JSON.parse(existingUserRole) : new UserRole();
  return (
    userRole?.access
      ?.filter((role) => role.module?.toLowerCase() === title.toLowerCase())[0]
      ?.roleAccess?.filter(
        (role) => role.method?.toLowerCase() === method.toLowerCase()
      )[0].access ?? false
  );
}
