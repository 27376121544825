import { alias, list, object, primitive, serializable } from "serializr";

export class UserRoleAccess {
  @serializable
  id?: string;

  @serializable
  method?: string;

  @serializable
  access?: boolean;
}
export class UserAccess {
  @serializable
  id?: string;

  @serializable
  module?: string;

  @serializable(alias("role_access", list(object(UserRoleAccess))))
  roleAccess?: UserRoleAccess[];
}
class UserRole {
  @serializable
  id?: string;

  @serializable(alias("role_name", primitive()))
  roleName?: string;

  @serializable
  limit?: number;

  @serializable(list(object(UserAccess)))
  access?: UserAccess[];
}

export default UserRole;
