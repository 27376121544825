import { DiagramView } from "enums/diagramView";
import { Layer } from "models/Layer/layer.model";
import { Plan } from "models/Plans/plans.model";

export const parseNumber = (val: unknown) => Number(val) || 0;

export function getSubViewType(viewType: DiagramView): DiagramView {
  switch (viewType) {
    case DiagramView.SPACE:
      return DiagramView.LAYER;
    case DiagramView.PLAN:
      return DiagramView.SPACE;
    case DiagramView.ZONE:
      return DiagramView.SPACE;
    case DiagramView.PROTOTYPE:
      return DiagramView.PLAN;
    default:
      throw new Error("Invalid view type");
  }
}

export function getSubviewTitle(viewType: DiagramView): string {
  switch (viewType) {
    case DiagramView.SPACE:
      return "View Layer";
    case DiagramView.PLAN:
      return "View Space";
    case DiagramView.ZONE:
      return "View Space";
    case DiagramView.PROTOTYPE:
      return "View Plan";
    case DiagramView.LAYER:
      return "View Product";
    default:
      throw new Error("Invalid view type");
  }
}

export function getRightViewTitle(
  viewType: DiagramView,
  selectedZoneId: string | undefined,
  plan: Plan | undefined,
  layer: Layer | undefined,
): string {
  if (selectedZoneId && plan && plan.zones) {
    const zone = plan.zones.find((z) => z.id === selectedZoneId);
    return zone?.title || "";
  }

  if (viewType === DiagramView.PROTOTYPE) {
    return plan?.title || "";
  }

  return layer?.title || "";
}
