import React, { FC } from "react";

import "./expandableCard.scss";
import AddItemIcon from "shared/components/AddItemIcon";
import useRedirect from "shared/hooks/useRedirect";
import { Plan } from "models/Plans/plans.model";
import { AccordionOpen, Edit, Eye, Pin, RemovePin } from "shared/icons";
import { Tooltip } from "antd";
import { useCanAddPin } from "context/CanAddPinContext";
import SkeletonInput from "antd/es/skeleton/Input";
import { Layer } from "models/Layer/layer.model";
import { useSelectedPlan } from "context/SelectedPlanContext";
import { useSelectedSpace } from "context/SelectedSpaceContext";
import { useSelectedLayerId } from "context/SelectedLayerContext";
import { useSelectedVariantId } from "context/SelectedVariantContext";
import { useParams } from "react-router-dom";
import { Coordinate } from "models/Coordinate/coordinate.model";
import { DiagramView } from "enums/diagramView";
import { useOpenedLayerVariant } from "context/OpenedLayerVariantContext";
import useToggle from "shared/hooks/useToggle";
import ToggleDescriptionIcon from "shared/components/ToggleDescriptionIcon";
import DescriptionModal from "shared/components/DescriptionModal";
import LazyLoadedImage from "shared/components/LazyLoadedImage";
import { userHasAccess } from "utils";
import { Module } from "enums/module.enum";
import { Method } from "enums/method.enum";

import { getSubviewTitle } from "shared/utils/parser";
import { Zone } from "models/Zone/zone.model";

import { useSelectedZone } from "context/SelectedZoneContext";

import ZoneAccordion from "./ZoneAccordion";

interface ExpandableCardType {
  subView: Plan | Layer;
  viewId?: string;
  subViewType: DiagramView;
}
const ExpandableCard: FC<ExpandableCardType> = ({
  subView,
  viewId,
  subViewType,
}: ExpandableCardType) => {
  //ToDo - create layers services and products services and give generic names
  const selectedPlanContext = useSelectedPlan();
  const selectedSpaceContext = useSelectedSpace();
  const selectedLayerContext = useSelectedLayerId();
  const {
    redirectToAddSpaceForm,
    redirectToProductLists,
    redirectToEditPlan,
    redirectToAddZoneForm,
    redirectToEditLayer,
  } = useRedirect();
  const selectedProductContext = useSelectedVariantId();
  const { setOpenedLayerVariantId } = useOpenedLayerVariant();

  const {
    isToggled: descModalToggled,
    toggleOn: descModalToggleOn,
    toggleOff: descModalToggleOff,
  } = useToggle();

  const zoomToImage = (id: string) => {
    if (selectedSpaceContext?.ref?.current != undefined) {
      const { zoomToElement } = selectedSpaceContext.ref.current;
      zoomToElement(id, 2);
    }
  };
  const { prototypeId, planId, spaceId, zoneId } = useParams();

  const handleExpand = () => {
    if (
      selectedPlanContext?.selectedPlanId === subView?.id ||
      selectedLayerContext?.selectedLayerId === subView?.id
    ) {
      selectedPlanContext?.handleSetSelectedPlanId?.("");
      selectedLayerContext?.updateSelectedLayerId?.("");
      setOpenedLayerVariantId?.("");
      return;
    }
    if (subViewType === DiagramView.PLAN) {
      selectedPlanContext?.handleSetSelectedPlanId?.(subView?.id ?? "");
    } else {
      selectedLayerContext?.updateSelectedLayerId?.(subView?.id ?? "");
    }

    if (selectedZoneId && handleSetSelectedZoneId) {
      handleSetSelectedZoneId(undefined);
    }

    handleSetAddPin?.(false);
  };

  const handleAddSpace = () => {
    if (!viewId || !subView?.id || !zoneId) return;
    //ToDo - create layers services and products services and give generic names
    redirectToAddSpaceForm(viewId, subView?.id, zoneId);
  };
  const { handleSetAddPin, handleSetAddPinningDetails, canAddPin } =
    useCanAddPin();
  const subViewMappingList =
    subViewType === DiagramView.PLAN
      ? selectedPlanContext?.plan?.zones
      : selectedLayerContext?.layer?.products;

  const isActiveSuperSubView = (superSubViewId?: string) => {
    const selectedSubViewId =
      subViewType === DiagramView.PLAN
        ? selectedSpaceContext?.selectedSpace?.id
        : selectedProductContext?.selectedVariant?.id;
    return selectedSubViewId === superSubViewId ? "active-space" : "";
  };
  const handleSelectSuperSubViewActive = (
    superSubViewId: string,
    superSubViewCoordinate?: Coordinate
  ) => {
    zoomToImage(superSubViewId ?? "");
    subViewType === DiagramView.PLAN
      ? selectedSpaceContext?.handleSetSelectedSpace?.({
          id: superSubViewId,
          coordinates: superSubViewCoordinate,
        })
      : selectedProductContext?.updateSelectedVariantId?.({
          id: superSubViewId,
          coordinates: superSubViewCoordinate,
        });
  };

  const isSuperSubViewSelected = (superSubViewId: string) =>
    subViewType === DiagramView.PLAN
      ? selectedSpaceContext?.selectedSpace?.id === superSubViewId
      : selectedProductContext?.selectedVariant?.id === superSubViewId;

  const handleEditFormRedirect = () => {
    if (subViewType === DiagramView.PLAN) {
      viewId && subView?.id && redirectToEditPlan(viewId, subView?.id);
    }
    prototypeId &&
      planId &&
      spaceId &&
      subView?.id &&
      redirectToEditLayer(prototypeId, planId, spaceId, subView.id);
  };
  const { removePin, updatingPin } = useSelectedSpace();
  const { handleUnpinProductFromLayer } = useSelectedVariantId();
  const { selectedZoneId, handleSetSelectedZoneId } = useSelectedZone();

  const removePinHandle = (superSubViewId?: string) => {
    superSubViewId &&
      (subViewType === DiagramView?.PLAN
        ? !updatingPin && removePin?.(superSubViewId)
        : handleUnpinProductFromLayer?.(superSubViewId));
  };

  const pinTitle =
    subViewType === DiagramView.PLAN ? "Pin Space" : "Pin Product";

  let headingTitle = "";
  if (subViewType === DiagramView.LAYER) {
    headingTitle = "PRODUCT";
  } else if (subViewType === DiagramView.PLAN) {
    headingTitle = "ZONE";
  } else {
    headingTitle = "SPACE";
  }

  return (
    <>
      {/* ToDo - create layers services and products services and give generic names  */}
      <div className="card-container">
        <div
          className={`plan-or-layer-card ${
            selectedPlanContext?.selectedPlanId === subView?.id ||
            selectedLayerContext?.selectedLayerId === subView?.id
              ? "expanded"
              : ""
          }`}
          onClick={handleExpand}
        >
          <div className="plan-or-layer-card__details">
            {subViewType === DiagramView.PLAN && "image" in subView && (
              <div className="plan-or-layer-card__image">
                <LazyLoadedImage
                  src={subView?.image?.url ?? ""}
                  alt={subView?.id}
                  aspectRatio="square"
                />
              </div>
            )}
            <div className="plan-or-layer-card__title tw-text-3xl">
              {subView?.title}
            </div>
            {(selectedPlanContext?.selectedPlanId === subView?.id ||
              selectedLayerContext?.selectedLayerId === subView?.id) && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  descModalToggleOn();
                }}
                className="tw-ml-2"
              >
                <ToggleDescriptionIcon
                  active={descModalToggled}
                ></ToggleDescriptionIcon>
              </div>
            )}
          </div>
          <div className="plan-or-layer-card__actions">
            {userHasAccess(Module.PROTOTYPE, Method.EDIT) && (
              <div
                className={`dual-view-icons__icon-gp__icon ${
                  selectedPlanContext?.selectedPlanId === subView?.id ||
                  selectedLayerContext?.selectedLayerId === subView?.id
                    ? ""
                    : "icon-hidden"
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleEditFormRedirect();
                }}
              >
                <Edit className="icon icon--edit" />
              </div>
            )}
            <div className={`dual-view-icons__icon-gp__icon`}>
              {selectedPlanContext?.selectedPlanId === subView?.id ? (
                <AccordionOpen className="icon icon--accordion icon--accordion--closed" />
              ) : (
                <AccordionOpen className="icon icon--accordion" />
              )}
            </div>
          </div>
        </div>
        <div
          className={`space-card ${
            selectedPlanContext?.selectedPlanId === subView?.id ||
            selectedLayerContext?.selectedLayerId === subView?.id
              ? ""
              : "d-none"
          }`}
        >
          {userHasAccess(Module.PROTOTYPE, Method.EDIT) &&
            headingTitle !== "ZONE" && (
              <div className="">
                <span className="disp">
                  <span className="space-heading">
                    {/* {subViewType === DiagramView.LAYER ? "PRODUCT" : "SPACE"}{" "} */}
                    {headingTitle}{" "}
                  </span>{" "}
                  <AddItemIcon
                    onAdd={
                      subViewType === DiagramView.PLAN
                        ? handleAddSpace
                        : () =>
                            prototypeId &&
                            planId &&
                            spaceId &&
                            selectedLayerContext?.selectedLayerId &&
                            redirectToProductLists(
                              prototypeId,
                              planId,
                              spaceId,
                              selectedLayerContext?.selectedLayerId
                            )
                    }
                    tooltipOptions={{
                      title:
                        subViewType === DiagramView.PLAN
                          ? "Add Space"
                          : "Add Product",
                    }}
                  />
                </span>
              </div>
            )}

          {selectedPlanContext?.planLoading || selectedLayerContext?.loading ? (
            <SkeletonInput active block />
          ) : subViewMappingList &&
            Array.isArray(subViewMappingList) &&
            subViewType === DiagramView.PLAN ? (
            <ZoneAccordion
              subViewMappingList={subViewMappingList as Zone[]}
              handleSelectSuperSubViewActive={handleSelectSuperSubViewActive}
              isActiveSuperSubView={isActiveSuperSubView}
              isSuperSubViewSelected={isSuperSubViewSelected}
              setOpenedLayerVariantId={setOpenedLayerVariantId}
              subViewType={subViewType}
              viewId={viewId}
              subViewId={subView.id}
            />
          ) : (
            subViewMappingList?.map((superSubView) => (
              <div
                className={`space-list ${isActiveSuperSubView(
                  superSubView?.id
                )}`}
                key={superSubView?.id}
                onClick={() => {
                  superSubView?.id &&
                    handleSelectSuperSubViewActive(
                      superSubView?.id,
                      superSubView?.coordinate
                    );
                }}
              >
                <div className="display-flex space-between">
                  <p
                    className="tw-text-xl tw-max-w-[150px] tw-truncate tw-overflow-hidden tw-m-0"
                    title={superSubView?.title}
                  >
                    {superSubView?.title}
                  </p>
                  <div
                    className={`${
                      superSubView?.id &&
                      isSuperSubViewSelected(superSubView?.id)
                        ? ""
                        : "d-none"
                    }`}
                  >
                    {userHasAccess(Module.PROTOTYPE, Method.EDIT) && (
                      <Tooltip
                        title={`${
                          superSubView?.isPinned ? "Remove Pin" : "Pin Space"
                        }`}
                      >
                        {superSubView?.isPinned ? (
                          <RemovePin
                            className="icon-pin"
                            onClick={() => removePinHandle(superSubView?.id)}
                          />
                        ) : (
                          //ToDo: check if on disabling can add pin and still sending the setAddPinDetails has any buggy behaviour
                          <Pin
                            className="icon-pin"
                            onClick={() => {
                              handleSetAddPin?.(!canAddPin);
                              superSubView?.id &&
                                superSubView?.title &&
                                handleSetAddPinningDetails?.(
                                  superSubView?.id,
                                  superSubView?.title
                                );
                            }}
                          />
                        )}
                      </Tooltip>
                    )}
                    <Tooltip title={getSubviewTitle(subViewType)}>
                      <Eye
                        className="icon-pin"
                        onClick={() => {
                          setOpenedLayerVariantId?.(superSubView?.id ?? "");
                        }}
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>
            ))
          )}

          <DescriptionModal
            title={subView?.title ?? ""}
            type={subViewType}
            desc={subView?.description ?? ""}
            isOpen={descModalToggled}
            onClose={descModalToggleOff}
          />

          {userHasAccess(Module.PROTOTYPE, Method.EDIT) &&
            headingTitle === "ZONE" && (
              <div className=" tw-mt-8">
                <span className="disp">
                  <span className="space-heading">
                    {/* {subViewType === DiagramView.LAYER ? "PRODUCT" : "SPACE"}{" "} */}
                    {headingTitle}{" "}
                  </span>{" "}
                  <AddItemIcon
                    onAdd={
                      subViewType === DiagramView.PLAN
                        ? () => {
                            redirectToAddZoneForm(
                              prototypeId || "",
                              selectedPlanContext.selectedPlanId || ""
                            );
                          }
                        : () =>
                            prototypeId &&
                            planId &&
                            spaceId &&
                            selectedLayerContext?.selectedLayerId &&
                            redirectToProductLists(
                              prototypeId,
                              planId,
                              spaceId,
                              selectedLayerContext?.selectedLayerId
                            )
                    }
                    tooltipOptions={{
                      title: "Add Zone",
                    }}
                  />
                </span>
              </div>
            )}
        </div>
      </div>
    </>
  );
};

export default ExpandableCard;
