import React from "react";
import { Back, Delete } from "shared/icons";
import { useFormikContext } from "formik";
import { Product } from "models/Product/product.model";
import { ButtonType } from "enums/buttonType.enum";
import useToggle from "shared/hooks/useToggle";
import useRedirect from "shared/hooks/useRedirect";
import DeleteModal from "shared/components/DeleteModal";
import Button from "shared/components/Button";
import ProductService from "services/ProductService/product.service";
import { Space } from "antd";

import "./productFormHeader.scss";
import { userHasAccess } from "utils";
import { Module } from "enums/module.enum";
import { Method } from "enums/method.enum";

const ProductFormHeader = () => {
  const { isSubmitting, values } = useFormikContext<Product>();

  const { goBack, redirectToDashBoardListing } = useRedirect();

  const { toggleOn, isToggled, toggleOff } = useToggle();

  const { deleteProduct } = ProductService();

  const {
    toggleOn: toggleOnDelete,
    isToggled: isDeleteToggled,
    toggleOff: toggleOffDelete,
  } = useToggle();

  const isProductUpdate = !!values?.id;

  const handleDelete = async () => {
    if (!values?.id) return;

    const isDeleted = await deleteProduct(values?.id);
    if (isDeleted) redirectToDashBoardListing();
  };

  return (
    <>
      <div className="product-form-header">
        <div className="product-form-header__back">
          <span
            className="icon-container product-form-header__start"
            onClick={goBack}
          >
            <Back className="icon--back" />
            <span className="text-capitalize">back</span>
          </span>
        </div>
        <div className="product-form-header__end">
          {isProductUpdate && userHasAccess(Module.PRODUCT, Method.DELETE) && (
            <Button
              type={ButtonType.TEXT}
              className="product-form-header__end__delete"
              icon={<Delete className="icon icon--delete" />}
              onClick={toggleOnDelete}
            >
              Delete Product
            </Button>
          )}
          <Button
            type={ButtonType.DEFAULT}
            className="product-form-header__end__discard"
            onClick={toggleOn}
          >
            Discard
          </Button>
          <Button
            className="text-white"
            loading={isSubmitting}
            htmlType="submit"
            type={ButtonType.PRIMARY}
          >
            {isProductUpdate ? "Update" : "Create"}
          </Button>
        </div>
      </div>
      <DeleteModal
        deleteText="Discard"
        hideTrash
        onCancel={toggleOff}
        onDelete={redirectToDashBoardListing}
        open={isToggled}
        subTitle="Product info entered will be deleted."
        title="Are you sure to discard?"
        width={366}
      />
      <DeleteModal
        deleteText="Delete"
        onCancel={toggleOffDelete}
        onDelete={handleDelete}
        open={isDeleteToggled}
        subTitle="Product will be deleted from the system"
        title={
          <Space align="start">
            <span className="text-medium">Delete</span>
            <span className="quotes">{values?.name}</span>
          </Space>
        }
        width={510}
      />
    </>
  );
};

export default ProductFormHeader;
