import React from "react";
import Slider, { SliderRangeProps } from "antd/es/slider";
import "./slider.scss";

interface SliderProps extends SliderRangeProps {
  size?: "sm";
}
const SliderShared = ({ range, ...props }: SliderProps) => {
  return <Slider range {...props} />;
};

export default SliderShared;
