import { PlanError } from "shared/constants/plan";
import { PrototypeError } from "shared/constants/prototype";
import getObjectIdSchema from "shared/utils/getObjectIdValidation";
import * as Yup from "yup";

export default Yup.object().shape({
  title: Yup.string()
    .required(PrototypeError.TITLE_REQUIRED)
    .max(120, PrototypeError.TITLE_LENGTH)
    .trim()
    .strict(true),
  description: Yup.string()
    .required(PrototypeError.DESCRIPTION_REQUIRED)
    .trim()
    .strict(true),
  diagram: getObjectIdSchema(true, PlanError.DIAGRAM_REQUIRED).required(
    PlanError.DIAGRAM_REQUIRED,
  ),
  image: getObjectIdSchema().required(),
});
