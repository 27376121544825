import { Modal } from "antd";
import React from "react";
import "./descriptionModal.scss";
import { ViewDescription } from "shared/icons";

export interface DescriptionModalProps {
  title: string;
  type?: string;
  desc: string;
  isOpen: boolean;
  onClose: () => void;
  width?: number;
}

const DescriptionModal = ({
  title,
  type,
  desc,
  isOpen,
  onClose,
  width,
}: DescriptionModalProps) => {
  return (
    <Modal
      closable
      footer={null}
      open={isOpen}
      onCancel={onClose}
      width={width ?? 640}
      className="description__modal__container"
    >
      <div className="description__modal__title">{title}</div>
      <div className="description__modal__subtitle">
        <ViewDescription className="icon" /> {type} Description
      </div>
      <div className="description__modal__desc scrollbar-visible multiline">
        {desc}
      </div>
    </Modal>
  );
};

export default DescriptionModal;
