import AwsS3 from "@uppy/aws-s3";
import Uppy, { UppyFile, UppyOptions } from "@uppy/core";
import axiosInstance, { getToken } from "interceptor/axiosInstance";
import { ApiRoutes, COMPANION_URL } from "routes/routeConstants/apiRoutes";
import { v4 as uuidv4 } from "uuid";
import ThumbnailGenerator from "@uppy/thumbnail-generator";
import { AttachmentFormat } from "enums/attachmentFormat";
import { CLOUD_PROVIDER } from "shared/constants";

const shouldMultipart = (file: UppyFile) => file.size > 100 * 2 ** 20;

const AWSUppy = (options: UppyOptions = {}) =>
  new Uppy({
    id: uuidv4(),
    autoProceed: true,
    ...options,
  })
    .use(AwsS3, {
      companionUrl: COMPANION_URL,
      // shouldUseMultipart: ((file: UppyFile) => {
      //   return file.size > 100 * 2 ** 20;
      // }) as any,
      companionHeaders: {
        "uppy-auth-token": getToken(),
      },
      shouldUseMultipart: (() => true) as any,
    })
    .use(ThumbnailGenerator, {
      waitForThumbnailsBeforeUpload: true,
      thumbnailType: AttachmentFormat.JPEG,
      thumbnailHeight: 100,
      thumbnailWidth: 100,
      locale: {
        strings: {
          generatingThumbnails: "",
        },
      },
    });

const AzureUppy = (options: UppyOptions = {}) =>
  new Uppy({
    id: uuidv4(),
    autoProceed: true,
    ...options,
  })
    .use(AwsS3, {
      getUploadParameters: async (file: UppyFile) => {
        const filename = encodeURIComponent(file.name);
        const getSasUrl = `${ApiRoutes.BASE_URL}/attachments/azure-upload-url`;

        const sasResp = await axiosInstance.post(getSasUrl, {
          filename,
        });

        const uploadUrl = sasResp.data?.url;

        return {
          url: uploadUrl,
          method: "PUT",
          headers: {
            "x-ms-blob-type": "BlockBlob",
          },
        };
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      shouldUseMultipart: (() => false) as any,
    })
    .use(ThumbnailGenerator, {
      waitForThumbnailsBeforeUpload: true,
      thumbnailType: AttachmentFormat.JPEG,
      thumbnailHeight: 100,
      thumbnailWidth: 100,
      locale: {
        strings: {
          generatingThumbnails: "",
        },
      },
    });

export default (options: UppyOptions = {}) => {
  if (CLOUD_PROVIDER === "azure") {
    return AzureUppy(options);
  }

  return AWSUppy(options);
};
